import { useContext, useEffect } from 'react';
import { goFocus, JarvislyFormContext } from '../others/FormComponents';
import { ModuleContext } from '../../jarvisly-module/ModuleContext';

const useTabChild = (currentTabName, fieldNameFocus, props) => {
  // providers context ---------------------------------------------------------
  const { elRefs, form } = useContext(JarvislyFormContext);
  const { setModuleForm } = useContext(ModuleContext);

  // props deconstruction ------------------------------------------------------
  const { activeTab } = props;

  // local variables I ---------------------------------------------------------

  // methods -------------------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (activeTab !== currentTabName) return;
    goFocus(elRefs, fieldNameFocus);

    if (form) setModuleForm(form);
  }, [activeTab, form]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useTabChild;
