import { TagOutlined } from '@ant-design/icons';
import { Card, Col, Modal, Row } from 'antd';
import {
  goFocus,
  JarvislyCnpj,
  JarvislyEmail,
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislyPhone,
  JarvislySegmented,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useContext, useEffect, useState } from 'react';
import appService from 'services/appService';
import axiosService from 'services/axiosService';
import {
  clone,
  returnOnlyNumbers,
  translateX,
} from 'utils/helpers';

// ...

const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form, elRefs } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    showPixEditModal,
    setShowPixEditModal,
    isDisabled,
    selectedPix,
    selectedType,
    setSelectedType,
    loading,
  } = props;

  // local variables I ----------------------------------------------------------
  const title = isDisabled
    ? translateX('view')
    : !selectedPix?._id
      ? translateX('new_pix_key')
      : translateX('edit_pix_key');

  // component states -----------------------------------------------------------
  useEffect(() => {
    if (selectedPix) {
      setTimeout(() => form.setFieldsValue(selectedPix), 200);
      setSelectedType(selectedPix.type);
    }
  }, [selectedPix]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    goFocus(elRefs, selectedType, null, 100);
  }, [selectedType, elRefs]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setShowPixEditModal(false);
  };

  const handleTypeChange = value => {
    setSelectedType(value);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Modal
      title={
        <>
          <TagOutlined />
          <span className="ml-2">{title}</span>
        </>
      }
      destroyOnClose={true}
      open={showPixEditModal}
      width={640}
      okText={translateX('save')}
      onCancel={onClose}
      cancelText={translateX('close')}
      // cancelButtonProps={{ style: { display: 'none' } }}
      onOk={() => (isDisabled ? onClose() : form.submit())}
      getContainer={false}
      okType={'primary'}
      loading={loading}
    >
      <Card title={translateX('choice_an_option')}>
        <Row gutter={ROW_GUTTER} style={{ marginBottom: '24px' }}>
          <Col xs={24} sm={24}>
            <JarvislySegmented
              name="type"
              onChange={handleTypeChange}
              value={selectedType}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              direction="horizontal"
              options={[
                { value: 'cnpj', label: translateX('cnpj') },
                { value: 'mobile', label: translateX('mobile') },
                { value: 'email', label: translateX('email') },
                { value: 'other', label: translateX('other') },
              ]}
            />
          </Col>
        </Row>

        <Row gutter={ROW_GUTTER}>
          <Col
            xs={24}
            style={{ display: selectedType === 'cnpj' ? 'flex' : 'none' }}
          >
            <JarvislyCnpj name="cnpj" required={selectedType === 'cnpj'} />
          </Col>

          <Col
            xs={24}
            style={{ display: selectedType === 'mobile' ? 'flex' : 'none' }}
          >
            <JarvislyPhone name="mobile" required={selectedType === 'mobile'} />
          </Col>

          <Col
            xs={24}
            style={{ display: selectedType === 'email' ? 'flex' : 'none' }}
          >
            <JarvislyEmail name="email" required={selectedType === 'email'} />
          </Col>

          <Col
            xs={24}
            style={{ display: selectedType === 'other' ? 'flex' : 'none' }}
          >
            <JarvislyInput name="other" required={selectedType === 'other'} />
          </Col>
        </Row>
      </Card>
    </Modal>
  );
};

// MAIN COMPONENT **************************************************************

const PixEditModal = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    setShowPixEditModal,
    selectedRecord,
    selectedPix,
    pixArr,
    setPixArr,
    refreshDocument,
  } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------
  const [selectedType, setSelectedType] = useState('cnpj');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (selectedPix) {
      switch (selectedPix.type) {
        case 'cnpj':
          selectedPix.cnpj = selectedPix.value;
          return;

        case 'mobile':
          selectedPix.mobile = selectedPix.value;
          return;

        case 'email':
          selectedPix.email = selectedPix.value;
          return;

        default:
          selectedPix.other = selectedPix.value;
      }
    }
  }, [selectedPix]);

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    selectedType,
    setSelectedType,
    loading,
  };

  // providers context ---------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onConfirm = async ({ body }) => {
    const newBody = { type: selectedType };

    switch (selectedType) {
      case 'cnpj':
        newBody.value = returnOnlyNumbers(body.cnpj);
        break;
      case 'mobile':
        newBody.value = returnOnlyNumbers(body.mobile);
        break;
      case 'email':
        newBody.value = body.email.toLowerCase();
        break;
      default:
        newBody.value = body.other;
    }

    setLoading(true);

    const idx = pixArr?.findIndex(p => p.type === newBody.type);

    let pixData = clone(pixArr);

    if (idx >= 0 && selectedPix?._id !== pixArr[idx]._id) {
      const key = translateX(selectedType);
      const message = translateX(
        'pix_key_already_exists',
        ['%KEY%'],
        [key.toUpperCase()],
      );
      appService.message('w', message, 'onSave');
      return setLoading(false);
    } else if (idx >= 0 && selectedPix?._id === pixData[idx]._id) {
      // pixArr[idx].value = newBody.value;
      pixData[idx].value = newBody.value;
    } else {
      // pixArr.push({
      //   _id: generateToken(),
      //   ...newBody,
      // });
      pixData.push(newBody);
    }

    appService.message('s', 'saved_parameters', 'onSave');

    const axiosOptions = {
      url: `/v1/banks`,
      _id: selectedRecord._id,
      data: { pixKeys: pixData },
    };

    setShowPixEditModal(false);

    const [doc] = await axiosService.put(axiosOptions);
    pixData = clone(doc?.pixKeys);

    pixData?.map(p => {
      p[p.type] = p.value;
      return p;
    });

    setPixArr([...pixData]);
    setLoading(false);

    refreshDocument(null, true, false, false).then()
  };

  // variables III -------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-add-pix"
      document={selectedPix || { type: 'cnpj' }}
      showSubmitButton={false}
      onConfirm={onConfirm}
      autoFocus={false}
      abortComponentSave
    >
      <ModalComponent {...childProps} />
    </JarvislyFormProvider>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default PixEditModal;
