import { Card } from 'antd';
import JarvislyEntityForm from 'components/jarvisly-components/forms/Items/JarvislyEntityForm';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';
import { ParametersContext } from 'components/jarvisly-module/ParametersProvider';
import { useContext } from 'react';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const CustomersRecordIndex = props => {
  // providers context ---------------------------------------------------------
  const { selectedRecord, setSelectedRecord, setShowBusinessAreaModal } =
    useContext(ParametersContext);
  // props deconstruction ------------------------------------------------------
  const { document } = props;

  // local variables I ---------------------------------------------------------
  useTabChild('record', ['__entity', 'cnpj'], props);

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------
  const childProps = {
    ...props,
    selectedRecord,
    setSelectedRecord,
    setShowBusinessAreaModal,
  };
  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Card>
        <JarvislyEntityForm
          {...childProps}
          // alterado para atender multi
          entityPersonType="manual"
          // original lekkus
          // entityPersonType="manual"
          // entityPersonDefault="company"

          // originRegisteredLabel="registered_customer"
          // originNewLabel="new_customer"
          // searchEntityLabel="search_customer"
          // selectable
          additionalInfo="required"
        />

        {/*
        <JarvislyEntityForm
          {...childProps}
          // selectable
          entityPersonType="person"
          // entityPersonDefault="person"
          // originRegisteredLabel="registered_customer"
          // originNewLabel="new_customer"
          // searchEntityLabel="search_customer"
          showCpfCnpj={true}
          cnpjCpfFirst={true}
        />
        */}
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

export default CustomersRecordIndex;
