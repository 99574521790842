import React from 'react';
import { Col, Grid, Row, Spin } from 'antd';
import NoData from './NoData';
import { connect } from 'react-redux';
import utils from 'utils';
import { NAV_TYPE_TOP } from 'constants/ThemeConstant';
import app from 'configs/ConfigIndex';

const { useBreakpoint } = Grid;

const Loading = ({
  isFetching,
  data,
  navCollapsed,
  navType,
  fullscreen,
  showNoDataIcon,
  marginTop = 0,
  progressTop = -120,
}) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const isNavTop = navType === NAV_TYPE_TOP;
  const left = isMobile || fullscreen || isNavTop ? 0 : navCollapsed ? 80 : 250;

  return (
    <>
      {isFetching ? (
        <Row
          justify="center"
          align="middle"
          style={{
            height: '100%',
            // height: '80%',
            position: 'fixed',
            left: left,
            right: 0,
            zIndex: 2,
            // background: '#FAFAFB',
            background: `${app.backgroundColor}80`,
            // opacity: 0.1, // data ? 0.9 : 1,
            // marginTop: marginTop || -62,
          }}
        >
          <Col>
            <Spin size="large" style={{ marginTop: progressTop }} />
          </Col>
        </Row>
      ) : showNoDataIcon && (!data || data.length === 0) ? (
        <NoData marginTop={marginTop} />
      ) : null}
    </>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme;
  return {
    navCollapsed,
    navType,
  };
};

export default connect(mapStateToProps)(Loading);
