import { EditOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Button, Flex, Table, Tooltip } from 'antd';
import { parseDateToDisplay, setLocale, translateX } from 'utils/helpers';
import dayjs from 'dayjs';
import DocumentStatusTag from 'components/jarvisly-components/others-old/DocumentStatusTag';
import accounting from 'accounting';
import React from 'react';
import { useSelector } from 'react-redux';
import MULTI_PLANS_MODULE from '../plans';
import Icon from 'components/util-components/Icon';
import { DefaultApiIcon } from 'components/jarvisly-module/Form/FormHeader';

const MultiPlansList = props => {
  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,
    onClickOpen,

    numberLineColumn,
    documentCodeColumn,
  } = props;

  // component states ----------------------------------------------------------

  // local variables -----------------------------------------------------------
  const { parameters } = useSelector(s => s.moduleSlice);
  const statusFilter = buildDocumentStatusFilter();

  const operatorsFilter = parameters?.operators.map(o => {
    return {
      text: o?.name,
      value: o?._id,
    };
  });

  const tableColumns = buildTableColumns();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return (
    <>
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildDocumentStatusFilter() {
    const options =
      MULTI_PLANS_MODULE?.initialRequest?.filterOptions?.status || [];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }

  function buildTableColumns() {
    return [
      numberLineColumn,
      documentCodeColumn,

      {
        title: translateX('plan'),
        dataIndex: 'name',
        ...columnSorter('name'),
        ...columnSearch('name'),
        className: 'text-truncate',
        render: (_, record) => (
          <>
            <div className="text-uppercase">{record?.name}</div>
          </>
        ),
      },

      {
        title: translateX('operator'),
        dataIndex: 'operatorId',
        ...columnFilters('operatorId', operatorsFilter),
        // ...columnSorter('__operator.name'),
        align: 'left',
        render: (_, record) => (
          <>
            <div className="font-weight-bold">{record?.__operator?.name}</div>

            <Tooltip
              title={translateX(record?.__operator?.parameters.__tooltip)}
            >
              {/*{translateX('exchange')}:*/}
              {record?.__operator?.parameters?.__exchangeRate &&
              !record?.__operator?.parameters?.__hideExchangeOnList
                ? accounting.formatMoney(
                    record.__operator?.parameters.__exchangeRate,
                    `${record?.__operator?.parameters?.__symbol} `,
                    2,
                    '.',
                    ',',
                  )
                : ''}
            </Tooltip>
          </>
        ),
      },

      // {
      //   title: translateX('quotation'),
      //   dataIndex: '__operator.parameters.__exchangeRate',
      //   ...columnSorter('__operator.parameters.__exchangeRate'),
      //   // width: 100,
      //   render: (_, record) => (
      //     <div className="text-center">
      //       {record?.__operator?.parameters?.__exchangeRate &&
      //       !record?.__operator?.parameters?.__hideExchangeOnList ? (
      //         <Tooltip
      //           title={translateX(record?.__operator?.parameters.__tooltip)}
      //         >
      //           {accounting.formatMoney(
      //             record.__operator?.parameters.__exchangeRate,
      //             `${record?.__operator?.parameters?.__symbol} `,
      //             2,
      //             '.',
      //             ',',
      //           )}
      //         </Tooltip>
      //       ) : (
      //         ''
      //       )}
      //     </div>
      //   ),
      // },

      {
        title: translateX('price'),
        dataIndex: 'price',
        ...columnSorter('price'),
        align: 'right', // Isso já deve alinhar à direita no contexto da tabela
        render: (_, record) => (
          <Flex justify="flex-end" align="start" style={{ width: 100 }}>
            <div style={{ textAlign: 'right', width: '100%' }}>
              <div style={{ textTransform: 'uppercase' }}>
                {accounting.formatMoney(
                  record?.price || record?.dailyPrice,
                  `${
                    record?.__priceSymbol ||
                    record?.__operator?.parameters?.__symbol
                  } `,
                  2,
                  '.',
                  ',',
                )}
              </div>
              <div style={{ textTransform: 'lowercase' }}>
                {translateX(
                  !record?.priceType || record.priceType === 'daily'
                    ? 'day'
                    : record.priceType,
                )}
              </div>
            </div>

{/*            {!record?.__priceChangedByApi ? (
              <Tooltip
                title={translateX(
                  'data_changed_by_api',
                  ['%DATE%'],
                  [
                    parseDateToDisplay(
                      dayjs(record?.__priceChangedAt),
                      true,
                      true,
                    ),
                  ],
                )}
              >
                <div style={{ marginTop: 2 }}>
                  <Icon
                    type={ThunderboltOutlined}
                    className="text-primary font-size-md ml-1"
                    style={{ flexShrink: 0 }}
                  />
                </div>
              </Tooltip>
            ) : (
              <div className="mt-1" style={{ width: 21 }} />
            )}*/}
          </Flex>
        ),
      },

      {
        title: translateX('api'),
        dataIndex: '_integrations.planCode',
        ...columnSorter('_integrations.planCode'),
        width: 70,
        render: (_, record) => (
          <div className="text-center">
            {record?.__operator?._integrations?.operatorCode ? (
              <DefaultApiIcon document={record} showApiIcon />
            ) : null}
          </div>
        ),
      },

      {
        title: translateX('status'),
        dataIndex: 'status',
        ...columnFilters('status', statusFilter),
        ...columnSorter('status'),
        render: (_, record) => <DocumentStatusTag record={record} />,
      },

      {
        title: translateX('last_update'),
        dataIndex: '_metadata.audit.updatedAt',
        ...columnSorter('_metadata.audit.updatedAt'),
        // width: 160,
        hidden: false,
        render: (_, record) => (
          <>
            {record?._metadata?.audit?.updatedAt &&
              // moment(record._metadata.audit.updatedAt).format('L LT')}
              dayjs(record._metadata.audit.updatedAt).fromNow()}
          </>
        ),
      },

      {
        title: setLocale('actions'),
        dataIndex: 'actions',
        align: 'center',
        width: 50,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">
            <Tooltip title={setLocale('edit')}>
              <Button
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => onClickOpen(record)}
                size="small"
              />
            </Tooltip>

            {/* <Tooltip title={setLocale('view')}>
              <Button
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => onClickPreview(record)}
                size="small"
              />
            </Tooltip>*/}
          </div>
        ),
      },
    ].filter(x => !x.hidden);
  }
};

export default MultiPlansList;
