import {
  buildSteps,
  goFocus,
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyPreviousNext,
  JarvislyRadio,
} from '../../../others/FormComponents';
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  parseCnpjToDisplay,
  parsePhoneToDisplay,
  returnOnlyNumbers,
  translateX,
} from 'utils/helpers';
import { Card, Descriptions, Modal, Row, Spin, Steps } from 'antd';
import { BankOutlined } from '@ant-design/icons';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import JarvislyBankExistingAccountForm from '../../Items/JarvislyBankExistingAccountForm';
import JarvislyBankOpenDigitalAccountForm from '../../Items/JarvislyBankOpenDigitalAccountForm';
import appService from 'services/appService';
import { useSelector } from 'react-redux';
import asaasService from 'services/asaasService';
import bankService from 'services/bankService';
import authService from 'services/authService';
import app from 'configs/ConfigIndex';
// import PixAddModal from 'components/jarvisly-components/forms/Banks/modal/PixAddModal';

// COMPONENT *******************************************************************
// *****************************************************************************
const Step3 = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { selectedAction, form, document } = props;

  // local variables I ---------------------------------------------------------
  const info = form.getFieldsValue() || {};
  const bank = bankService.getBankByNumber(info.selectedBank) || {};
  const items = buildItems();

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // console.log('info.accountDigit', info.agencyDigit);

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Descriptions bordered items={items} className="w-100 mb-4" column={2} />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildItems() {
    if (selectedAction === 'add') {
      return [
        {
          key: 'bank',
          label: translateX('bank'),
          span: 2,
          children: `${bank.title}`,
        },
        {
          key: 'alias',
          label: translateX('alias'),
          span: 1,
          children: `${translateX(info.alias)}`,
        },
        {
          key: 'type',
          label: translateX('type'),
          span: 1,
          children: `${translateX(info.accountType)}`,
        },
        {
          key: 'agency',
          label: translateX('agency'),
          span: 1,
          children: (
            <div>
              <span>{info.agency}</span>
              {info.agencyDigit ? <span>-{info.agencyDigit}</span> : null}
            </div>
          ),
        },
        {
          key: 'account',
          label: translateX('account'),
          span: 1,
          children: (
            <div>
              <span>{info.account}</span>
              {info.accountDigit ? <span>-{info.accountDigit}</span> : null}
            </div>
          ),
        },
        {
          key: 'note',
          label: translateX('note'),
          span: 2,
          children: info.note,
        },
      ];
    } else {
      return [
        {
          key: 'accountType',
          label: translateX('account_type'),
          span: 2,
          children: `${translateX('legal_account')}`,
        },
        {
          key: 'document',
          label: translateX('cnpj'),
          span: 2,
          children: `${parseCnpjToDisplay(document?.cnpj)}`,
        },
        {
          key: 'companyName',
          label: translateX('company_name'),
          span: 2,
          children: document?.fullName?.toUpperCase(),
        },
        {
          key: 'bank',
          label: translateX('bank'),
          span: 2,
          children: bank.title,
        },
        {
          key: 'alias',
          label: translateX('alias'),
          span: 1,
          children: `${translateX(info.alias)}`,
        },
        {
          key: 'type',
          label: translateX('type'),
          span: 1,
          children: `${translateX('digital_account')}`,
        },
        // {
        //   key: 'responsible',
        //   label: translateX('responsible'),
        //   span: 2,
        //   children: `${toCapitalCase(info?.name)}`,
        // },
        {
          key: 'phone',
          label: translateX('phone'),
          span: 2,
          children: parsePhoneToDisplay(info?.phone),
        },
        {
          key: 'email',
          label: translateX('bank_login'),
          span: 2,
          children: info?.email?.toLowerCase(),
        },
        {
          key: 'note',
          label: translateX('note'),
          span: 2,
          children: info.note,
        },
      ];
    }
  }
};

// COMPONENT *******************************************************************
// *****************************************************************************
const Step2 = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { selectedAction, elRefs, step } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (step === 1) goFocus(elRefs, 'selectedBank');
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps
  // methods -------------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------

  return selectedAction === 'open' ? (
    <JarvislyBankOpenDigitalAccountForm {...props} />
  ) : (
    <JarvislyBankExistingAccountForm {...props} />
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// COMPONENT *******************************************************************
// *****************************************************************************
const Step1 = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { selectedAction, setSelectedAction, setEnabledSteps } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onChange = ({ target: { value: v } }) => {
    setSelectedAction(v);
    setEnabledSteps(prev => prev.filter(i => i !== 2));
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <JarvislyRadio
        onChange={onChange}
        value={selectedAction}
        options={[
          { value: 'add', text: 'add_existing_account' },
          {
            value: 'open',
            text: 'open_new_digital_account',
            disabled: !app.FINTECH_SERVICES,
          },
        ]}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form, elRefs, setFormAddFields } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const {
    record,
    showModal,
    setShowModal,
    afterClose,
    isDisabled,
    updating,
    forceClose,
    setForceClose,
    isWorking,
    setIsWorking,
    setIntegratedAccount,
  } = props;

  // local variables I ----------------------------------------------------------
  const { selectedUser } = useSelector(s => s.authSlice);
  const isDone = record?.status === 'generated_order';
  const title = isDisabled
    ? translateX('view')
    : !record?._id
      ? translateX('add_bank')
      : translateX('edit');

  // component states -----------------------------------------------------------
  const [step, setStep] = useState(0);
  const [enabledSteps, setEnabledSteps] = useState([0]);
  const [selectedAction, setSelectedAction] = useState();

  // local variables II ---------------------------------------------------------
  const stepsArr = buildStepsArray();
  const stepItems = buildSteps(stepsArr, step, isDone, enabledSteps, isWorking);

  const childProps = {
    ...props,
    form,
    step,
    elRefs,
    selectedAction,
    setSelectedAction,
    setEnabledSteps,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form?.resetFields();
    if (record) form?.setFieldsValue(record);
  }, [record, form]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (forceClose) onClose();
  }, [forceClose]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    setStep(0);
    setEnabledSteps([0]);
    setShowModal(false);
    setSelectedAction(null);
    setForceClose && setForceClose(false);
  };

  const getCardTitle = () => {
    switch (step) {
      case 0:
        return 'choice_an_option';

      case 1:
        if (selectedAction === 'open') {
          return 'digital_account_opening';
        } else {
          return 'enter_your_bank_details';
        }

      case 2:
        return 'confirm_your_data';

      default:
        return '';
    }
  };

  const onPrevious = () => {
    switch (step) {
      case 0:
        return onClose();

      case 1:
        return setStep(0);

      case 2:
        return setStep(1);

      default:
        console.error('no case defined!');
        break;
    }
  };

  const onNext = () => {
    switch (step) {
      case 0:
        setIntegratedAccount(null);
        setStep(1);
        break;

      case 1:
        form
          .validateFields()
          .then(async () => {
            const apiIntegrated = form?.getFieldValue('apiIntegrated');
            const apiKey = form?.getFieldValue('apiKey');

            if (apiIntegrated) {
              setIsWorking(true);
              const bankNumber = form?.getFieldValue('selectedBank');

              // integratedAccount
              const ia = await bankService.getIntegratedAccountByApiKey(
                apiKey,
                {
                  bankNumber,
                  userName: selectedUser.name,
                  userEmail: selectedUser.email,
                },
              );

              setIsWorking(false);

              if (!ia) {
                setIntegratedAccount(null);
                appService.messageDestroy('invalid_key');
                appService.message(
                  'w',
                  translateX('invalid_key_for_selected_bank'),
                  'invalid_key',
                  5,
                );
                goFocus(elRefs, 'apiKey', 'all');
                return;
              } else {
                setIntegratedAccount(ia);
                form.setFieldsValue({
                  agency: ia.agency,
                  agencyDigit: ia.agencyDigit,
                  account: ia.account,
                  accountDigit: ia.accountDigit,
                });
              }
            }

            setFormAddFields({ action: selectedAction });
            setStep(2);
          })
          .catch(errorInfo => form.submit());

        break;

      case 2:
        form.submit();
        break;

      default:
        console.error('no case defined!');
        break;
    }

    setEnabledSteps(prev => [...new Set([...prev, step + 1])]);
  };

  const onAfterClose = () => {
    afterClose();
    setStep(0);
    setEnabledSteps([0]);
    setSelectedAction(null);
    form?.resetFields();
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        className="title-fixed-with-18"
        title={
          <>
            <BankOutlined />
            <span className="ml-2">{title}</span>
          </>
        }
        destroyOnClose={true}
        open={showModal}
        width={680}
        onCancel={onClose}
        getContainer={false}
        confirmLoading={updating}
        afterClose={onAfterClose}
        closable={!isWorking}
        footer={
          <JarvislyPreviousNext
            step={step}
            totalSteps={3}
            onPrevious={onPrevious}
            onNext={onNext}
            nextDisabled={!selectedAction || isWorking}
            previousDisabled={isWorking}
          />
        }
      >
        <Spin spinning={isWorking} size="large">
          <Row gutter={ROW_GUTTER} className="mb-2">
            <Card className="w-100 strong-border m-2">
              <Steps
                direction="horizontal"
                size="small"
                current={step}
                className="site-navigation-steps"
                onChange={v => setStep(v)}
                items={stepItems}
              />
            </Card>
          </Row>

          <Row gutter={ROW_GUTTER}>
            <Card
              title={translateX(getCardTitle())}
              className="w-100 strong-border m-2"
            >
              {step === 0 ? <Step1 {...childProps} /> : null}
              {/*{step === 1 ? <Step2 {...childProps} /> : null}*/}
              <Step2
                {...childProps}
                style={{ display: step === 1 ? 'block' : 'none' }}
              />
              {step === 2 ? <Step3 {...childProps} /> : null}
            </Card>
          </Row>
        </Spin>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildStepsArray() {
    return [
      {
        title: 'options',
        // description: 'choice_an_option',
      },
      {
        title: 'data',
        // description: 'inform_your_data',
      },
      {
        title: 'confirm',
        // description: 'confirm_your_data',
      },
    ];
  }
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const JarvislyBanksAddModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    document,
    isWorking,
    setIsWorking,
    refreshDocument,
    selectedModule,
    pixArr,
    selectedRecord,
    setSelectedRecord,
  } = props;

  // local variables I ---------------------------------------------------------
  const { selectedUser } = useSelector(s => s.authSlice);
  const banksList = document?.__banks?.length > 0 ? document?.__banks : null;

  // component states ----------------------------------------------------------
  // for 'banks' tab
  const [showModal, setShowModal] = useState(false);
  const [forceClose, setForceClose] = useState(false);
  const [integratedAccount, setIntegratedAccount] = useState(null);

  // local variables II --------------------------------------------------------
  const banksTabProps = {
    ...props,
    showModal,
    setShowModal,
    selectedRecord,
    setSelectedRecord,
    forceClose,
    setForceClose,
    banksList,
    setIntegratedAccount,
  };

  // hooks ---------------------------------------------------------------------

  useEffect(() => {
    if (!showModal) return;

    if (selectedUser && !selectedRecord) {
      setSelectedRecord({
        // alias: 'ASAAS',
        email: selectedUser?.email,
        name: selectedUser?.name,
        phone: selectedUser?.phoneNumber?.replace('+55', ''),
        // selectedBank: '461',
        // note: 'Conta para Recebimento de Clientes',
      });
    }
  }, [selectedUser, selectedRecord, showModal]); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log('devia ZERAR pix?')
  // useEffect(() => {
  //   if (showModal === false) {
  //     setPixArr([]);
  //   }
  // }, [showModal]); // eslint-disable-line react-hooks/exhaustive-deps

  useImperativeHandle(ref, () => ({
    showModal,
    setShowModal: value => setShowModal(value),
    selectedRecord: selectedRecord,
    setSelectedRecord: value => setSelectedRecord(value),
  }));

  // methods -------------------------------------------------------------------

  const onConfirm = async ({ body }) => {
    if (body.action === 'add') {
      const bank = bankService.getBankByNumber(body?.selectedBank) || {};
      const bankCode = bank.code;
      const agencyCode = `${bankCode}${returnOnlyNumbers(body?.agency)}${
        body?.agencyDigit ? returnOnlyNumbers(body?.agencyDigit) : ''
      }`;
      const accountCode = `${agencyCode}${returnOnlyNumbers(body?.account)}${
        body?.accountDigit ? returnOnlyNumbers(body?.accountDigit) : ''
      }`;

      if (integratedAccount) {
        body.sandbox = integratedAccount?.sandbox || false;
        body.accountProfile = integratedAccount?.accountProfile || 'native';
        setIntegratedAccount(null);
      } else {
        body.sandbox = false;
        body.accountProfile = 'native';
      }

      appService.notificationDestroy('failure');
      appService.messageDestroy('add_account');

      let idx = banksList?.findIndex(b => b.accountCode === accountCode);

      if (banksList && idx > -1) {
        appService.message(
          'w',
          translateX('bank_account_already_exists'),
          'add_account',
          5,
        );
      } else {
        setIsWorking(true);

        try {
          const updatedPixArr = pixArr.map(item => {
            const { _id, ...rest } = item;
            return rest;
          });
          const newBody = {
            ...body,
            pixArr: updatedPixArr,
          };
          await bankService.addBank(newBody, document);
          setForceClose(true);
          if (selectedModule.name === 'accounts') authService.refreshAccount();
          refreshDocument(null, true).then();
        } catch (error) {
          setIsWorking(false);
        }
      }
    } else {
      setIsWorking(true);

      try {
        await asaasService.createAccount(body, document);
        setForceClose(true);
        if (selectedModule.name === 'accounts') authService.refreshAccount();
        refreshDocument(null, true).then();
      } catch (error) {
        setIsWorking(false);
      }
    }
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <JarvislyFormProvider
        name="form-bank"
        document={selectedRecord}
        requiredFields={[
          'name',
          'phone',
          'email',
          'selectedBank',
          'accountType',
          // 'agency',
          // 'account',
          // 'accountDigit',
          'alias',
        ]}
        showSubmitButton={false}
        onConfirm={onConfirm}
        abortComponentSave={true}
      >
        <ModalComponent
          {...banksTabProps}
          showBirthdate
          updating={isWorking}
          record={selectedRecord}
          afterClose={() => {
            setSelectedRecord(null);
          }}
        />
      </JarvislyFormProvider>

      {/* WRAPPER PIX KEY MODAL */}
      {/*<PixAddModal {...banksTabProps} />*/}
      {/* WRAPPER PIX KEY MODAL */}
    </>
  );
});

export default JarvislyBanksAddModal;
