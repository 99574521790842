import { Alert, Card, Col, Collapse, Descriptions, Divider, Row } from 'antd';
import { JarvislyNumber } from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { translateX } from 'utils/helpers';
import dayjs from 'dayjs';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';
import React, { useEffect, useState } from 'react';


// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const IotDevicesConfigurationTab = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document } = props;

  // local variables I ---------------------------------------------------------
  useTabChild('settings', 'downtime', props);
  const initialValues = {
    mirv: 40, // minimal initial range value - for ultrasonic
    mfrv: 30, // minimal final range value - for ultrasonic
    mire: 0, // maximum initial range exceeded - for ultrasonic
    mfre: 0, // maximum final range exceeded - for ultrasonic
  };

  // component states ----------------------------------------------------------
  const [minInitialRangeValue, setMinInitialRangeValue] = useState(
    initialValues.mirv,
  );
  const [minFinalRangeValue, setMinFinalRangeValue] = useState(
    initialValues.mirv + initialValues.mfrv,
  );
  const [maxInitialRangeExceeded, setMaxInitialRangeExceeded] = useState(
    initialValues.mire,
  );
  const [maxFinalRangeExceeded, setMaxFinalRangeExceeded] = useState(
    initialValues.mire + initialValues.mfre,
  );

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    const mirv = document?.field1?.initialRangeValue || initialValues.mirv;
    const mfrv = mirv + initialValues.mfrv;

    // const mire = document?.field1?.initialRangeValue || initialValues.mirv;
    // const mfre = mirv + initialValues.mfrv;

    setMinInitialRangeValue(mirv);
    setMinFinalRangeValue(mfrv);
  }, [document]);

  // methods -------------------------------------------------------------------
  const onChangeMinimalRangeValue = value => {
    setMinFinalRangeValue(value + initialValues.mfrv);
  };

  const onChangeMinimalRangeExceeded = value => {
    setMinFinalRangeValue(value + initialValues.mfrv);
  };

  // local variables III -------------------------------------------------------
  const wlWusInfo = buildWsWusInfoItems();

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* PARAMETERS */}
      <Card title={translateX('parameters')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12}>
            <JarvislyNumber
              label="minimum_distance"
              name={['field1', 'initialRangeValue']}
              min={minInitialRangeValue}
              max={100}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
              onChange={onChangeMinimalRangeValue}
              tooltip={translateX('min_distance_sensor_description')}
            />
          </Col>

          <Col xs={24} sm={12}>
            <JarvislyNumber
              label="maximal_distance"
              name={['field1', 'finalRangeValue']}
              min={minFinalRangeValue}
              max={300}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
              tooltip={translateX('max_distance_sensor_description')}
            />
          </Col>
        </Row>

        <Row>
          <Collapse size="small" className="w-100" items={wlWusInfo} />
        </Row>
      </Card>
      {/* PARAMETERS */}

      {/* ALARM */}
      <Card title={translateX('alarm')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={8}>
            <JarvislyNumber
              label="offline"
              name={['field1', 'downtime']}
              min={5}
              max={1440}
              addonBefore={null}
              addonAfter={translateX('minutes')}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>

          <Col xs={24} sm={8}>
            <JarvislyNumber
              label="minimum_exceeded"
              name={['field1', 'alarmExceededMin']}
              min={0}
              max={1000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
              onChange={onChangeMinimalRangeValue}
              tooltip={translateX('min_distance_sensor_description')}
            />
          </Col>
          <Col xs={24} sm={8}>
            <JarvislyNumber
              label="maximum_exceeded"
              name={['field1', 'alarmExceededMax']}
              min={0}
              max={1000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
            />
          </Col>
          {/*
          <Col xs={24} sm={12} lg={6}>
            <JarvislyNumber
              label="calibration_time"
              name={['field1', 'calibrationTime']}
              min={1}
              max={62000}
              addonBefore={null}
              addonAfter={'cm'}
              decimalSeparator=","
              suffixStyle={{ width: 60 }}
              required
              tooltip={translateX('max_distance_sensor_description')}
            />
          </Col>
*/}
        </Row>
      </Card>
      {/* ALARM */}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildWsWusInfoItems() {
    return [
      {
        key: 'info',
        label: translateX('wl_wus_info_title'),
        children: (
          <>
            <Alert
              style={{ width: '100%', marginBottom: 8 }}
              message={translateX('wl_wus_info_1')}
              type="info"
            />
            <Alert
              style={{ width: '100%', marginBottom: 8 }}
              message={translateX('wl_wus_info_2')}
              type="info"
            />
            <Alert
              style={{ width: '100%' }}
              message={translateX('wl_wus_info_3')}
              type="info"
            />
          </>
        ),
      },
    ];
  }
};

export default IotDevicesConfigurationTab;
