import {
  Avatar,
  Button,
  Card,
  DatePicker,
  Divider,
  Dropdown,
  Flex,
  FloatButton,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Radio,
  Row,
  Segmented,
  Select,
  Space,
  Switch,
  Tooltip,
} from 'antd';
import {
  applyNumberMask,
  debounce,
  generateToken,
  getDateFormatByLocale,
  getObjectByField,
  InputPlate,
  isValidCnpj,
  isValidCpf,
  isValidCpfCnpj,
  isValidEmail,
  isValidPhoneNumber,
  isValidPlate,
  isValidUrl,
  isValidZip,
  normalize,
  returnOnlyNumbers,
  strToNumber,
  translateWord,
  translateX,
} from 'utils/helpers';
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import appService from 'services/appService';
import axiosService from 'services/axiosService';
import {
  CheckCircleOutlined,
  CheckCircleTwoTone,
  CheckOutlined,
  DeleteOutlined,
  DislikeOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  EyeTwoTone,
  IssuesCloseOutlined,
  LeftOutlined,
  MoreOutlined,
  PlusOutlined,
  RightOutlined,
  RocketOutlined,
} from '@ant-design/icons';
import entityService from 'services/entityService';
import { AsyncAutoComplete } from './AsyncAutoComplete';
import PhoneInput from './PhoneInput';
import FormItem from './FormItem';
import CpfCnpjInput from './CpfCnpjInput';
import CepInput from './CepInput';
import GoogleMapReact from 'google-map-react';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { ModuleContext } from '../../jarvisly-module/ModuleContext';
import financialService from 'services/financialService';
import utils from 'utils';

let isOnchangeWorking = false;
let isSubmitting = false;

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { REACT_APP_GOOGLE_API_KEY: GOOGLE_API_KEY } = process.env;

export const JarvislyFormContext = createContext({});
export const JarvislyAddressContext = createContext({});
export const JarvislyTabContext = createContext({});

export const JarvislyFormProvider = props => {
  // providers context ---------------------------------------------------------
  const {
    selectedModule,
    refreshDocument,
    // setForm,
    mode,
    moduleRefreshed,
    moduleFocus,
    setIsWorking,
    lastAction,
    isWorking,
    documentContext,
  } = useContext(ModuleContext);

  // props deconstruction ------------------------------------------------------
  const {
    children,
    name = `form-${generateToken()}`,
    layout = 'vertical',
    autoComplete = 'off',
    initialValues,
    document = null,
    showSubmitButton = false,
    style,
    styleSubmitButton,
    className,
    preserve = true,
    onValuesChange,
    validateTrigger,
    validateInitialValues = false,
    validateData = false,
    forceValidation = false,
    requiredFields = [],
    disabledFields = [],
    autoFocus,

    onSave,
    onConfirm,
    apiUrl = selectedModule?.api,
    idField = '_id',
    onAfterSave,
    onError,

    setTab,
    disabled,
    abortComponentSave = false,
  } = props;

  // local variables I ---------------------------------------------------------
  const elRefs = {};
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // component states ----------------------------------------------------------
  const [addressState, setAddressState] = useState();
  const [additionalFields, setAdditionalFields] = useState({});
  const [ready, setReady] = useState(false);
  const [formRefreshed, setFormRefreshed] = useState(0);
  const [updating, setUpdating] = useState(false);
  const [validating, setValidating] = useState(false);
  const [tabs, setTabs] = useState({});
  const [isDisabled, setDisabled] = useState(disabled);
  const [searchedEntity, setSearchedEntity] = useState(null);
  const [redefinedForm, setRedefinedForm] = useState(null);
  // const [refreshAddress, setRefreshAddress] = useState(0);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form.resetFields();

    if (document) {
      form.setFieldsValue(document);
    } else if (initialValues) {
      form.setFieldsValue(initialValues);
    }

    if (
      (validateInitialValues && initialValues) ||
      (validateData && document) ||
      forceValidation
    ) {
      setTimeout(() => form.validateFields(), 400);
    }

    setReady(true);
    setFormRefreshed(+new Date());
  }, [document]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   setForm(form);
  // }, [form]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsWorking(updating);
  }, [updating]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setDisabled(mode === 'view');
  }, [mode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (form && mode === 'add' && !lastAction) {
      form.resetFields();
      setFormRefreshed(+new Date());
      // form.setFieldsValue(empty)
    }

    if (mode === 'add' && ready) {
      if (moduleFocus?.tab && setTab) setTab(moduleFocus.tab);

      if (elRefs?.[moduleFocus?.field]) {
        goFocus(elRefs, moduleFocus.field, 'end');
      }
    }
  }, [moduleRefreshed, mode, moduleFocus]); // eslint-disable-line react-hooks/exhaustive-deps

  // local variables II --------------------------------------------------------

  // methods -------------------------------------------------------------------
  const formCheck = (action, fieldName) => {
    switch (action) {
      default:
      case 'is_required_field': {
        const f = Array.isArray(fieldName)
          ? fieldName[fieldName.length - 1]
          : fieldName;

        return requiredFields.includes(f);
      }

      case 'is_disabled_field': {
        const f = Array.isArray(fieldName)
          ? fieldName[fieldName.length - 1]
          : fieldName;

        return disabledFields.includes(f);
      }
    }
  };

  const setFormAddFields = fieldsObj => {
    setAdditionalFields(prev => ({ ...prev, ...fieldsObj }));
  };

  const addComponentToTab = (tab, fieldName) => {
    const f = typeof fieldName === 'string' ? [fieldName] : fieldName;

    const isExists = !!tabs?.[tab]?.some(
      i => JSON.stringify(i) === JSON.stringify(f),
    );

    if (!isExists) {
      if (!tabs?.[tab]) {
        tabs[tab] = [f];
      } else {
        tabs[tab].push(f);
      }

      setTabs(prev => ({ ...prev, [tab]: [...tabs[tab]] }));
    }
  };

  const resetForm = () => {
    form?.resetFields();
    setRedefinedForm(+new Date());
  };

  // const handleFormKeyDown = e => {
  //   if (e.keyCode === 13) {
  //     e.preventDefault();
  //   }
  // };

  const onFinishFailed = errorInfo => {
    console.log('errorInfo', errorInfo);

    setSubmitting(false);
    const fieldName = errorInfo.errorFields?.[0]?.name;

    appService.message('w', 'document_not_saved', 'onSave');

    let tabFocus;

    tabs &&
      Object.keys(tabs).map(k => {
        if (
          tabs[k].some(i => JSON.stringify(i) === JSON.stringify(fieldName))
        ) {
          tabFocus = k;
        }
        return k;
      });

    if (tabFocus && setTab) setTab(tabFocus);

    if (elRefs?.[fieldName]) goFocus(elRefs, fieldName);
    onSave && onSave({});
  };

  const onFinish = async values => {
    if (!abortComponentSave) {
      appService.message('i', 'saving_document', 'onSave');
      setUpdating(true);
    }

    let valuesFromForm = addressState?.root
      ? { ...addressState.root, ...values }
      : { ...values };

    const documentContextData = documentContext
      ? { ...documentContext.data }
      : {};

    let dataForm =
      document && !document?._id
        ? { ...document, ...valuesFromForm, ...documentContextData }
        : { ...valuesFromForm, ...documentContextData };

    // treat for multiple address in the same form
    addressState &&
      Object.keys(addressState).map(k => {
        if (!addressState?.[k]?.address2 && addressState[k])
          addressState[k].address2 = null;

        if (k !== 'root') {
          const v = values?.[k] || {};
          dataForm[k] = { ...addressState[k], ...v };
        }
        return k;
      });

    // treat additional fields
    additionalFields &&
      Object.keys(additionalFields).map(k => {
        const path = k.replace(',', '.');
        _.set(dataForm, path, additionalFields[k]);
        return k;
      });

    try {
      console.log('.');
      let body = onSave && (await onSave({ body: dataForm, values }));
      console.log('..');

      if (typeof body === 'boolean') {
        if (!body) {
          setSubmitting(false);
          return setUpdating(false);
        } else {
          body = dataForm;
        }
      } else if (!body) {
        body = dataForm;
      }

      console.log('...');
      if (!abortComponentSave && (!body || (!apiUrl && !onConfirm))) {
        appService.message('w', 'document_not_saved', 'onSave');
        setUpdating(false);
        isSubmitting = false;
        return;
      }

      console.log('....');

      const _id = document?.[idField];
      delete body?.[idField];

      // -------------------------------------------------------------------- //
      // build body._indice
      const indexes = selectedModule?.documentState?.indexes || [];

      const obj = {
        ...document,
        ...body,
      };

      obj._indice = '';
      indexes.forEach(i => {
        const value = getObjectByField(obj, i);
        if (value) obj._indice += normalize(value, 'lower') + ' | ';
      });
      body._indice = obj._indice;
      // -------------------------------------------------------------------- //

      let doc;

      console.log('.....');

      if (onConfirm) {
        console.log(
          'a',
          onConfirm,
          _id,
          values,
          dataForm,
          searchedEntity,
          form,
        );

        doc = await onConfirm({
          _id,
          values,
          body: dataForm,
          searchedEntity,
          form,
        });

        console.log('b');

        if (abortComponentSave) {
          setSubmitting(false);
          return;
        }

        console.log('c');

        if (!doc) {
          appService.message('w', 'document_not_saved', 'onSave');
          setUpdating(false);
          setSubmitting(false);
          return;
        }

        console.log('d');
      } else {
        console.log('e');
        if (!abortComponentSave) {
          [doc] = _id
            ? await axiosService.put({
                _id,
                url: apiUrl,
                data: body,
              })
            : await axiosService.post({
                url: apiUrl,
                data: body,
              });
        }
      }

      console.log('f');

      // doc = document after save (with _id, _metadata...)
      // body = values from form with changes
      // values = values from without changes
      onAfterSave && (await onAfterSave({ doc, body, values }));

      console.log('g');

      // abort auto-save
      if (abortComponentSave) {
        setSubmitting(false);
        return;
      }

      if (!onConfirm) {
        // update url and module context
        if (selectedModule && documentContext?.mode === 'add') {
          const route = selectedModule?.routes.find(r =>
            r?.includes(selectedModule?.urlForm || selectedModule?.url),
          );

          if (route) {
            const newUrl = route.replace(':_id', doc?._id);
            navigate(newUrl);
          }
        }

        await refreshDocument(null);
      }

      setUpdating(false);
      appService.message(
        's',
        _id ? 'document_updated_with_success' : 'document_saved_with_success',
        'onSave',
      );
    } catch (error) {
      appService.message('e', 'error_saving_document', 'onSave');
      appService.console(
        'e',
        `Erro ${error?.data?.code}: ${error?.data?.errorMessage}`,
      );

      onError && onError({ error });
    }

    setSubmitting(false);
  };

  // local variables III -------------------------------------------------------
  const exposedValues = {
    ready,
    document,
    elRefs,
    form,
    setFormAddressState: setAddressState,
    formAddressState: addressState,
    setFormAddFields,
    formAdditionalField: additionalFields,
    formCheck,
    initFocus: autoFocus,

    updating,
    setUpdating,

    validating,
    setValidating,

    addComponentToTab,
    isFormDisabled: isDisabled || isWorking,
    resetForm,

    searchedEntity,
    setSearchedEntity,
    requiredFields,
    disabledFields,
    formRefreshed,
    redefinedForm,
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <JarvislyFormContext.Provider value={exposedValues}>
      <Form
        // onKeyDown={handleFormKeyDown}
        name={name}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete={autoComplete}
        initialValues={initialValues}
        noValidate
        style={{ width: '100%', ...style }}
        layout={layout}
        className={`${className} icon-with-margin`}
        preserve={preserve}
        onValuesChange={onValuesChange}
        validateTrigger={validateTrigger}
      >
        {children}

        {showSubmitButton ? (
          <Flex justify={'end'}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => setSubmitting(true)}
                loading={updating}
                disabled={
                  updating || validating || isWorking || mode === 'view'
                }
                style={styleSubmitButton}
              >
                {translateX('save')}
              </Button>
            </Form.Item>
          </Flex>
        ) : null}
      </Form>
    </JarvislyFormContext.Provider>
  );
};

export const JarvislyAddressProvider = props => {
  // providers context ---------------------------------------------------------
  const { form, setFormAddressState, ready, document, setValidating } =
    useContext(JarvislyFormContext);
  const { setFormWorking } = useContext(ModuleContext);

  // props deconstruction ------------------------------------------------------
  const {
    children,
    addressContext = 'root',
    address1FieldName = addressContext === 'root'
      ? 'address1'
      : [addressContext, 'address1'],
    numberFieldName = addressContext === 'root'
      ? 'number'
      : [addressContext, 'number'],
  } = props;

  // local variables I ---------------------------------------------------------
  const fieldsAddress = $buildFieldAddress();
  const fieldsDisabled = $buildFieldDisabled();
  const fieldsEnabledOrDisabled = $buildFieldEnabledOrDisabled();

  // component states ----------------------------------------------------------
  const [state, setState] = useState({});

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setFormAddressState(prev => ({ ...prev, ...state }));
  }, [state]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ready) {
      if (document) {
        updateAddressState(document, addressContext);
      } else {
        updateAddressState(null, addressContext);
      }
    }
  }, [document, ready]); // eslint-disable-line react-hooks/exhaustive-deps

  // local variables II --------------------------------------------------------
  const isMultipleAddress =
    state?.[addressContext]?.addressMetadata?.zipWithMultipleAddress || false;
  const zip = form?.getFieldValue('zip');
  const isZipOk = isValidZip(zip);

  // methods -------------------------------------------------------------------
  const updateAddressState = (document, context = 'root') => {
    let address = null;

    if (document) {
      const entity = context === 'root' ? document : document?.[context];

      address = {
        erro: false,
        addressTitle: entity?.addressTitle,
        city: entity?.city,
        __city: entity?.__city,
        province: entity?.province,
        addressCoordinates: entity?.addressCoordinates,
        addressAdditionalInfo: entity?.addressAdditionalInfo,
        addressMetadata: entity?.addressMetadata,
        number: entity?.number,
        address1: entity?.address1,
        neighborhood: entity?.neighborhood,
      };
    }

    setAddressState(address);
  };

  const addressCheck = (action, fieldName) => {
    switch (action) {
      case 'is_required_field': {
        // const f = context === 'root' ? 'number' : [context, 'number'];

        const isDisabledOrEnabled =
          addressContext === 'root'
            ? fieldsEnabledOrDisabled.includes(fieldName)
            : fieldsEnabledOrDisabled.some(
                i => JSON.stringify(i) === JSON.stringify(fieldName),
              );

        return isMultipleAddress && isDisabledOrEnabled && isZipOk;
      }

      case 'is_disabled_field': {
        const isDisabled =
          addressContext === 'root'
            ? fieldsDisabled.includes(fieldName)
            : fieldsDisabled.some(
                i => JSON.stringify(i) === JSON.stringify(fieldName),
              );

        const isDisabledOrEnabled =
          addressContext === 'root'
            ? fieldsEnabledOrDisabled.includes(fieldName)
            : fieldsEnabledOrDisabled.some(
                i => JSON.stringify(i) === JSON.stringify(fieldName),
              );

        return (
          isDisabled &&
          (!isMultipleAddress || (isMultipleAddress && !isDisabledOrEnabled))
        );
      }

      case 'is_multiple_address':
        return isMultipleAddress;

      case 'is_address_number': {
        const f =
          addressContext === 'root' ? 'number' : [addressContext, 'number'];
        return fieldName === f;
      }

      case 'search_on_google_maps': {
        const f =
          addressContext === 'root' ? 'number' : [addressContext, 'number'];
        return (
          (fieldName?.toString() === f?.toString() || fieldName === f) &&
          !isMultipleAddress
        );
      }

      case 'check_name_context': {
        if (addressContext !== 'root') {
          return Array.isArray(fieldName)
            ? [addressContext, ...fieldName]
            : [addressContext, fieldName];
        } else {
          return fieldName;
        }
      }

      default:
        return true;
    }
  };

  const setAddressState = (address = {}) => {
    const formFields = form.getFieldValue();
    const f =
      addressContext === 'root' ? formFields : formFields?.[addressContext];

    if (!f?.zip) return;

    setState(prev => ({ ...prev, [addressContext]: { ...address } }));

    if (addressContext === 'root') {
      form?.setFieldsValue({
        ...f,
        ...address,
      });
    } else {
      form?.setFieldValue(addressContext, {
        ...f,
        ...address,
      });
    }
  };

  const updateAddressCoordinates = async (ev, fieldName) => {
    if (addressCheck('search_on_google_maps', fieldName)) {
      debounce($validateAddressNumberOnGoogleMaps, 1000);
    }

    async function $validateAddressNumberOnGoogleMaps() {
      let number = ev?.target?.value || 0;

      const fAddress =
        addressContext === 'root'
          ? form?.getFieldValue()
          : form?.getFieldValue([addressContext]);

      const formAddress = fAddress?.address1;

      const formCity = fAddress?.city;

      const formUf = '';
      if (!formAddress) {
        setFormWorking(false);
        return setValidating(false);
      }

      const fullAddress = formAddress
        ? `${number} ${formAddress}, ${formCity}, ${formUf}`
        : `${formCity}, ${formUf}`;

      const addressCoordinates = await appService.getGpsByAddress(fullAddress);

      // const address = { ...state[addressContext] };

      const address = {
        erro: false,
        addressTitle: fAddress?.addressTitle,
        city: fAddress?.city,
        __city: fAddress?.__city,
        province: fAddress?.province,
        addressCoordinates: addressCoordinates,
        addressAdditionalInfo: fAddress?.addressAdditionalInfo,
        addressMetadata: fAddress?.addressMetadata,
        number: fAddress?.number,
        address1: fAddress?.address1,
        neighborhood: fAddress?.neighborhood,
      };

      if (fieldName.includes('number')) delete address?.[fieldName];

      setAddressState(address);
      setFormWorking(false);
      setValidating(false);
    }
  };

  const decorateAddress = async (addressFromApi, zipNumber) => {
    // decorate address
    const zip = returnOnlyNumbers(addressFromApi?.cep || zipNumber);
    const number = form?.getFieldValue(numberFieldName)
      ? form.getFieldValue(numberFieldName)
      : 0;

    const address = addressFromApi?.logradouro
      ? `${number} ${addressFromApi?.logradouro}, ${addressFromApi?.localidade}, ${addressFromApi?.uf}`
      : `${addressFromApi?.localidade}, ${addressFromApi?.uf}`;

    const addressCoordinates =
      addressFromApi?.logradouro || addressFromApi?.localidade
        ? await appService.getGpsByAddress(address)
        : [];

    const data = {
      erro: addressFromApi?.erro || false, // object from api viacep if cep not found
      addressTitle: translateX('main_address'),
      city: addressFromApi?.localidade || '', // 'Campinas'
      __city: addressFromApi?.localidade
        ? `${addressFromApi?.localidade} - ${addressFromApi?.uf}`
        : '', // 'Campinas - SP'
      province: addressFromApi?.uf || '', // 'SP'
      // addressReference: '', // reference point
      addressCoordinates: addressCoordinates || '',

      addressAdditionalInfo: addressFromApi?.cep
        ? {
            note: addressFromApi?.complemento, // from api   -> 'de 460/461 ao fim'
            areaCode: addressFromApi?.ddd, // '19'
            giaCode: addressFromApi?.gia, // '2446'     -> Guia de Informação e Apuração do ICMS - GIA
            ibgeCode: addressFromApi?.ibge, // '3509502'  -> Instituto Brasileiro de Geografia e Estatística
            siafiCode: addressFromApi?.siafi, // '6291',    -> Sistema Integrado de Administração Financeira
          }
        : null,

      addressMetadata: {
        countryCode: 'br',
        foundByApi: !!addressFromApi?.cep,
        apiUrl: `https://viacep.com.br/ws/${zip}/json/`,
        zipWithMultipleAddress:
          addressFromApi?.cep && !addressFromApi?.logradouro,
      },
    };

    if (number) data.number = number;
    if (addressFromApi?.logradouro || !addressFromApi?.cep) {
      data.address1 = addressFromApi?.logradouro || '';
    }
    if (addressFromApi?.bairro || !addressFromApi?.cep) {
      data.neighborhood = addressFromApi?.bairro || '';
    }
    return data;
  };

  // local variables III -------------------------------------------------------
  const exposedValues = {
    addressContext,
    setAddressState,
    addressState: state?.[addressContext],
    allAddress: state,
    address1FieldName,
    numberFieldName,
    decorateAddress,
    fieldsAddress,
    fieldsAddressToLock: fieldsDisabled,
    updateAddressCoordinates,
    addressCheck,
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <JarvislyAddressContext.Provider value={exposedValues}>
      {children}
    </JarvislyAddressContext.Provider>
  );

  // internal functions --------------------------------------------------------
  function $buildFieldAddress() {
    const f = [
      'address1',
      'neighborhood',
      'city',
      '__city',
      'address2',
      'number',
    ];

    if (addressContext === 'root') return f;
    return f.map(i => [addressContext, i]);
  }

  function $buildFieldDisabled() {
    const f = ['address1', 'neighborhood', 'city', '__city'];
    if (addressContext === 'root') return f;
    return f.map(i => [addressContext, i]);
  }

  function $buildFieldEnabledOrDisabled() {
    const f = ['address1', 'neighborhood'];
    if (addressContext === 'root') return f;
    return f.map(i => [addressContext, i]);
  }
};

export const JarvislyTabProvider = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { children, tab } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // local variables II --------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------
  const exposedValues = { tab };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <JarvislyTabContext.Provider value={exposedValues}>
      {children}
    </JarvislyTabContext.Provider>
  );

  // internal functions --------------------------------------------------------
};

export const JarvislyInput = ({
  isListField,
  fieldKey,
  'x-no-label': xNoLabel,
  ...props
}) => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);

  const {
    elRefs,
    initFocus,
    formCheck,
    addComponentToTab,
    isFormDisabled,
    setValidating,
  } = useContext(JarvislyFormContext);

  const { addressCheck, updateAddressCoordinates } = useContext(
    JarvislyAddressContext,
  );

  const { setFormWorking } = useContext(ModuleContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'textField',
    invalidErrorLabel = 'invalid_data',
    componentName = 'JarvislyInput',
    name = id,
    onChange,
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
    value,
    defaultValue,
  } = props;

  // local variables I ---------------------------------------------------------
  name = addressCheck ? addressCheck('check_name_context', name) : name;
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);

  elRefs[name] = useRef(null);

  // address treatment
  const lockField = addressCheck && addressCheck('is_disabled_field', name);

  if (!disabled && addressCheck) {
    disabled = addressCheck('is_disabled_field', name);
  }
  if (addressCheck && addressCheck('is_required_field', name)) {
    required = true;
  }

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onLocalChange = async ev => {
    setValidating(name?.includes('number'));
    setFormWorking(name?.includes('number'));
    // for address context provider
    if (updateAddressCoordinates) {
      await updateAddressCoordinates(ev, name);
    }

    if (onChange) await onChange(ev);
  };

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    xNoLabel,
    required,
    isListField,
    fieldKey,

    hasFeedback: false,
    validationFn: null,
    feedBackStatus,
    setFeedBackStatus,
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <Input
        {...props}
        ref={elRefs[name]}
        onChange={onLocalChange}
        disabled={disabled || lockField}
        autoFocus={autoFocus}
        value={value}
        defaultValue={defaultValue}
      />
    </FormItem>
  );
};

export const JarvislyPhone = ({
  'x-no-label': xNoLabel,
  isListField,
  fieldKey,
  ...props
}) => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { elRefs, initFocus, formCheck, addComponentToTab, isFormDisabled } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'phone',
    componentName = 'JarvislyPhone',
    invalidErrorLabel = props?.profile?.toUpperCase() === 'MOBILE'
      ? 'invalid_mobile_number'
      : 'invalid_phone_number',
    name = id,
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    xNoLabel,
    required,

    hasFeedback: true,
    validationFn: isValidPhoneNumber,
    feedBackStatus,
    setFeedBackStatus,
  };

  // const onBlur = () => {
  //   form?.validateFields([name]);
  // };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <PhoneInput
        {...props}
        ref={elRefs[name]}
        autoFocus={autoFocus}
        disabled={disabled}
        // onBlur={onBlur}
      />
    </FormItem>
  );
};

export const JarvislyEmail = props => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { elRefs, initFocus, formCheck, addComponentToTab, isFormDisabled } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'email',
    componentName = 'JarvislyEmail',
    invalidErrorLabel = 'invalid_email',
    name = id,
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,

    hasFeedback: true,
    validationFn: isValidEmail,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <Input
        {...props}
        ref={elRefs[name]}
        autoFocus={autoFocus}
        disabled={disabled}
      />
    </FormItem>
  );
};

export const JarvislyMultiEmails = props => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const {
    elRefs,
    initFocus,
    formCheck,
    addComponentToTab,
    isFormDisabled,
    form,
  } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'email',
    componentName = 'JarvislyEmail',
    invalidErrorLabel = 'invalid_email',
    name = id,
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
    options = [],
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);
  let decoratedOptions = options?.map(e => e && { value: e }) || [];

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,

    hasFeedback: true,
    validationFn: isValidEmail,
    feedBackStatus,
    setFeedBackStatus,
  };

  // component states ----------------------------------------------------------
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onChange = arr => {
    const newArr = arr.filter(e => isValidEmail(e));
    form.setFieldValue(name, newArr);
    form.validateFields([name]);
    setIsSelectOpen(false);
  };

  const onInputKeyDown = () => {
    setIsSelectOpen(decoratedOptions.length > 0);
  };

  const onBlur = () => {
    setIsSelectOpen(false);
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <Select
        onChange={onChange}
        mode="tags"
        allowClear
        open={isSelectOpen}
        onBlur={onBlur}
        onInputKeyDown={onInputKeyDown}
        onFocus={onInputKeyDown}
        style={{ width: '100%' }}
        placeholder="Digite e pressione Enter para adicionar emails"
        options={decoratedOptions}
        ref={elRefs[name]}
        showSearch
        // filterOption={defaultFilterOption}
        disabled={disabled}
        required={required}
        autoFocus={autoFocus}
      />
    </FormItem>
  );
};

export const JarvislyWebsite = props => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { elRefs, initFocus, formCheck, addComponentToTab, isFormDisabled } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'website',
    invalidErrorLabel = 'invalid_website',
    componentName = 'JarvislyWebsite',
    name = id,
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,

    hasFeedback: true,
    validationFn: isValidUrl,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <Input
        {...props}
        ref={elRefs[name]}
        autoFocus={autoFocus}
        disabled={disabled}
      />
    </FormItem>
  );
};

export const JarvislyPassword = props => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { elRefs, initFocus, formCheck, addComponentToTab, isFormDisabled } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'password',
    invalidErrorLabel = 'invalid_password',
    componentName = 'JarvislyPassword',
    name = id,
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,

    hasFeedback: false,
    validationFn: null,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <Input.Password
        ref={elRefs[name]}
        {...props}
        autoFocus={autoFocus}
        iconRender={visible =>
          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
        }
        disabled={disabled}
      />
    </FormItem>
  );
};

export const JarvislyCpfCnpj = props => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { elRefs, initFocus, formCheck, addComponentToTab, isFormDisabled } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'cpfCnpj',
    label = 'cpf_cnpj',
    componentName = 'JarvislyCpfCnpj',
    invalidErrorLabel = 'invalid_document',
    name = id,
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    label,
    componentName,
    invalidErrorLabel,
    name,
    required,

    hasFeedback: true,
    validationFn: isValidCpfCnpj,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <CpfCnpjInput
        {...props}
        ref={elRefs[name]}
        autoFocus={autoFocus}
        disabled={disabled}
      />
    </FormItem>
  );
};

export const JarvislyCpf = props => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { elRefs, initFocus, formCheck, addComponentToTab, isFormDisabled } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'cpf',
    componentName = 'JarvislyCpf',
    invalidErrorLabel = 'invalid_cpf',
    name = id,
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,

    hasFeedback: true,
    validationFn: isValidCpf,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <CpfCnpjInput
        {...props}
        ref={elRefs[name]}
        profile="cpf"
        autoFocus={autoFocus}
        disabled={disabled}
      />
    </FormItem>
  );
};

export const JarvislyCnpj = props => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { elRefs, initFocus, formCheck, addComponentToTab, isFormDisabled } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'cnpj',
    componentName = 'JarvislyCnpj',
    invalidErrorLabel = 'invalid_cnpj',
    name = id,
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,

    hasFeedback: true,
    validationFn: isValidCnpj,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <CpfCnpjInput
        {...props}
        ref={elRefs[name]}
        profile="cnpj"
        autoFocus={autoFocus}
        disabled={disabled}
      />
    </FormItem>
  );
};

export const JarvislyCep = props => {
  // Multi Address Zip Codes -> 08970000, 08900000, 06890
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const {
    elRefs,
    initFocus,
    formCheck,
    form,
    addComponentToTab,
    isFormDisabled,
  } = useContext(JarvislyFormContext);
  const {
    setAddressState,
    address1FieldName,
    numberFieldName,
    decorateAddress,
    addressCheck,
    addressContext,
  } = useContext(JarvislyAddressContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'zip',
    componentName = 'JarvislyCep',
    invalidErrorLabel = 'invalid_zip',
    name = id,
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
  } = props;

  // local variables I ---------------------------------------------------------
  name = addressCheck ? addressCheck('check_name_context', name) : name;
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');
  const [forceDisable, setForceDisable] = useState(false);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onGetZipFromApi = async ev => {
    const zip = typeof ev === 'object' ? ev?.target?.value : ev;

    const MASK = '99999-999';
    const MAX_LENGTH = 8;

    let zipNumber = returnOnlyNumbers(zip);
    let zipString = applyNumberMask(zipNumber, MASK);

    // if (zipNumber === lastSearch) {
    //   return lastFeedBack ? $done(lastFeedBack) : null;
    // }

    const emptyAddress = await decorateAddress({}, zipNumber);
    const objRequired = {
      error: true,
      status: 'error',
      message: 'required',
      data: emptyAddress,
      keepTyping: true,
    };
    const objInvalid = {
      error: true,
      status: 'error',
      message: invalidErrorLabel,
      data: emptyAddress,
      keepTyping: true,
    };
    const objDefault = {
      error: false,
      status: '',
      message: '',
      data: emptyAddress,
      keepTyping: true,
    };

    if (zipNumber?.length !== MAX_LENGTH) {
      if (zipNumber?.length) {
        return $done(objInvalid);
      } else if (required) {
        return $done(objRequired);
      } else {
        return $done(objDefault);
      }
    }

    setFeedBackStatus('validating');
    setForceDisable(true);
    let data = await $searchCep(zipString);
    let decoratedData = await decorateAddress(data, zipNumber);

    setAddressState(decoratedData);

    if (decoratedData?.erro) {
      return $done({
        error: true,
        status: 'error',
        message: decoratedData?.message || 'zip_not_found',
        data: emptyAddress,
      });
    } else {
      return $done({
        error: false,
        status: 'success',
        message: null,
        data: decoratedData,
      });
    }

    function $done(data) {
      if (!data.keepTyping) setForceDisable(false);

      const isMultipleAddress =
        data?.data?.addressMetadata?.zipWithMultipleAddress;

      const focusFieldName =
        data?.erro || data?.error
          ? name
          : isMultipleAddress
            ? address1FieldName
            : numberFieldName;

      if (addressContext === 'root') {
        form?.setFieldsValue(data.data);
      } else {
        form?.setFieldsValue({ [addressContext]: data.data });
      }

      if (!data.keepTyping) {
        goFocus(elRefs, focusFieldName, 'all');
      }

      setFeedBackStatus(data);
      return data;
    }
  };

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,

    hasFeedback: true,
    validationFn: isValidZip,
    feedBackStatus,
    setFeedBackStatus,
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <CepInput
        {...props}
        ref={elRefs[name]}
        autoFocus={autoFocus}
        onChange={onGetZipFromApi}
        disabled={disabled || forceDisable}
      />
    </FormItem>
  );

  // internal functions --------------------------------------------------------

  async function $searchCep(value) {
    const viaCepUrl = `https://viacep.com.br/ws/${value}/json/`;

    return fetch(viaCepUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('zip_not_found');
        }

        return response;
      })
      .then(response => {
        return response.json();
      });
  }
};

export const JarvislyDatePicker = props => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { elRefs, initFocus, formCheck, addComponentToTab, isFormDisabled } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'date',
    componentName = 'JarvislyDatePicker',
    invalidErrorLabel = 'invalid_date',
    name = id,
    format = getDateFormatByLocale(),
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,

    hasFeedback: false,
    validationFn: null,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <DatePicker
        {...props}
        ref={elRefs[name]}
        className="w-100 no-border-radius"
        format={format}
        autoFocus={autoFocus}
        disabled={disabled}
        // disabledDate={disabledDate}
      />
    </FormItem>
  );

  // internal functions --------------------------------------------------------
};

export const JarvislyRadioButtons = props => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { elRefs, initFocus, formCheck, addComponentToTab, isFormDisabled } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'date',
    componentName = 'JarvislyDatePicker',
    invalidErrorLabel = 'invalid_date',
    name = id,
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
    options = [
      { value: 1, label: 'option_1' },
      { value: 2, label: 'option_2' },
    ],
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,

    hasFeedback: false,
    validationFn: null,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <Radio.Group
        {...props}
        ref={elRefs[name]}
        options={options}
        optionType="button"
        buttonStyle="solid"
        autoFocus={autoFocus}
        disabled={disabled}
      />
    </FormItem>
  );
};

export const JarvislyDateRangePicker = props => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { elRefs, initFocus, formCheck, addComponentToTab, isFormDisabled } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'period',
    componentName = 'JarvislyRangePicker',
    invalidErrorLabel = 'invalid_date',
    name = id,
    format = getDateFormatByLocale(),
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,

    hasFeedback: false,
    validationFn: null,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <RangePicker
        {...props}
        ref={elRefs[name]}
        className="w-100 no-border-radius"
        format={format}
        initFocus={autoFocus}
        disabled={disabled}
      />
    </FormItem>
  );

  // internal functions --------------------------------------------------------
};

// isListField and fieldKey are implicit props from <Form.List/>
export const JarvislySelect = ({
  isListField,
  fieldKey,
  'x-no-label': xNoLabel,
  'x-show-add-button': showAddButton = false,
  'x-on-add-button-label': onAddButtonLabel = 'new',
  'x-on-add-button-fn': onAddButtonFn = () => {},
  ...props
}) => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { elRefs, initFocus, formCheck, addComponentToTab, isFormDisabled } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'selector',
    componentName = 'JarvislySelect',
    invalidErrorLabel = 'invalid_data',
    name = id,
    options = $buildOptions(),
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
    filterOptions = defaultFilterOption,

    // add button
    // showAddButton = false,
    // onAddButtonLabel = 'new',
    // onAddButtonFn = () => {},
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,
    xNoLabel,
    isListField,
    fieldKey,

    hasFeedback: false,
    validationFn: null,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <Select
        {...props}
        ref={elRefs[name]}
        filterOption={filterOptions}
        options={options}
        autoFocus={autoFocus}
        disabled={disabled}
        showSearch
        allowClear
        dropdownRender={menu => (
          <>
            {menu}
            {showAddButton ? (
              <div>
                <Divider style={{ margin: '8px 0' }} />
                <Flex justify="center" align="center">
                  <Button
                    size="small"
                    type="primary"
                    className="m-2 pl-2 pr-3"
                    icon={<PlusOutlined />}
                    onClick={onAddButtonFn}
                  >
                    {translateX(onAddButtonLabel)}
                  </Button>
                </Flex>
              </div>
            ) : null}
          </>
        )}
      />
    </FormItem>
  );

  // internal functions --------------------------------------------------------
  function $buildOptions() {
    return [
      { value: 1, label: 'Option 1' },
      { value: 2, label: 'Option 2 (disabled)', disabled: true },
      {
        value: 3,
        label: 'Option 3',
      },
    ];
  }
};

export const JarvislySelectGender = props => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { elRefs, initFocus, formCheck, addComponentToTab, isFormDisabled } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'gender',
    componentName = 'JarvislySelectGender',
    invalidErrorLabel = 'invalid_data',
    name = id,
    level = 1,
    options = $buildOptions(level),
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,

    hasFeedback: false,
    validationFn: null,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <Select
        {...props}
        ref={elRefs[name]}
        filterOption={defaultFilterOption}
        options={options}
        autoFocus={autoFocus}
        disabled={disabled}
        showSearch
        allowClear
      />
    </FormItem>
  );

  // internal functions --------------------------------------------------------
  function $buildOptions() {
    return [
      {
        value: 'female',
        text: translateX('female'),
        label: translateX('female'),
        disabled: false,
        level: 1,
      },
      {
        value: 'male',
        text: translateX('male'),
        label: translateX('male'),
        disabled: false,
        level: 1,
      },
      {
        value: 'prefer_not_inform',
        text: translateX('prefer_not_inform'),
        label: translateX('prefer_not_inform'),
        disabled: false,
        level: 2,
      },
    ];
  }
};

export const JarvislySelectCompanyType = props => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { elRefs, initFocus, formCheck, addComponentToTab, isFormDisabled } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'companyType',
    componentName = 'JarvislySelectCompanyType',
    invalidErrorLabel = 'invalid_data',
    label = 'company_size',
    name = id,
    level = 1,
    options = $buildOptions(level),
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,
    label,

    hasFeedback: false,
    validationFn: null,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <Select
        {...props}
        ref={elRefs[name]}
        filterOption={defaultFilterOption}
        options={options}
        autoFocus={autoFocus}
        disabled={disabled}
        showSearch
        allowClear
      />
    </FormItem>
  );

  // internal functions --------------------------------------------------------
  function $buildOptions() {
    return [
      {
        value: 'mei',
        text: translateX('mei_description'),
        label: translateX('mei'),
        disabled: false,
      },
      {
        value: 'individual',
        text: translateX('individual_description'),
        label: translateX('individual'),
        disabled: false,
      },
      {
        value: 'limited',
        text: translateX('limited_description'),
        label: translateX('limited'),
        disabled: false,
      },
      {
        value: 'association',
        text: translateX('association_description'),
        label: translateX('association'),
        disabled: false,
      },
      {
        value: 'sa',
        text: translateX('s_a'),
        label: translateX('s_a'),
        disabled: false,
      },
    ];
  }
};

export const JarvislySelectAvatars = props => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { elRefs, initFocus, formCheck, addComponentToTab, isFormDisabled } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'avatares',
    componentName = 'JarvislySelectAvatares',
    invalidErrorLabel = 'invalid_data',
    name = id,
    // level = 1,
    // options = $buildOptions(level),
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
    mode = 'single',
    members = mockTeamMembers,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,

    hasFeedback: false,
    validationFn: null,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  /*
  const m = [
    {
      id: 'eileenHorton-1153',
      name: 'Eileen Horton',
      email: 'eileen_h@hotmail.com',
      pictureUrl: '/img/avatars/thumb-1.jpg',
    },
  ];
*/

  // UI COMPONENTS -------------------------------------------------------------
  const memberTagRender = props => (
    <TeamAvatarsSelect _id={props.value} size={32} members={members} />
  );

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <Select
        {...props}
        ref={elRefs[name]}
        filterOption={false}
        // options={options}
        autoFocus={autoFocus}
        disabled={disabled}
        // showSearch
        // allowClear
        tagRender={memberTagRender}
        mode={mode}
        removeIcon={null}
        // placeholder="None"
        className="avatar-select board-card-modal select"
        // style={{ width: '100%' }}
      >
        {members.map(elm => (
          <Option key={elm._id} value={elm._id}>
            <TeamAvatarsSelect _id={elm._id} name size={32} members={members} />
          </Option>
        ))}
      </Select>
    </FormItem>
  );

  // internal functions --------------------------------------------------------
  /*
    function $buildOptions() {
      return [
        {
          value: 'female',
          text: translateX('female'),
          label: translateX('female'),
          disabled: false,
          level: 1,
        },
        {
          value: 'male',
          text: translateX('male'),
          label: translateX('male'),
          disabled: false,
          level: 1,
        },
        {
          value: 'prefer_not_inform',
          text: translateX('prefer_not_inform'),
          label: translateX('prefer_not_inform'),
          disabled: false,
          level: 2,
        },
      ];
    }
  */
};

export const JarvislyTextArea = ({ 'x-no-label': xNoLabel, ...props }) => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { elRefs, initFocus, formCheck, addComponentToTab, isFormDisabled } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'text',
    invalidErrorLabel = 'invalid_data',
    componentName = 'JarvislyTextArea',
    name = id,
    rows = 2,
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    xNoLabel,
    required,

    hasFeedback: false,
    validationFn: null,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <TextArea
        {...props}
        rows={rows}
        ref={elRefs[name]}
        autoFocus={autoFocus}
        disabled={disabled}
      />
    </FormItem>
  );
};

export const JarvislySwitch = ({ value, 'x-no-label': xNoLabel, ...props }) => {
  // this component is the unique that not can pass {...props}, need to pass property
  // by property because this not have the 'value' property, it has 'check' instead
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { document, mode } = useContext(ModuleContext);
  const {
    elRefs,
    initFocus,
    formCheck,
    addComponentToTab,
    isFormDisabled,
    form,
  } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'switch',
    invalidErrorLabel = 'invalid_data',
    componentName = 'JarvislySwitch',
    name = id,
    showLabel = false,
    checkedChildren = showLabel && translateX('yes'),
    unCheckedChildren = showLabel && translateX('no'),
    // checked,
    onChange,
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
    style,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,
    xNoLabel,

    hasFeedback: false,
    validationFn: null,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  const checked =
    mode === 'view'
      ? getObjectByField(document, name)
      : form.getFieldValue(name);

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <Switch
        // defaultChecked={getObjectByField(document, name)}
        checked={checked}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        ref={elRefs[name]}
        onChange={onChange}
        disabled={disabled}
        autoFocus={autoFocus}
        style={style}
      />
    </FormItem>
  );
};

export const JarvislySegmented = ({ 'x-no-label': xNoLabel, ...props }) => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const { elRefs, initFocus, formCheck, addComponentToTab, isFormDisabled } =
    useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'segment',
    componentName = 'JarvislySegmented',
    invalidErrorLabel = 'invalid_data',
    name = id,
    options = $buildOptions(),
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
    block = true,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,
    xNoLabel,

    hasFeedback: false,
    validationFn: null,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <Segmented
        {...props}
        ref={elRefs[name]}
        options={options}
        autoFocus={autoFocus}
        disabled={disabled}
        block={block}
        // onChange={value => setStatus(value)}
      />
    </FormItem>
  );

  // internal functions --------------------------------------------------------
  function $buildOptions() {
    return [
      { value: 1, label: 'Option 1', icon: <IssuesCloseOutlined /> },
      {
        value: 2,
        label: 'Option 2 (disabled)',
        icon: <ExclamationCircleOutlined />,
        disabled: true,
      },
      { value: 3, label: 'Option 3', icon: <CheckCircleOutlined /> },
    ];
  }
};

export const JarvislyNumber = ({
  isListField,
  fieldKey,
  'x-prefix-style': xPrefixStyle,
  'x-suffix-style': xSuffixStyle,
  'x-no-label': xNoLabel,
  ...props
}) => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const {
    elRefs,
    initFocus,
    formCheck,
    setFormAddFields,
    formAdditionalField,
    addComponentToTab,
    isFormDisabled,
  } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'inputNumber',
    invalidErrorLabel = 'invalid_data',
    componentName = 'JarvislyNumber',
    name = id,
    style = {},
    autoFocus = name === initFocus,

    addonBefore = $buildAddonBeforeSample(),
    prefixDefaultValue = 'usd', // prefixStyle,
    onPrefixChange,
    prefixDisabled,

    addonAfter = $buildAddonAfterSample(),
    suffixDefaultValue = 'yearly', // suffixStyle,
    onSuffixChange,
    suffixDisabled,

    required,
    context,
    disabled = isFormDisabled,

    decimalSeparator = ',',
    min,
    max,

    onChange,
    onClick,
    placeholder,
    keyboard,
    onKeyDown,
    controles,
    className,
    value,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  if (typeof required === 'undefined') {
    required = formCheck('is_required_field', name);
  }
  elRefs[name] = useRef(null);

  if (!style.width) style.width = '100%';

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,
    xNoLabel,
    isListField,
    fieldKey,

    hasFeedback: false,
    validationFn: null,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prefixDefaultValue) onLocalPrefixChange(prefixDefaultValue).then();
    if (suffixDefaultValue) onLocalSuffixChange(suffixDefaultValue).then();
  }, [prefixDefaultValue, suffixDefaultValue, isListField, fieldKey]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onLocalPrefixChange = async value => {
    if (!addonBefore) return;
    setFormAddFields({ [`${name}Prefix`]: value });
    onPrefixChange && (await onPrefixChange(value));
  };

  const onLocalSuffixChange = async value => {
    if (!addonAfter) return;
    setFormAddFields({ [`${name}Suffix`]: value });
    onSuffixChange && (await onSuffixChange(value));
  };

  // UI COMPONENTS -------------------------------------------------------------

  // PREFIX
  const AddonPrefix = (
    <Select
      defaultValue={prefixDefaultValue}
      style={xPrefixStyle}
      filterOption={defaultFilterOption}
      onChange={onLocalPrefixChange}
      disabled={disabled || prefixDisabled}
      value={formAdditionalField?.[`${name}Prefix`]}
    >
      {Array?.isArray(addonBefore) &&
        addonBefore.map(item => (
          <Option
            key={item.value}
            value={item.value}
            text={item.text}
            disabled={item.disabled}
          >
            {item?.tooltip ? (
              <Tooltip title={item.tooltip}>{item.label}</Tooltip>
            ) : (
              item.label
            )}
          </Option>
        ))}
    </Select>
  );
  if (addonBefore && Array.isArray(addonBefore)) addonBefore = AddonPrefix;

  // SUFFIX
  const AddonSuffix = (
    <Select
      defaultValue={suffixDefaultValue}
      style={xSuffixStyle}
      filterOption={defaultFilterOption}
      onChange={onLocalSuffixChange}
      disabled={disabled || suffixDisabled}
      value={formAdditionalField?.[`${name}Suffix`]}
    >
      {Array?.isArray(addonAfter) &&
        addonAfter.map(item => (
          <Option
            key={item.value}
            value={item.value}
            text={item.text}
            disabled={item.disabled}
          >
            {item?.tooltip ? (
              <Tooltip title={item.tooltip}>{item.label}</Tooltip>
            ) : (
              item.label
            )}
          </Option>
        ))}
    </Select>
  );
  if (addonAfter && Array.isArray(addonAfter)) addonAfter = AddonSuffix;

  return (
    <FormItem {...formItemProps}>
      <InputNumber
        ref={elRefs[name]}
        addonBefore={addonBefore}
        addonAfter={addonAfter}
        value={value}
        style={style}
        autoFocus={autoFocus}
        disabled={disabled}
        decimalSeparator={decimalSeparator}
        min={min}
        max={max}
        onChange={onChange}
        onClick={onClick}
        placeholder={placeholder}
        keyboard={keyboard}
        onKeyDown={onKeyDown}
        controls={controles}
        className={className}
      />
    </FormItem>
  );

  // internal functions --------------------------------------------------------
  function $buildAddonBeforeSample() {
    return [
      {
        value: 'usd',
        text: translateX('dollar'),
        label: translateX('dollar'),
        tooltip: translateX('usd_description'),
        disabled: false,
      },
      {
        value: 'eur',
        text: translateX('euro'),
        label: translateX('euro'),
        tooltip: translateX('eur_description'),
        disabled: true,
      },
      {
        value: 'brl',
        text: translateX('real'),
        label: translateX('real'),
        tooltip: translateX('brl_description'),
        disabled: false,
      },
    ];
  }

  function $buildAddonAfterSample() {
    return [
      {
        value: 'monthly',
        text: translateX('monthly'),
        label: translateX('monthly'),
        tooltip: translateX('monthly_payment'),
        disabled: false,
      },
      {
        value: 'yearly',
        text: translateX('yearly'),
        label: translateX('yearly'),
        tooltip: translateX('yearly_payment'),
        disabled: false,
      },
    ];
  }
};

export const JarvislyMoneyPercentage = ({
  'x-prefix-style': xPrefixStyle,
  'x-suffix-style': xSuffixStyle,
  'x-no-label': xNoLabel,
  ...props
}) => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const {
    elRefs,
    initFocus,
    formCheck,
    setFormAddFields,
    formAdditionalField,
    addComponentToTab,
    isFormDisabled,
    form,
  } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'inputNumber',
    invalidErrorLabel = 'invalid_data',
    componentName = 'JarvislyNumber',
    name = id,
    style = {},
    autoFocus = name === initFocus,

    addonBefore = financialService.valueOrPercentageOptions(),
    prefixDefaultValue = 'percentage', // prefixStyle,

    addonAfter = $buildAddonAfterSample(),
    suffixDefaultValue = 0, // suffixStyle,
    onSuffixChange,

    required,
    context,
    disabled = isFormDisabled,

    decimalSeparator = ',',
    min,
    max,

    mainValue = 0,
    profile = ['value'],
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  if (!style.width) style.width = '100%';
  //if (mainValue) mainValue = form?.getFieldValue(mainValue) || mainValue;

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');
  const [interestConversion, setInterestConversion] = useState('');
  const [interestType, setInterestType] = useState('percentage');

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,
    xNoLabel,

    hasFeedback: false,
    validationFn: null,
    feedBackStatus,
    setFeedBackStatus,
    toolTipText: `${translateX(
      'reference_value',
    )}: ${financialService.formatMoney(mainValue, 2, 'brl', true)}`,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prefixDefaultValue)
      onLocalPrefixChange(prefixDefaultValue, true).then();
    if (suffixDefaultValue)
      onLocalSuffixChange(suffixDefaultValue, true).then();
  }, [prefixDefaultValue, suffixDefaultValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    onInterestChange();
  }, [mainValue, form.getFieldValue(name)]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onLocalPrefixChange = async value => {
    if (!addonBefore) return;
    setFormAddFields({ [`${name}Prefix`]: value });
    onInterestPrefixChange && onInterestPrefixChange(value);
  };

  const onLocalSuffixChange = async (value, useEffectLoad) => {
    if (!addonAfter) return;
    setFormAddFields({ [`${name}Suffix`]: value });
    onSuffixChange && (await onSuffixChange(value, useEffectLoad));
  };

  const onInterestPrefixChange = (type = interestType) => {
    const value = mainValue; // && (form.getFieldValue(mainValue) || mainValue);
    const fieldNumber = form.getFieldValue(name);

    if (type === 'value') {
      const opposite = financialService.formatNumber(interestConversion, 3);
      const number = opposite || financialService.formatNumber(fieldNumber, 2);
      const conversion = financialService.valueToPercentage(
        value,
        number,
        3,
        true,
      );

      form.setFieldValue(
        name,
        financialService.formatMoney(opposite, 2, '', true),
      );
      setInterestConversion(`${conversion}%`);
      setInterestType(type);
      setFormAddFields({ [`${name}Suffix`]: strToNumber(conversion) });
    } else {
      const opposite = financialService.formatNumber(interestConversion, 3);
      const number = opposite || financialService.formatNumber(fieldNumber, 3);
      const conversion = financialService.percentageToValue(
        value,
        number,
        2,
        true,
      );

      form.setFieldValue(name, financialService.formatNumber(opposite, 3));
      setInterestConversion(`R$ ${conversion}`);
      setInterestType(type);
      setFormAddFields({ [`${name}Suffix`]: strToNumber(conversion) });
    }
  };

  const onInterestChange = () => {
    const number = strToNumber(form.getFieldValue(name));

    if (isNaN(number)) return;

    const value = mainValue; // form.getFieldValue(mainValue) || 0;

    if (interestType === 'value') {
      const conversion = financialService.valueToPercentage(
        value,
        number,
        3,
        true,
      );
      setInterestConversion(`${conversion}%`);
      setFormAddFields({ [`${name}Suffix`]: strToNumber(conversion) });
    } else {
      const conversion = financialService.percentageToValue(
        value,
        number,
        2,
        true,
      );
      setInterestConversion(`R$ ${conversion}`);
      setFormAddFields({ [`${name}Suffix`]: strToNumber(conversion) });
    }
  };

  // UI COMPONENTS -------------------------------------------------------------

  // PREFIX
  const AddonPrefix = (
    <Select
      defaultValue="percentage"
      style={xPrefixStyle}
      filterOption={defaultFilterOption}
      onChange={onLocalPrefixChange}
      disabled={disabled || profile.length === 1}
      value={formAdditionalField?.[`${name}Prefix`]}
    >
      {Array?.isArray(addonBefore) &&
        addonBefore
          .map(item => (
            <Option
              key={item.value}
              value={item.value}
              text={item.text}
              disabled={item.disabled || profile.length === 1}
            >
              {item?.tooltip ? (
                <Tooltip title={item.tooltip}>{item.label}</Tooltip>
              ) : (
                item.label
              )}
            </Option>
          ))
          .filter(i => profile.includes(i.key))}
    </Select>
  );
  if (addonBefore && Array.isArray(addonBefore)) addonBefore = AddonPrefix;

  return (
    // <Tooltip title={`${translateX('reference_value')}: ${financialService.formatMoney(mainValue, 2, 'brl', true)}`}>
    <FormItem {...formItemProps}>
      <InputNumber
        ref={elRefs[name]}
        addonAfter={interestConversion}
        onChange={onInterestChange}
        className="addon-with-conversion"
        addonBefore={addonBefore}
        style={style}
        autoFocus={autoFocus}
        disabled={disabled}
        decimalSeparator={decimalSeparator}
        min={min}
        max={max}
      />
    </FormItem>
    // </Tooltip>
  );

  // internal functions --------------------------------------------------------

  function $buildAddonAfterSample() {
    return [
      {
        value: 'monthly',
        text: translateX('monthly'),
        label: translateX('monthly'),
        tooltip: translateX('monthly_payment'),
        disabled: false,
      },
      {
        value: 'yearly',
        text: translateX('yearly'),
        label: translateX('yearly'),
        tooltip: translateX('yearly_payment'),
        disabled: false,
      },
    ];
  }
};

export const JarvislyTitle = props => {
  const { title, className = 'mb-2', centered = false } = props;

  return (
    <Row className={className}>
      <h5 className={`w-100 text-muted ${centered ? 'text-center' : ''}`}>
        {translateX(title || 'n_d')}
      </h5>
    </Row>
  );
};

export const JarvislyGoogleMap = props => {
  // providers context ---------------------------------------------------------
  const { addressState } = useContext(JarvislyAddressContext);

  // props deconstruction ------------------------------------------------------
  const {
    lat,
    lng,
    zoom = 16,
    googleApiKey = GOOGLE_API_KEY,
    height = 200,
    width = '100%',
  } = props;

  // local variables I ---------------------------------------------------------
  const coordinates = addressState?.addressCoordinates;

  const mapDefaultProps = {
    center: {
      lat: lat || -22.9110238,
      lng: lng || -47.09600229999999,
    },
    zoom: zoom,
  };

  const mapProps = {
    center: {
      lat: coordinates?.[0] || mapDefaultProps.center.lat,
      lng: coordinates?.[1] || mapDefaultProps.center.lng,
    },
    zoom: zoom,
  };

  return (
    // Important! Always set the container height explicitly, otherwise, the maps is not show
    <div style={{ marginBottom: 24, height, width }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleApiKey }}
        defaultCenter={mapDefaultProps.center}
        defaultZoom={mapDefaultProps.zoom}
        center={mapProps.center}
      />

      {/*
      <AnyReactComponent
        lat={props?.lat}
        lng={props?.lng}
        // text="720, Engenheiro Antonio Carvalho de Miranda, Campinas, SP"
        text={<img src={imgMarker} alt="Local"/>}
      />
      */}
      {/*</GoogleMapReact>*/}
    </div>
  );
};

export const JarvislyEditViewRemoveItem = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { onEdit, record, onDelete, isDisabled, deleteTitle } = props;

  // local variables I ---------------------------------------------------------
  const popconfirmTitle =
    deleteTitle || translateX('remove_this_item', ['%ITEM%'], ['item']);

  // methods -------------------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      <Tooltip title={translateX(!isDisabled ? 'edit' : 'view')}>
        <Button
          size="small"
          className="m-2"
          icon={!isDisabled ? <EditOutlined /> : <EyeOutlined />}
          onClick={() => onEdit(record)}
        />
      </Tooltip>

      {!isDisabled ? (
        <Popconfirm
          title={popconfirmTitle}
          cancelButtonProps={{ className: 'fixed-with-90' }}
          okButtonProps={{ className: 'fixed-with-90' }}
          onConfirm={() => onDelete(record)}
        >
          <Button size="small" icon={<DeleteOutlined />} />
        </Popconfirm>
      ) : null}
    </>
  );
};

export const JarvislyAsyncAutoComplete = props => {
  // providers context ---------------------------------------------------------
  const { tab } = useContext(JarvislyTabContext);
  const {
    elRefs,
    initFocus,
    formCheck,
    addComponentToTab,
    isFormDisabled,
    document,
    ready,
  } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  let {
    id = 'asyncSelect',
    invalidErrorLabel = 'invalid_data',
    componentName = 'JarvislyAsyncAutoComplete',
    name = id,
    autoFocus = name === initFocus,
    required,
    context,
    disabled = isFormDisabled,
    onChange,

    'x-min-char-to-search': minCharToSearch = 3,
    'x-on-select': onSelect,

    // items for mockOnChange...
    'x-folder': xFolder = 'documents',
    'x-field-id': xFieldId = '_id',
    'x-field-name': xFieldName = 'name',
    'x-field-full-name': xFieldFullName = 'fullName',
    'x-data-type': xDataType,
    'x-data-profile': xDataProfile,
  } = props;

  // local variables I ---------------------------------------------------------
  name = buildName(context, name);
  required = required || formCheck('is_required_field', name);
  disabled = disabled || formCheck('is_disabled_field', name);
  elRefs[name] = useRef(null);

  // component states ----------------------------------------------------------
  const [feedBackStatus, setFeedBackStatus] = useState('');
  const [lastSearch, setLastSearch] = useState(null);
  const [isFound, setIsFound] = useState(false);

  // local variables II --------------------------------------------------------
  const formItemProps = {
    ...props,
    id,
    componentName,
    invalidErrorLabel,
    name,
    required,

    hasFeedback: false,
    validationFn: null,
    feedBackStatus,
    setFeedBackStatus,
  };

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (tab) addComponentToTab(tab, name);
  }, [tab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ready) {
      if (document?.[name]) {
        setLastSearch(document[name]);
        setIsFound(true);
      } else {
        setLastSearch(null);
        setIsFound(false);
      }
    }
  }, [document, ready]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onSelectLocal = value => {
    onSelect &&
      onSelect({
        key: value?.key, // _id
        label: value?.label, // name
        value: value?.value, // name
      });
  };

  const mockOnChange = async searchValue => {
    // abort the searching according the following condition
    if (searchValue.length < minCharToSearch) {
      setLastSearch(null);
      setIsFound(false);
      return null;
    }

    if (!isFound && searchValue.includes(lastSearch)) return null;

    // const data = await getEntitiesByField('name', searchValue, 'documents', 'company', 'operator');
    const options = {
      folder: xFolder,
      dataType: xDataType,
      dataProfile: xDataProfile,
      value: searchValue,
    };
    const [docs] = await entityService.getEntitiesByPowerSearch(options);

    setLastSearch(searchValue);
    setIsFound(!!docs);

    return docs?.map(i =>
      entityService.buildSelectedEntity(
        i,
        xFieldId,
        xFieldName,
        xFieldFullName,
      ),
    );
  };

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <FormItem {...formItemProps}>
      <AsyncAutoComplete
        {...props}
        style={{ width: '100%' }}
        // ref={elRefs[name]}
        disabled={disabled}
        x-inner-ref={elRefs[name]}
        x-on-search={onChange || mockOnChange}
        x-on-select={onSelectLocal}
        autoFocus={autoFocus}
      />

      {/*<AutoComplete ref={elRefs[name]} labelInValue allowClear backfill />*/}
    </FormItem>
  );
};

export const JarvislyRequiredCard = props => {
  const {
    children,
    errorsArr,
    value,
    title,
    errorMessage = 'required',
  } = props;

  return (
    <Card
      title={
        <div className="pb-3">
          {title ? (
            <div className="text-muted">
              <span style={{ color: 'red', marginRight: 6, fontSize: 12 }}>
                *
              </span>
              {translateX(title)}
            </div>
          ) : null}

          {errorsArr?.includes(value) ? (
            <div
              style={{
                color: '#ff6b72',
                padding: '2px 0 2px 0',
                overflow: 'hidden',
                position: 'absolute',
                fontSize: 12,
                lineHeight: 1.57,
                fontWeight: 400,
              }}
            >
              {translateX(errorMessage)}
            </div>
          ) : null}
        </div>
      }
    >
      {children}
    </Card>
  );
};

export const JarvislyPreviousNext = props => {
  let {
    previousDisabled,
    onPrevious,

    nextDisabled,
    onNext,

    step,
    totalSteps = 1,
  } = props;

  totalSteps = totalSteps - 1;

  const [previous, setPrevious] = useState({
    title: 'cancel',
    type: 'default',
    width: 120,
    icon: null,
    disabled: false,
  });

  const [next, setNext] = useState({
    title: 'next',
    type: 'primary',
    width: 120,
    icon: <RightOutlined />,
    disabled: true,
  });

  useEffect(() => {
    if (step > 0) {
      setPrevious(prev => ({
        ...prev,
        title: 'previous',
        type: 'primary',
        icon: <LeftOutlined />,
      }));
    } else {
      setPrevious(prev => ({
        ...prev,
        title: 'cancel',
        type: 'default',
        icon: null,
      }));
    }

    if (step === totalSteps) {
      setNext(prev => ({
        ...prev,
        title: 'confirm',
        icon: <CheckOutlined />,
      }));
    } else {
      setNext(prev => ({
        ...prev,
        title: 'next',
        icon: <RightOutlined />,
      }));
    }
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex justify={appService.isMobile() ? 'center' : 'end'}>
      <Button
        key={previous.key || 'previous'}
        style={{ width: previous.width || 'unset' }}
        type={previous.type || 'default'}
        disabled={previousDisabled}
        onClick={onPrevious}
        icon={previous.icon || null}
      >
        {translateX(previous.title || 'cancel')}
      </Button>

      <Button
        className="ml-2"
        key={next.key || 'next'}
        style={{ width: next.width || 'unset' }}
        type={next.type || 'primary'}
        disabled={nextDisabled}
        onClick={onNext}
        icon={next.icon || null}
        danger={step === totalSteps}
      >
        {translateX(next.title || 'ok')}
      </Button>
    </Flex>
  );
};

export const JarvislyRadio = props => {
  const {
    onChange,
    value,
    direction = 'vertical',
    options = [
      { value: 1, text: 'option_1' },
      { value: 2, text: 'option_2' },
    ],
  } = props;

  return (
    <Radio.Group onChange={onChange} value={value}>
      <Space direction={direction} size="middle">
        {options?.map(o => (
          <Radio key={o.value} value={o.value} disabled={o.disabled}>
            {translateX(o.text || 'n/a')}
          </Radio>
        ))}

        {/*<Radio value="add">{translateX('add_existing_account')}</Radio>*/}
        {/*<Radio value="open">{translateX('open_new_digital_account')}</Radio>*/}
      </Space>
    </Radio.Group>
  );
};

export const SearchEntity = ({
  'x-on-search-before': onSearchBefore,
  ...props
}) => {
  let {
    'x-min-char-to-search': minCharToSearch = 3,
    'x-on-select': onSelect,

    // items for mockOnChange...
    'x-folder': xFolder = 'documents',
    'x-field-id': xFieldId = '_id',
    'x-field-name': xFieldName = 'name',
    'x-field-full-name': xFieldFullName = 'fullName',
    'x-data-type': xDataType,
    'x-data-profile': xDataProfile,
  } = props;

  const [lastSearch, setLastSearch] = useState(null);
  const [isFound, setIsFound] = useState(false);

  const onChangeLocal = async searchValue => {
    if (onSearchBefore) onSearchBefore(searchValue);

    // abort the searching according the following condition
    if (searchValue.length < minCharToSearch) {
      setLastSearch(null);
      setIsFound(false);
      return null;
    }

    if (!isFound && searchValue.includes(lastSearch)) return null;

    // const data = await getEntitiesByField('name', searchValue, 'documents', 'company', 'operator');
    const options = {
      folder: xFolder,
      dataType: xDataType,
      dataProfile: xDataProfile,
      value: searchValue,
    };
    const [docs] = await entityService.getEntitiesByPowerSearch(options);

    setLastSearch(searchValue);
    setIsFound(!!docs);

    return docs?.map(i =>
      entityService.buildSelectedEntity(
        i,
        xFieldId,
        xFieldName,
        xFieldFullName,
      ),
    );
  };

  const onSelectLocal = value => {
    onSelect &&
      onSelect({
        key: value?.key, // _id
        label: value?.label, // name
        value: value?.value, // name
      });
  };

  return (
    <AsyncAutoComplete
      {...props}
      style={{ width: '100%' }}
      // ref={elRefs[name]}
      // disabled={disabled}
      // x-inner-ref={elRefs[name]}
      x-on-search={onChangeLocal}
      x-on-select={onSelectLocal}
      // autoFocus={autoFocus}
    />
  );
};

// -----------------------------------------------------------------------
// OLD JARVISLY FORM COMPONENTS ------------------------------------------

export const FormPlaca = props => {
  const t = {
    isRequired: translateX('required'),
    isInvalid: translateX('invalid_plate'),
  };

  const {
    name = 'plate',
    label = buildLabel(props, 'plate'),
    elRef,
    rules = [
      {
        required: !!props?.required,
        message: t.isRequired,
      },
    ],
    disabled,
    form,

    onValidating,
    onSearched,
  } = props;

  const [value, setValue] = useState(props?.value);
  const [validating, setValidating] = useState(false);
  const [validatorState, setValidatorState] = useState(null);
  const isRequired = rules?.findIndex(x => x.required) > -1;

  if (rules.findIndex(x => x.validator) === -1)
    rules.push({ validator: $validator });

  const onSearchPlaca = async (ev, plate) => {
    setValue(plate);
    if (!isValidPlate(plate)) return;
    const result = await fetchPlate();
    onSearched && onSearched(result);
  };

  const fetchPlate = () => {
    setValidating(true);
    onValidating(true);

    return new Promise(r => {
      setTimeout(() => {
        setValidating(false);
        onValidating(false);
        elRef?.current?.focus({ cursor: 'all' });
        r();
      }, 1000);
    });
  };

  return (
    <Form.Item
      name={name}
      label={translateX(label)}
      value={value}
      hasFeedback
      validateStatus={validatorState?.status}
      help={validatorState?.message}
      rules={rules}
    >
      <InputPlate
        ref={elRef}
        onChange={onSearchPlaca}
        disabled={disabled || validating}
      />
    </Form.Item>
  );

  function $validator() {
    if ($handleValidator() === 'error') {
      return Promise.reject();
    } else {
      return Promise.resolve();
    }
  }

  function $handleValidator() {
    let message = '',
      status = '';
    const v =
      form?.getFieldValue(name) !== 'undefined' && form?.getFieldValue(name);

    if (!v && !form?.isFieldsTouched()) {
      return setValidatorState({
        status,
        message,
      });
    } else if (isRequired && !v) {
      message = t.isRequired;
      status = 'error';
    } else if (v && !isValidPlate(v)) {
      message = t.isInvalid;
      status = 'error';
    } else {
      status = v ? 'success' : '';
    }

    setValidatorState({
      status,
      message,
    });
    return status;
  }
};

export const FormAsyncAutoCompleteEntity = props => {
  const {
    name = 'selectedEntity',
    label = buildLabel(props),
    elRefs = {},
    rules = [
      {
        required: !!props?.required,
        message: '', // t.isRequired,
      },
    ],
    disabled,
    onChange,
    value,
    autoFocus = false,

    maxLength,
    placeholder,
    onSelect,
    style,

    folder = 'documents',
    dataType,
    dataProfile,
  } = props;

  const [isSearching, setIsSearching] = useState(false);
  const [isFound, setIsFound] = useState(false);
  const [lastSearch, setLastSearch] = useState(props?.value?.name || null);

  elRefs[name] = useRef(null);
  // const isRequired = rules?.findIndex(x => x.required) > -1;

  useEffect(() => {
    if (!value) return;
    setIsFound(true);
    setLastSearch(value.name);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  /*  if (!!isRequired) {
    rules.push({
      validator: () => {
        if (!isFound && !acceptNew) {
          return Promise.reject(t.isRequired);
        }
        return Promise.resolve();
      },
    });
  }*/

  const onSelectEntity = value => {
    const entity = {
      _id: value?.key,
      name: value?.label,
    };

    onSelect && onSelect(entity);
  };

  return (
    <Form.Item
      name={name}
      style={style}
      label={typeof label === 'string' ? translateX(label) : label}
      rules={rules}
    >
      <AsyncAutoComplete
        innerref={elRefs[name]}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        disabled={disabled}
        autoFocus={autoFocus}
        placeholder={placeholder}
        searchFn={handleEntitySearch}
        onSelect={onSelect && onSelectEntity}
        style={{ width: '100%' }}
      />
    </Form.Item>
  );

  async function handleEntitySearch(searchValue) {
    // abort the searching according the following condition
    if (isSearching) return;
    if (searchValue.length < 3) {
      setLastSearch(null);
      setIsFound(false);
      return null;
    }

    if (!isFound && searchValue.includes(lastSearch)) return null;

    setIsSearching(true);

    // const data = await getEntitiesByField('name', searchValue, 'documents', 'company', 'operator');
    const options = {
      folder,
      dataType,
      dataProfile,
      field: 'name',
      value: searchValue,
    };
    const [docs] = await entityService.getEntitiesByField(options);

    setLastSearch(searchValue);
    setIsSearching(false);
    setIsFound(!!docs);

    // const data = await fetchUsers(searchValue);
    return docs?.map(i => ({
      label: i.name, // title: i.name,
      value: i.name,
      key: i?._id,
      item: i,
    }));
  }
};

export const FormWrapper = props => {
  const {
    name = 'jarvisly-form',
    layout = 'vertical',
    validateTrigger = 'onChange',
    className = '',
    form,
    initialValues,
    onConfirm,
    onBeforeSave,
    onAfterSave,
    onError,
    onValuesChange,
    elRefs,
    idField = '_id',
    urlApi,
    style = {},
    preserve = true,
  } = props;

  const onFinishFailed = errorInfo => onFormFinishFailed(errorInfo, elRefs);

  // return 'false' to abort the method and not save for here!
  const onFinish = async values => {
    // if onConfirm exists, ignore onSave and execute only onConfirm (for singles form)
    if (onConfirm) return onConfirm(values);

    let body = onBeforeSave ? await onBeforeSave(values) : values;
    if (!body) return;

    appService.message('i', 'saving_document', 'onSave');

    const _id = body?.[idField];
    delete body?._id;

    try {
      const [doc, result] = _id
        ? await axiosService.put({
            _id,
            url: urlApi,
            data: body,
          })
        : await axiosService.post({
            url: urlApi,
            data: body,
          });

      if (!result) appService.messageDestroy('onSave');

      // const data = result?.data;

      appService.message(
        's',
        _id ? 'document_updated_with_success' : 'document_saved_with_success',
        'onSave',
      );
      onAfterSave && (await onAfterSave(doc));
    } catch (e) {
      appService.message('e', 'error_saving_document', 'onSave');
      appService.console(
        'e',
        `Erro ${e?.data?.code}: ${e?.data?.errorMessage}`,
      );

      onError && onError(e);
    }
  };

  return (
    <Form
      name={name}
      noValidate
      style={{ width: '100%', ...style }}
      form={form}
      layout={layout}
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
      validateTrigger={validateTrigger}
      className={`${className} icon-with-margin`}
      preserve={preserve}
      autoComplete="off"
    >
      {props.children}
    </Form>
  );
};

export const FormInput = props => {
  const {
    name,
    noLabel,
    value,
    label = buildLabel(props),
    elRefs = {},
    rules = [
      {
        required: !!props?.required,
        message: '', // t.isRequired,
      },
    ],
    disabled,
    minLength,
    maxLength,
    autoFocus = false,
    placeholder,
    onChange,
    onBlur,
    style,
    prefix,
    suffix,
    hasFeedback,
    validateStatus,
    help,
  } = props;

  elRefs[name] = useRef(null);

  return (
    <Form.Item
      name={name}
      style={style}
      label={
        !noLabel
          ? typeof label === 'string'
            ? translateX(label)
            : label
          : null
      }
      hasFeedback={hasFeedback}
      validateStatus={validateStatus}
      autoComplete="off"
      help={help}
      rules={rules}
    >
      <Input
        ref={elRefs[name]}
        prefix={prefix}
        suffix={suffix}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        minLength={minLength}
        maxLength={maxLength}
        disabled={disabled}
        autoFocus={autoFocus}
      />
    </Form.Item>
  );
};

export const FormPhone = props => {
  const t = {
    isRequired: translateWord('required'),
    isInvalid: translateWord('invalid_phone_number'),
  };

  const {
    name = 'phone',
    label = buildLabel(props, 'phone'),
    elRefs = {},
    rules = [
      {
        required: !!props?.required,
        message: '', // t.isRequired,
      },
    ],
    autoFocus = false,
    disabled,
    value,
    form,
    profile,
    onChange,
    onBlur,
    prefix,
    suffix,
    allowClear,
    style,
  } = props;

  const [, setFeedBackStatus] = useState('');
  const [, setFeedBackMessage] = useState('');

  elRefs[name] = useRef(null);
  const isRequired = rules?.findIndex(x => x.required) > -1;

  const feedbackOptions = {
    name,
    form,
    isRequired,
    setFeedBackStatus,
    setFeedBackMessage,
    onChange,
    t,
    fn: isValidPhoneNumber,
    profile,
  };

  useEffect(() => {
    (async () => await onChangeValidationFeedback(null, feedbackOptions))();

    return () => {
      setFeedBackStatus('');
      setFeedBackMessage('');
    };
  }, [form, form?.getFieldsValue([name])]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form.Item
      name={name}
      style={style}
      label={typeof label === 'string' ? translateX(label) : label}
      value={value}
      hasFeedback
      // validateStatus={feedBackStatus}
      // help={feedBackMessage}
      rules={rules}
    >
      <InputPhoneOld
        ref={elRefs[name]}
        profile={profile}
        onBlur={onBlur}
        autoFocus={autoFocus}
        prefix={prefix}
        suffix={suffix}
        allowClear={allowClear}
        onChange={ev => onChangeValidationFeedback(ev, feedbackOptions)}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export const FormEmail = props => {
  const t = {
    isRequired: translateWord('required'),
    isInvalid: translateWord('invalid_email'),
  };

  const {
    name = props?.name || 'email',
    rules = [
      {
        required: !!props?.required,
        message: '', // t.isRequired,
      },
    ],
    form,
    onChange,
  } = props;

  const [, setFeedBackStatus] = useState('');
  const [, setFeedBackMessage] = useState('');

  const isRequired = rules?.findIndex(x => x.required) > -1;

  const feedbackOptions = {
    name,
    form,
    isRequired,
    setFeedBackStatus,
    setFeedBackMessage,
    onChange,
    t,
    fn: isValidEmail,
  };

  useEffect(() => {
    let ready = false;

    (async () => {
      await onChangeValidationFeedback(null, feedbackOptions);
      ready = true;
    })();

    return () => {
      if (!ready) return;
      setFeedBackStatus && setFeedBackStatus('');
      setFeedBackMessage && setFeedBackMessage('');
    };
    // }, [form, form?.getFieldsValue([name])]); // eslint-disable-line react-hooks/exhaustive-deps
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    // label, disable, elRefs, etc. already includes in 'props'
    <FormInput
      {...props}
      name={name}
      hasFeedback
      // validateStatus={feedBackStatus}
      // help={feedBackMessage}
      rules={rules}
      onChange={ev => onChangeValidationFeedback(ev, feedbackOptions)}
    />
  );
};

export const FormWebsite = props => {
  const t = {
    isRequired: translateWord('required'),
    isInvalid: translateWord('invalid_website'),
  };

  const {
    name = props?.name || 'website',
    rules = [
      {
        required: !!props?.required,
        message: '', // t.isRequired,
      },
    ],
    form,
    onChange,
  } = props;

  const [feedBackStatus, setFeedBackStatus] = useState('');
  const [feedBackMessage, setFeedBackMessage] = useState('');

  const isRequired = rules?.findIndex(x => x.required) > -1;

  const feedbackOptions = {
    name,
    form,
    isRequired,
    setFeedBackStatus,
    setFeedBackMessage,
    onChange,
    t,
    fn: isValidUrl,
  };

  useEffect(() => {
    (async () => await onChangeValidationFeedback(null, feedbackOptions))();

    return () => {
      setFeedBackStatus('');
      setFeedBackMessage('');
    };
  }, [form, form?.getFieldsValue([name])]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    // label, disable, elRefs, etc. already includes in 'props'
    <FormInput
      {...props}
      name={name}
      hasFeedback={true}
      validateStatus={feedBackStatus}
      help={feedBackMessage}
      rules={rules}
      // onChange={ev => onChangeValidationFeedback(ev, feedbackOptions)}
      onChange={ev => validateField(ev, feedbackOptions)}
    />
  );
};

export const FormPassword = props => {
  const {
    name = 'password',
    value,
    label = buildLabel(props),
    elRefs = {},
    rules = [
      {
        required: !!props?.required,
        message: '', // t.isRequired,
      },
    ],
    disabled,
    maxLength,
    autoFocus = false,
    placeholder,
    onChange,
    style,
    prefix,
    suffix,
    hasFeedback,
    validateStatus,
    help,
  } = props;

  elRefs[name] = useRef(null);

  return (
    <Form.Item
      name={name}
      style={style}
      label={typeof label === 'string' ? translateX(label) : label}
      hasFeedback={hasFeedback}
      validateStatus={validateStatus}
      help={help}
      rules={rules}
    >
      <Input.Password
        ref={elRefs[name]}
        prefix={prefix}
        suffix={suffix}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength}
        disabled={disabled}
        autoFocus={autoFocus}
      />
    </Form.Item>
  );
};

export const FormCpf = props => {
  const {
    name = 'cpf',
    label = buildLabel(props, 'person_cpf'),
    elRefs = {},
    rules = [
      {
        required: !!props?.required,
        message: '', // t.isRequired,
      },
    ],
    autoFocus = false,
    disabled,
    value,
    form,
    onChange,
    prefix,
    suffix,
    allowClear,
    hasFeedback = true,
  } = props;

  const [feedBackStatus, setFeedBackStatus] = useState('');

  elRefs[name] = useRef(null);
  const isRequired = rules?.findIndex(x => x.required) > -1;

  if (rules.findIndex(x => x.validator) === -1 && isRequired)
    rules.push({
      validator: async function () {
        const options = {
          form,
          name,
          fn: isValidCpf,
          setFeedBackStatus,
          onChange,
        };

        setFeedBackStatus('validating');
        if (!(await validateField(null, options))) {
          setFeedBackStatus('error');
          return Promise.reject();
        } else {
          // if (feedBackStatus !== 'validating') setFeedBackStatus('success');
          setFeedBackStatus('success');
          return Promise.resolve();
        }
      },
    });

  useEffect(() => {
    const options = {
      form,
      name,
      fn: isValidCpf,
      setFeedBackStatus,
    };

    if (form.getFieldValue('_id') && validateField(null, options)) {
      setFeedBackStatus('success');
    }

    return () => {
      setFeedBackStatus('');
    };

    // (async () => await onChangeValidationFeedback(null, feedbackOptions))();
    //
    // return () => {
    //   setFeedBackStatus('');
    //   setFeedBackMessage('');
    // };
    // }, [form, form?.getFieldsValue([name])]); // eslint-disable-line react-hooks/exhaustive-deps
  }, [form?.getFieldValue('_id')]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form.Item
      name={name}
      label={typeof label === 'string' ? translateX(label) : label}
      hasFeedback={hasFeedback}
      validateStatus={feedBackStatus}
      // help={feedBackMessage}
      value={value}
      rules={rules}
    >
      <InputCpfCnpj
        ref={elRefs[name]}
        prefix={prefix}
        suffix={suffix}
        autoFocus={autoFocus}
        profile="cpf"
        allowClear={allowClear}
        // onChange={onChange}
        // onChange={ev => onChangeValidationFeedback(ev, feedbackOptions)}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export const FormCnpj = props => {
  const t = {
    isRequired: translateWord('required'),
    isInvalid: translateWord('invalid_cnpj'),
  };

  const {
    name = 'cnpj',
    label = buildLabel(props, 'company_cnpj'),
    elRefs = {},
    rules = [
      {
        required: !!props?.required,
        message: '', // t.isRequired,
      },
    ],
    autoFocus = false,
    disabled,
    value,
    form,
    onChange,
    prefix,
    suffix,
    allowClear,
  } = props;

  const [feedBackStatus, setFeedBackStatus] = useState('');
  const [feedBackMessage, setFeedBackMessage] = useState('');

  elRefs[name] = useRef(null);
  const isRequired = rules?.findIndex(x => x.required) > -1;

  const feedbackOptions = {
    name,
    form,
    isRequired,
    setFeedBackStatus,
    setFeedBackMessage,
    onChange,
    t,
    fn: isValidCnpj,
  };

  if (rules.findIndex(x => x.validator) === -1)
    rules.push({ validator: validatorRules.bind(null, feedbackOptions) });

  useEffect(() => {
    (async () => await onChangeValidationFeedback(null, feedbackOptions))();

    return () => {
      setFeedBackStatus('');
      setFeedBackMessage('');
    };
  }, [form, form?.getFieldsValue([name])]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form.Item
      name={name}
      label={typeof label === 'string' ? translateX(label) : label}
      hasFeedback
      validateStatus={feedBackStatus}
      help={feedBackMessage}
      value={value}
      rules={rules}
    >
      <InputCpfCnpj
        ref={elRefs[name]}
        prefix={prefix}
        suffix={suffix}
        autoFocus={autoFocus}
        profile="cnpj"
        allowClear={allowClear}
        onChange={ev => onChangeValidationFeedback(ev, feedbackOptions)}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export const FormZip = props => {
  const t = {
    isRequired: translateWord('required'),
    isInvalid: translateWord('invalid_zip'),
    isNotFound: translateWord('zip_not_found'),
  };

  const {
    name = 'zip',
    label = buildLabel(props, 'zip'),
    elRefs = {},
    rules = [
      {
        required: !!props?.required,
        message: '', // t.isRequired,
      },
    ],
    disabled,
    value,
    form,
    autoFocus = false,
    numberFieldName = 'number',
    afterSearch,
  } = props;

  const [feedBackStatus, setFeedBackStatus] = useState('');
  // const [feedBackMessage, setFeedBackMessage] = useState('');
  const [lastZip, setLastZip] = useState({});

  elRefs[name] = useRef(null);
  // const isRequired = rules?.findIndex(x => x.required) > -1;

  if (rules.findIndex(x => x.validator) === -1)
    rules.push({
      validator: async function () {
        const options = {
          form,
          name,
          fn: isValidZip,
          setFeedBackStatus,
        };
        if (!validateField(null, options)) {
          return Promise.reject();
        } else {
          if (form.getFieldValue('_id') && feedBackStatus !== 'validating') {
            setFeedBackStatus('success');
          }
          return Promise.resolve();
        }
      },
    });

  useEffect(() => {
    const options = {
      form,
      name,
      fn: isValidZip,
      setFeedBackStatus,
    };

    if (form.getFieldValue('_id') && validateField(null, options)) {
      setFeedBackStatus('success');
    }

    return () => {
      setFeedBackStatus('');
    };
  }, [form?.getFieldValue('_id')]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form.Item
      name={name}
      label={typeof label === 'string' ? translateX(label) : label}
      value={value}
      hasFeedback
      validateStatus={feedBackStatus}
      // help={feedBackMessage}
      rules={rules}
    >
      <InputCep
        ref={elRefs[name]}
        autoFocus={autoFocus}
        minLength={9}
        maxLength={9}
        onChange={consumeZipCode}
        disabled={disabled || feedBackStatus === 'validating'}
      />
    </Form.Item>
  );

  async function consumeZipCode(event, addressFromApi) {
    let cep =
      event?.target?.value ||
      (form?.getFieldValue(name) !== 'undefined' && form?.getFieldValue(name));

    // have a result from cep api
    if (addressFromApi) {
      if (addressFromApi?.erro) {
        // property 'erro' from viacep api error
        setFeedBackStatus('error');
        // setFeedBackMessage(t.isNotFound);
        setLastZip({
          ...lastZip,
          doc: null,
          zip: cep,
          feedBackStatus: 'error',
          feedBackMessage: t.isNotFound,
        });
        setTimeout(() => elRefs[name].current.focus({ cursor: 'all' }), 50);
        return;
      } else {
        setFeedBackStatus('success');
        // setFeedBackMessage('');
        setLastZip({
          ...lastZip,
          doc: addressFromApi,
          zip: cep,
          feedBackStatus: 'success',
          feedBackMessage: '',
        });
        $decorateAddress(addressFromApi).then();
        if (elRefs?.[numberFieldName]?.current?.focus) {
          // next field (number) focus
          setTimeout(
            () => elRefs[numberFieldName].current.focus({ cursor: 'all' }),
            50,
          );
        }
        return;
      }
    }

    if (typeof cep === 'string' && cep?.length !== 9) {
      afterSearch && afterSearch();
    } else if (typeof cep === 'string' && cep?.length === 9) {
      // consumir api

      setFeedBackStatus('validating');
      // setFeedBackMessage('');

      if (cep === lastZip?.zip) {
        $decorateAddress(lastZip?.doc).then();
        setFeedBackStatus(lastZip.feedBackStatus);
        // setFeedBackMessage(lastZip.feedBackMessage);
      }
    }

    async function $decorateAddress(addressFromApi) {
      if (!addressFromApi) return afterSearch && afterSearch();

      // decorate address
      const zip = returnOnlyNumbers(addressFromApi?.cep);
      const number = form?.getFieldValue(numberFieldName)
        ? form.getFieldValue(numberFieldName)
        : 0;
      const address = `${number} ${addressFromApi?.logradouro}, ${addressFromApi?.localidade}, ${addressFromApi?.uf}`;
      const addressCoordinates = await appService.getGpsByAddress(address);

      // const addressCoordinates =
      //   number && addressFromApi?.logradouro
      //     ? await appService.getGpsByAddress(address)
      //     : null;

      const decoratedAddress = {
        addressTitle: translateWord('main_address'),
        zip: zip, // '13034673'
        address1: addressFromApi?.logradouro, // 'Avenida Antônio Carvalho de Miranda'
        address2: null, // additional/complemento
        number: '', // house number
        neighborhood: addressFromApi?.bairro, // 'Vila São Bento'
        city: addressFromApi?.localidade, // 'Campinas'
        __city: `${addressFromApi?.localidade} - ${addressFromApi?.uf}`, // 'Campinas - SP'
        province: addressFromApi?.uf, // 'SP'
        addressReference: '', // reference point
        addressCoordinates: addressCoordinates,

        addressAdditionalInfo: {
          note: addressFromApi?.complemento, // from api   -> 'de 460/461 ao fim'
          areaCode: addressFromApi?.ddd, // '19'
          giaCode: addressFromApi?.gia, // '2446'     -> Guia de Informação e Apuração do ICMS - GIA
          ibgeCode: addressFromApi?.ibge, // '3509502'  -> Instituto Brasileiro de Geografia e Estatística
          siafiCode: addressFromApi?.siafi, // '6291',    -> Sistema Integrado de Administração Financeira
        },

        addressMetadata: {
          countryCode: 'br',
          foundByApi: true,
          apiUrl: `https://viacep.com.br/ws/${zip}/json/`,
          zipWithMultipleAddress: !!!addressFromApi?.logradouro,
        },
      };

      return afterSearch ? afterSearch(decoratedAddress) : decoratedAddress;
    }
  }
};

export const FormDatePicker = props => {
  const {
    name,
    label = buildLabel(props),
    elRefs = {},
    rules = [
      {
        required: !!props?.required,
        message: '', // t.isRequired,
      },
    ],
    disabled,
    value,
    format = getDateFormatByLocale(),
    placeholder,
    onChange,
    tooltip,
  } = props;

  elRefs[name] = useRef(null);

  return (
    <Form.Item
      name={name}
      label={typeof label === 'string' ? translateX(label) : label}
      value={value}
      tooltip={tooltip}
      rules={rules}
    >
      <DatePicker
        className="w-100 no-border-radius"
        format={format}
        disabled={disabled}
        placeholder={placeholder}
        allowClear
        onChange={onChange}
        ref={elRefs[name]}
      />
    </Form.Item>
  );
};

export const FormDateRangerPicker = props => {
  const {
    name,
    label = buildLabel(props),
    elRefs = {},
    rules = [
      {
        required: !!props?.required,
        message: '', // t.isRequired,
      },
    ],
    disabled,
    value,
    format = getDateFormatByLocale(),
    placeholder,
    onChange,
    tooltip,
    disabledDate,
  } = props;

  elRefs[name] = useRef(null);

  return (
    <Form.Item
      name={name}
      label={typeof label === 'string' ? translateX(label) : label}
      value={value}
      tooltip={tooltip}
      rules={rules}
    >
      <RangePicker
        className="w-100 no-border-radius"
        format={format}
        disabled={disabled}
        placeholder={placeholder}
        allowClear
        disabledDate={disabledDate}
        onChange={onChange}
        ref={elRefs[name]}
        locale={'pt-br'}
      />
    </Form.Item>
  );
};

export const FormSelect = props => {
  const {
    name,
    label = buildLabel(props),
    elRefs = {},
    rules = [
      {
        required: !!props?.required,
        message: '', // t.isRequired,
      },
    ],
    disabled,
    onChange,
    onSearch,
    value,
    options = [],
    required,
    autoFocus = false,
  } = props;

  elRefs[name] = useRef(null);

  return (
    <Form.Item
      name={name}
      label={typeof label === 'string' ? translateX(label) : label}
      rules={rules}
    >
      <Select
        ref={elRefs[name]}
        options={options}
        value={value}
        showSearch
        allowClear
        filterOption={defaultFilterOption}
        onChange={onChange}
        onSearch={onSearch}
        disabled={disabled}
        required={required}
        autoFocus={autoFocus}
      />
    </Form.Item>
  );
};

export const FormSelectGender = props => {
  const t = {
    male: translateWord('male'),
    female: translateWord('female'),
    notInformed: translateWord('prefer_not_inform'),
    isRequired: translateWord('required'),
  };

  const {
    name = 'gender',
    rules = [
      {
        required: !!props?.required,
        message: '', // t.isRequired,
      },
    ],
    level = 1,
  } = props;

  const options = buildOptions(level);

  return (
    // label, disable, elRefs, etc. already includes in 'props'
    <FormSelect {...props} name={name} rules={rules} options={options} />
  );

  function buildOptions(genderLevel) {
    const levels = [
      {
        value: 'female',
        text: t.female,
        label: t.female,
        disabled: false,
        level: 1,
      },
      {
        value: 'male',
        text: t.male,
        label: t.male,
        disabled: false,
        level: 1,
      },
      {
        value: 'prefer_not_inform',
        text: t.notInformed,
        label: t.notInformed,
        disabled: false,
        level: 2,
      },
    ];

    switch (genderLevel) {
      case 1:
        return levels.filter(g => g.level === 1);

      case 2:
        return levels.filter(g => g.level <= 2);

      default:
        return levels.filter(g => g.level === 1);
    }
  }
};

export const FormTextArea = props => {
  const {
    name,
    value,
    label = buildLabel(props),
    elRefs = {},
    rules = [
      {
        required: !!props?.required,
        message: '', // t.isRequired,
      },
    ],
    disabled,
    minLength,
    maxLength,
    autoFocus = false,
    placeholder,
    onChange,
    onBlur,
    style,
    prefix,
    suffix,
    hasFeedback,
    validateStatus,
    help,
    rows = 4,
    className,
    classNameFormItem,
  } = props;

  elRefs[name] = useRef(null);

  return (
    <Form.Item
      name={name}
      style={style}
      label={typeof label === 'string' ? translateX(label) : label}
      hasFeedback={hasFeedback}
      validateStatus={validateStatus}
      autoComplete="off"
      help={help}
      rules={rules}
      className={classNameFormItem}
    >
      <TextArea
        ref={elRefs[name]}
        prefix={prefix}
        suffix={suffix}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        minLength={minLength}
        maxLength={maxLength}
        disabled={disabled}
        autoFocus={autoFocus}
        rows={rows}
        className={className}
      />
    </Form.Item>
  );
};

export const FormSimpleSwitch = props => {
  const t = {
    isRequired: translateX('required'),
    yes: translateX('yes'),
    no: translateX('no'),
  };

  const {
    name,
    noLabel,
    label = buildLabel(props),
    elRefs = {},
    rules = [
      {
        required: !!props?.required,
        message: '', // t.isRequired,
      },
    ],
    disabled,
    onChange,
    style,

    defaultChecked = false,
    showYesNoLabel = false,
    value = false,
    className,
  } = props;

  elRefs[name] = useRef(null);

  const checkedChildren = showYesNoLabel && t.yes;
  const unCheckedChildren = showYesNoLabel && t.no;

  const [isChecked, setIsChecked] = useState(defaultChecked);

  useEffect(() => {
    setIsChecked(value);
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const onLocalChange = checked => {
    const value = onChange ? onChange(checked) : checked;
    setIsChecked(value);
  };

  return (
    <Form.Item
      name={name}
      className={className}
      label={
        !noLabel
          ? typeof label === 'string'
            ? translateX(label)
            : label
          : null
      }
      rules={rules}
    >
      <Switch
        ref={elRefs[name]}
        style={style}
        defaultChecked={defaultChecked}
        checked={isChecked}
        checkedChildren={checkedChildren}
        unCheckedChildren={unCheckedChildren}
        onChange={onLocalChange}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export const FormNumber = props => {
  const {
    name,
    label = buildLabel(props),
    noLabel = false,
    elRefs = {},
    rules = [
      {
        required: !!props?.required,
        message: '', // t.isRequired,
      },
    ],
    disabled,
    maxLength,
    style = { width: '100%' },
    itemStyle = { width: '100%' },
    formatter,
    parser,
    min,
    max,
    value,
    defaultValue,

    decimalSeparator,
    required,

    suffixDefaultValue,
    suffixValue,
    suffixStyle,

    prefixDefaultValue,
    prefixStyle,

    onChange,
    onPrefixChange,
    onSuffixChange,

    field = {},
    placeholder = '',
  } = props;

  let { addonBefore, addonAfter } = props;

  elRefs[name] = useRef(null);

  // PREFIX
  const AddonPrefix = (
    <Select
      defaultValue={prefixDefaultValue}
      disabled={disabled}
      // options={options}
      style={prefixStyle}
      // value={value}
      // showSearch
      // allowClear
      filterOption={defaultFilterOption}
      onChange={onPrefixChange}
      // onSearch={onSearch}
      // disabled={disabled}
      required={required}
    >
      {Array.isArray(addonBefore) &&
        addonBefore.map(item => (
          <Option key={item.value} value={item.value}>
            {item?.tooltipText ? (
              <Tooltip title={item.tooltipText}>{item.label}</Tooltip>
            ) : (
              item.label
            )}
          </Option>
        ))}
    </Select>
  );

  if (addonBefore && Array.isArray(addonBefore)) addonBefore = AddonPrefix;

  // SUFFIX
  const AddonSuffix = (
    <Select
      defaultValue={suffixDefaultValue}
      disabled={disabled}
      // options={options}
      style={suffixStyle}
      value={suffixValue}
      // showSearch
      // allowClear
      filterOption={defaultFilterOption}
      onChange={onSuffixChange}
      // onSearch={onSearch}
      // disabled={disabled}
      required={required}
    >
      {Array.isArray(addonAfter) &&
        addonAfter.map(item => (
          <Option key={item.value} value={item.value}>
            {item?.tooltipText ? (
              <Tooltip title={item.tooltipText}>{item.label}</Tooltip>
            ) : (
              item.label
            )}
          </Option>
        ))}
    </Select>
  );

  if (addonAfter && Array.isArray(addonAfter)) addonAfter = AddonSuffix;

  return (
    <Form.Item
      {...field}
      name={name}
      style={style}
      // label={typeof label === 'string' ? translateX( label) : label}
      label={
        !noLabel
          ? typeof label === 'string'
            ? translateX(label)
            : label
          : null
      }
      rules={rules}
      required={required}
    >
      <InputNumber
        ref={elRefs[name]}
        value={value}
        defaultValue={defaultValue}
        style={itemStyle}
        formatter={formatter}
        min={min}
        max={max}
        placeholder={placeholder}
        parser={parser}
        addonBefore={addonBefore}
        addonAfter={addonAfter}
        onChange={onChange}
        maxLength={maxLength}
        decimalSeparator={decimalSeparator}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export const FormPrevNextButton = props => {
  const {
    showButtons = 'both',

    previousType = 'primary',
    previousIcon = <LeftOutlined />,
    previousTitle = 'previous',
    previousStyle = { width: 116 },
    previousDisabled,
    onPreviousClick,

    nextType = 'primary',
    nextIcon = <RightOutlined />,
    nextTitle = 'next',
    nextStyle = { width: 116 },
    nextDisabled,
    onNextClick,
  } = props;

  return (
    <>
      {/* PREVIOUS */}
      {['both', 'previous'].includes(showButtons) ? (
        <Button
          type={previousType}
          icon={previousIcon}
          onClick={onPreviousClick}
          disabled={previousDisabled}
          className="mr-3"
          style={previousStyle}
        >
          &nbsp;{translateWord(previousTitle)}
        </Button>
      ) : null}
      {/* PREVIOUS */}

      {/* NEXT */}
      {['both', 'next'].includes(showButtons) ? (
        <Button
          type={nextType}
          icon={nextIcon}
          onClick={onNextClick}
          disabled={nextDisabled}
          style={nextStyle}
        >
          &nbsp;{translateWord(nextTitle)}
        </Button>
      ) : null}
      {/* NEXT */}
    </>
    //
    //
    // <Button
    //   type={type}
    //   style={style}
    //   htmlType={!onClick ? 'submit' : 'button'}
    //   block
    //   icon={icon}
    //   className={className}
    //   // loading={loading}
    //   onClick={onClick}
    //   disabled={disabled || loading}
    // >
    //   <span>{translateX( title)}</span>
    //
    //   {/* {!loading */}
    //   {/*     ? <span>{translateX( title)}</span> */}
    //   {/*     : <span>&nbsp;{translateX( titleLoading)}...</span> */}
    //   {/* } */}
    // </Button>
  );
};

// ----

export const FormButton = props => {
  const {
    type = 'primary',
    title = 'save',
    icon, // titleLoading = props?.title || 'save',
    loading,
    disabled,
    className,
    onClick,
    style = { width: 116 },
  } = props;

  return (
    <Button
      type={type}
      style={style}
      htmlType={!onClick ? 'submit' : 'button'}
      block
      icon={icon}
      className={className}
      // loading={loading}
      onClick={onClick}
      disabled={disabled || loading}
    >
      <span>{translateX(title)}</span>

      {/* {!loading */}
      {/*     ? <span>{translateX( title)}</span> */}
      {/*     : <span>&nbsp;{translateX( titleLoading)}...</span> */}
      {/* } */}
    </Button>
  );
};

export const FormDropDownButton = props => {
  const { mode, isWorking, formWorking } = useContext(ModuleContext);

  const {
    type = 'primary',
    title,
    dropDownIcon = <MoreOutlined />,
    className,
    onClick,
    style = {},
    disabled,
    showButton = true,
    showNewOptionsButton,
    trigger = 'click',
    menu = {
      items: [
        {
          label: 'No Item',
          key: 'no_item',
          icon: <DislikeOutlined />,
        },
      ],
      onClick: () => appService.console('e', `No Item!`),
    },
  } = props;

  return showButton ? (
    <Dropdown.Button
      type={type}
      style={{ width: 'unset', ...style, justifyContent: 'end' }}
      // htmlType={!onClick ? 'submit' : 'button'}
      block
      icon={dropDownIcon}
      className={`  ${className}`}
      // loading={loading}
      disabled={disabled || isWorking || formWorking}
      menu={menu}
      onClick={onClick}
      trigger={[trigger]}
      buttonsRender={([leftButton, rightButton]) => [
        React.cloneElement(leftButton, {
          disabled: mode === 'view' || isWorking || formWorking,
        }),
        rightButton,
      ]}
    >
      <span>{translateX(title)}</span>
    </Dropdown.Button>
  ) : showNewOptionsButton ? (
    <Dropdown
      type={type}
      style={{ width: 'unset', ...style, justifyContent: 'end' }}
      // htmlType={!onClick ? 'submit' : 'button'}
      block
      icon={dropDownIcon}
      className={`  ${className}`}
      // loading={loading}
      disabled={disabled || isWorking || formWorking}
      menu={menu}
      // onClick={onClick}
      trigger={[trigger]}
    >
      <Button
        type={type}
        style={{
          padding: 10,
          width: showButton ? 'unset' : 40,
          justifyContent: 'end',
        }}
      >
        {title ? (
          <span>
            {dropDownIcon ? <>&nbsp;&nbsp;</> : ''}
            {translateX(title)}
          </span>
        ) : (
          dropDownIcon
        )}
      </Button>
    </Dropdown>
  ) : null;
};

export const FormFloatButton = props => {
  const {
    icon = <RocketOutlined />,
    tooltip,
    trigger = 'click',
    type = 'primary',
    style = props.order === 2 ? { right: 88 } : { right: 24 },
    FloatButtonsComponent = <>Missing FloatButtonsComponent</>,
    disabled = false,
    group = false,
    onClick,
  } = props;

  return group ? (
    <FloatButton.Group
      disabled={disabled}
      trigger={trigger}
      type={type}
      tooltip={translateX(tooltip)}
      style={style}
      icon={icon}
      // open={open}
      // onClick={() => setOpen(!open)}
    >
      {FloatButtonsComponent}
    </FloatButton.Group>
  ) : (
    <FloatButton
      disabled={disabled}
      type={type}
      tooltip={translateX(tooltip)}
      style={style}
      icon={icon}
      // open={open}
      onClick={onClick}
    >
      {FloatButtonsComponent}
    </FloatButton>
  );
};

// FUNCTIONS FORM COMPONENTS =================================================
// ===========================================================================

const validateField = async (ev, opt) => {
  const { form, name, fn, onChange } = opt;

  const value =
    form?.getFieldValue(name) !== 'undefined' && form?.getFieldValue(name);

  // if (!value) return true;

  let isValid = fn(value);

  if (!isValid) {
    // setFeedBackStatus('error');
    return isValid;
  }

  isValid = onChange ? await onChange(value) : true;
  // if (!isValid) setFeedBackStatus('error');
  return isValid;
};

const validatorRules = async options => {
  if ((await onChangeValidationFeedback(null, options)) === 'error') {
    return Promise.reject();
  } else {
    return Promise.resolve();
  }
};

async function onChangeValidationFeedback(event, options) {
  const {
    name,
    form,
    isRequired,
    t,
    fn,
    setFeedBackStatus,
    setFeedBackMessage,
    onChange,
    profile,
  } = options;

  let message, status;

  let v =
    event?.target?.value ||
    (form?.getFieldValue(name) !== 'undefined' && form?.getFieldValue(name));

  if (v === '(') v = ''; // workaround for masks (ex: phone '() . - ')

  if (!form || (!v && !form?.isFieldsTouched())) {
    message = '';
    status = '';
  } else if (isRequired && !v) {
    message = t.isRequired;
    status = 'error';
  } else if (v && !fn(v, profile)) {
    message = t.isInvalid;
    status = 'error';
  } else {
    status = v ? 'success' : '';
  }

  if (onChange) {
    if (isOnchangeWorking) return;
    setFeedBackStatus('validating');
    setFeedBackMessage('');

    isOnchangeWorking = true;
    const result = await onChange(v); // {status, message}
    setFeedBackStatus(result?.status || status);
    setFeedBackMessage(result?.message || message);
    setTimeout(() => (isOnchangeWorking = false), 1000);
    return result?.status || status;
  } else {
    setFeedBackStatus(status);
    setFeedBackMessage(message);
    return status;
  }
}

const buildLabel = (props, id) => {
  const fromFieldName = props?.name
    ? Array.isArray(props.name) && props.name.length > 0
      ? props.name[props.name.length - 1]
      : props.name
    : undefined;

  return !props?.noLabel ? props?.label || id || fromFieldName || 'n_a' : null;
};

const InputCep = React.forwardRef((props, ref) => {
  const { onChange, type, prefix, suffix, allowClear } = props;

  const [lastCepSearched, setLastCepSearched] = useState();

  const MASK = '99999-999';
  const MAX_LENGTH = 8;

  let value = returnOnlyNumbers(props.value);

  if (value) {
    value = applyNumberMask(value, MASK);
  }

  const shouldSearchCep = cep =>
    cep?.length === MAX_LENGTH && cep !== lastCepSearched;

  const searchCep = value => {
    const viaCepUrl = `https://viacep.com.br/ws/${value}/json/`;

    return fetch(viaCepUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('No cep found');
        }
        return response;
      })
      .then(response => {
        return response.json();
      });
  };

  function onLocalChange(ev) {
    let value = returnOnlyNumbers(ev.target.value);
    let nextLength = value?.length || 0;

    if (nextLength > MAX_LENGTH) return;

    const maskedValue = applyNumberMask(value, MASK);

    onChange(ev);

    if (shouldSearchCep(value)) {
      setLastCepSearched(value);
      searchCep(maskedValue)
        .then(result => onChange(ev, result))
        .catch(err => console.error(err));
    }
  }

  return (
    <Input
      {...props}
      prefix={prefix}
      suffix={suffix}
      allowClear={allowClear}
      ref={ref}
      type={type}
      value={value}
      onChange={onLocalChange}
    />
  );
});

const InputCpfCnpj = React.forwardRef((props, ref) => {
  const { onChange, type, profile, prefix, suffix, allowClear } = props;

  const TYPES = {
    CPF: '999.999.999-99',
    CNPJ: '99.999.999/9999-99',
  };

  const MAX_LENGTH =
    profile?.toLowerCase() === 'cpf'
      ? returnOnlyNumbers(TYPES.CPF).length
      : returnOnlyNumbers(TYPES.CNPJ).length;

  let value = returnOnlyNumbers(props.value);

  if (value) {
    value = applyNumberMask(value, TYPES[getMask(value)]);
  }

  function onLocalChange(ev) {
    let value = returnOnlyNumbers(ev.target.value);
    const mask = getMask(value);
    let nextLength = value?.length || 0;

    if (nextLength > MAX_LENGTH) return;

    value = applyNumberMask(value, TYPES[mask]);
    if (value) ev.target.value = value;

    onChange(ev, mask);
  }

  function getMask(value) {
    if (profile?.toLowerCase() === 'cpf') {
      return 'CPF';
    } else if (profile?.toLowerCase() === 'cnpj') {
      return 'CNPJ';
    } else {
      return value.length > 11 ? 'CNPJ' : 'CPF';
    }
  }

  return (
    <Input
      {...props}
      prefix={prefix}
      suffix={suffix}
      allowClear={allowClear}
      ref={ref}
      type={type}
      value={value}
      onChange={onLocalChange}
    />
  );
});

const InputPhoneOld = React.forwardRef((props, ref) => {
  const {
    onChange,
    type,
    profile,
    prefix,
    suffix,
    onBlur,
    autoFocus,
    allowClear,
    disabled,
  } = props;

  const BR_TYPES = {
    WIRED: '(99) 9999-9999', // 9999999999   -> 10
    MOBILE: '(99) 9.9999-9999', // 99999999999  -> 11
    FREE: '9999-999-9999', // 99999999999  -> 11
  };

  const digits = profile
    ? returnOnlyNumbers(BR_TYPES[profile.toUpperCase()])
    : returnOnlyNumbers(BR_TYPES.FREE);

  const MAX_LENGTH = digits.length;

  let value = props.value && returnOnlyNumbers(props.value);

  if (value) {
    value = applyNumberMask(value, BR_TYPES[getMask(value)], true);
  } else {
    value = '';
  }

  // local onChange + parent onChange
  function onLocalChange(ev) {
    const valueNumber = returnOnlyNumbers(ev.target.value);
    const mask = getMask(valueNumber);

    let nextLength = valueNumber.length;

    if (nextLength > MAX_LENGTH) return;
    onChange(ev, mask);
  }

  function getMask(value) {
    if (profile) {
      return profile.toUpperCase();
    } else {
      if (value.substring(0, 1) === '0') return 'FREE';
      if (value.substring(2, 3) === '9') return 'MOBILE';
      if (value.length <= 10) return 'WIRED';

      return 'MOBILE';
    }
  }

  // if (props.antd === 'Input') {
  //   return (
  //     <Input
  //       {...props}
  //       ref={ref}
  //       type={type}
  //       value={value}
  //       onBlur={onBlur}
  //       autoFocus={autoFocus}
  //       prefix={prefix}
  //       suffix={suffix}
  //       allowClear={allowClear}
  //       disabled={disabled}
  //       onChange={onLocalChange}
  //     />
  //   );
  // } else if (props.antd === 'Input.Search') {
  //   return (
  //     <Input.Search
  //       {...props}
  //       ref={ref}
  //       type={type}
  //       value={value}
  //       onBlur={onBlur}
  //       autoFocus={autoFocus}
  //       prefix={prefix}
  //       suffix={suffix}
  //       allowClear={allowClear}
  //       disabled={disabled}
  //       onChange={onLocalChange}
  //     />
  //   );
  // } else {
  return (
    <Input
      {...props}
      ref={ref}
      type={type}
      value={value}
      onBlur={onBlur}
      autoFocus={autoFocus}
      prefix={prefix}
      suffix={suffix}
      allowClear={allowClear}
      disabled={disabled}
      onChange={onLocalChange}
    />
  );
  // }
});

export const onFormFinishFailed = (errorInfo, elRefs, setUpdating) => {
  const fieldName = errorInfo?.errorFields?.[0]?.name;
  const fieldRef = fieldName?.[fieldName?.length - 1];

  appService.message('e', 'incomplete_data');

  if (fieldRef) elRefs?.[fieldRef]?.current?.focus({ cursor: 'all' });
  setUpdating && setUpdating(false);
};

export const goFocus = (elRefs, fieldName, cursor = null, delay = 100) => {
  const cursorFocus = cursor ? { cursor } : null;
  setTimeout(() => {
    elRefs?.[fieldName || 0]?.current?.focus(cursorFocus);
  }, delay);
};

export const buildSteps = (items, step, isDone, enabledSteps, isWorking) => {
  return items.map((i, idx) => {
    return {
      title: (
        <span className={`${isDone && step === idx ? 'font-weight-bold' : ''}`}>
          {translateWord(i.title)}
        </span>
      ),
      description: translateWord(i.description),
      disabled: (!isDone && !enabledSteps.includes(idx)) || isWorking,
      status: isDone && 'finish',
      icon:
        isDone &&
        (step === idx ? <CheckCircleTwoTone /> : <CheckCircleOutlined />),
    };
  });
};

// reference: https://github.com/ant-design/ant-design/issues/22918
export const defaultFilterOption = (input, option, field = 'index') => {
  if (!input || !option) return false;

  let find = normalize(input.toString(), 'lower');
  let text = normalize(input.toString(), 'lower');

  if (option[field]) {
    // find without OptGroup
    text = normalize(option[field].toString(), 'lower');
  } else if (option.text) {
    text = normalize(option.text.toString(), 'lower');
  } else if (option.label) {
    text = normalize(option.label.toString(), 'lower');
  } else if (option.children) {
    text = normalize(option.children.toString(), 'lower');
  }

  return text.indexOf(find) >= 0;
};

export const isFormValid = form => {
  return new Promise(r => {
    setTimeout(() => {
      r(form?.getFieldsError()?.every(field => !field?.errors?.length));
    }, 100);
  });
};

export const buildName = (context, name) => {
  return context
    ? Array.isArray(name)
      ? [context, ...name]
      : [context, name]
    : name;
};

export const submitForm = form => {
  setSubmitting(true);
  form?.submit();
};

export const setSubmitting = value => {
  isSubmitting = value;
};

export const getSubmitting = () => {
  return isSubmitting;
};

export const setIsOnchangeWorking = value => {
  isOnchangeWorking = value;
};

export const getIsOnchangeWorking = () => {
  return isOnchangeWorking;
};

export const setField = (field, value = null, form, context) => {
  if (!form || !field) return;
  form?.setFieldValue(context ? [context, field] : field, value);
};

export const getField = (field, form, context) => {
  if (!form || !field) return;
  return form?.getFieldValue(context ? [context, field] : field);
};

export const setFields = (data = {}, form, context) => {
  if (!form) return;
  const body = context ? { [context]: data } : data;
  form?.setFieldsValue(body);
};

export const getFields = form => {
  if (!form) return;
  return form?.getFieldsValue();
};

export const elRef = (field, elRefs = {}, context) => {
  return elRefs[context ? [context, field] : field]?.current;
};

export const getFieldName = (field, context, document) => {
  if (!field) return;

  if (document) {
    const f = context ? [context, field] : field;
    return document[f];
  } else {
    return context ? [context, field] : field;
  }
};

export const TeamAvatarsSelect = props => {
  const {
    _id,
    size = 20,
    name,
    chain,
    fontSize = 'large',
    initials = null,
    members = mockTeamMembers,
  } = props;

  if (_id) {
    const member = members.filter(elm => _id === elm._id)[0];

    if (member) {
      return (
        <Flex align="center">
          <Tooltip title={name ? '' : member.name}>
            <Avatar
              className="cursor-pointer"
              size={size}
              src={member.avatarUrl}
              style={chain ? { border: '2px solid #fff' } : {}}
            >
              <span
                className={`font-weight-semibold p-2 font-size-${
                  fontSize === 'small' ? 'sm' : ''
                }`}
              >
                {initials
                  ? initials
                  : member?.name?.match
                    ? utils.getNameInitial(member?.name)
                    : ''}
              </span>
            </Avatar>
          </Tooltip>
          {name ? (
            <span className="mb-0 ml-2 font-weight-semibold">
              {member.name}
            </span>
          ) : null}
        </Flex>
      );
    }
  }
  return null;
};

export const mockTeamMembers = [
  {
    _id: 'eileenHorton-1153',
    name: 'Eileen Horton',
    email: 'eileen_h@hotmail.com',
    // avatarUrl: '/img/avatars/thumb-1.jpg',
  },
  {
    _id: 'terranceMoreno-1322',
    name: 'Terrance Moreno',
    email: '',
    // avatarUrl: '/img/avatars/thumb-2.jpg',
  },
  {
    _id: 'ronVargas7653',
    name: 'Ron Vargas',
    email: 'ronnie_vergas@infotech.io',
    avatarUrl: '/img/avatars/thumb-33.jpg',
  },
  {
    _id: 'lukeCook4721',
    name: 'Luke Cook',
    email: 'cookie_lukie@hotmail.com',
    avatarUrl: '/img/avatars/thumb-4.jpg',
  },
  {
    _id: 'joyceFreeman1384',
    name: 'Joyce Freeman',
    email: 'joyce991@infotech.io',
    avatarUrl: '/img/avatars/thumb-5.jpg',
  },
  {
    _id: 'samanthaPhillips8493',
    name: 'Samantha Phillips',
    email: 'samanthaphil@infotech.io',
    avatarUrl: '/img/avatars/thumb-6.jpg',
  },
  {
    _id: 'taraFletcher1263',
    name: 'Tara Fletcher',
    email: 'taratarara@imaze.edu.du',
    avatarUrl: '/img/avatars/thumb-7.jpg',
  },
  {
    _id: 'frederickAdams6532',
    name: 'Frederick Adams',
    email: 'iamfred@imaze.infotech.io',
    avatarUrl: '/img/avatars/thumb-8.jpg',
  },
  {
    _id: 'carolynHanson7953',
    name: 'Carolyn Hanson',
    email: 'carolyn_h@gmail.com',
    avatarUrl: '/img/avatars/thumb-9.jpg',
  },
  {
    _id: 'brittanyHale3683',
    name: 'Brittany Hale',
    email: 'brittany1134@gmail.com',
    avatarUrl: '/img/avatars/thumb-10.jpg',
  },
  {
    _id: 'lloydObrien1564',
    name: 'Lloyd Obrien',
    email: 'handsome-obrien@hotmail.com',
    avatarUrl: '/img/avatars/thumb-11.jpg',
  },
  {
    _id: 'gabriellaMay2850',
    name: 'Gabriella May',
    email: 'maymaymay12@infotech.io',
    avatarUrl: '/img/avatars/thumb-12.jpg',
  },
  {
    _id: 'leeWheeler1941',
    name: 'Lee Wheeler',
    email: '',
    avatarUrl: '/img/avatars/thumb-13.jpg',
  },
  {
    _id: 'gailBarnes7615',
    name: 'Gail Barnes',
    email: 'gailby0116@infotech.io',
    avatarUrl: '/img/avatars/thumb-14.jpg',
  },
  {
    _id: 'ellaRobinson1093',
    name: 'Ella Robinson',
    email: 'ella_robinson@infotech.io',
    avatarUrl: '/img/avatars/thumb-15.jpg',
  },
];
