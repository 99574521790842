import {
  JarvislyFormContext,
  JarvislyFormProvider,
  JarvislyInput,
  JarvislyNumber,
  JarvislySelect,
  JarvislyTitle,
} from '../../others/FormComponents';
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import { translateX } from 'utils/helpers';
import axiosService from 'services/axiosService';
import { Card, Col, Modal, Row } from 'antd';
import { TagOutlined } from '@ant-design/icons';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useSelector } from 'react-redux';
import appsindicoService from 'services/appsindicoService';

// COMPONENT *******************************************************************
// *****************************************************************************
const ModalComponent = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { record, showModal, setShowModal, afterClose, isDisabled, updating } =
    props;

  // local variables I ----------------------------------------------------------
  const title = isDisabled
    ? translateX('view')
    : !record?._id
    ? translateX('new_bicycle')
    : translateX('edit_bicycle');

  const { parameters } = useSelector(s => s.moduleSlice);
  const rimOptions = appsindicoService.buildRimOptions(parameters);
  const colorOptions = appsindicoService.buildColorOptions(parameters);
  const bicycleBrandOptions =
    appsindicoService.buildBicycleBrandOptions(parameters);

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    form?.resetFields();
    if (record) form?.setFieldsValue(record);
  }, [record, form]); // eslint-disable-line react-hooks/exhaustive-deps

  // METHODS -------------------------------------------------------------------
  const onClose = () => {
    form.resetFields();
    setShowModal(false);
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Modal
        className="title-fixed-with-18 modal-top"
        title={
          <>
            <TagOutlined />
            <span className="ml-2">{title}</span>
          </>
        }
        destroyOnClose={true}
        open={showModal}
        width={640}
        okText={translateX(!isDisabled ? 'save' : 'close')}
        onCancel={onClose}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={() => (isDisabled ? onClose() : form.submit())}
        getContainer={false}
        okType={isDisabled ? 'default' : 'primary'}
        confirmLoading={updating}
        afterClose={afterClose}
      >
        <Card>
          <JarvislyTitle title={'bicycle_registration'} />
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={8} lg={8}>
              <JarvislySelect
                name="brand"
                options={bicycleBrandOptions}
                showSearch
                allowClear
              />
            </Col>
            <Col xs={24} sm={8} lg={8}>
              <JarvislyInput name="model" />
            </Col>
            <Col xs={24} sm={8} lg={8}>
              <JarvislySelect
                name="rim"
                options={rimOptions}
                showSearch
                allowClear
              />
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={12} lg={12}>
              <JarvislySelect
                label="predominant_color"
                name="color"
                options={colorOptions}
                showSearch
                allowClear
              />
            </Col>
            <Col xs={24} sm={12} lg={12}>
              <JarvislyNumber
                name="value"
                label="reference_value"
                suffixDefaultValue="brl"
                min={1}
                decimalSeparator=","
                max={9999999.99}
                addonBefore={null}
                addonAfter="R$"
                suffixStyle={{ width: 60 }}
              />
            </Col>
          </Row>
          <Row gutter={ROW_GUTTER}>
            <Col xs={24} sm={24} lg={24}>
              <JarvislyInput name="observation" />
            </Col>
          </Row>
        </Card>
      </Modal>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// MAIN COMPONENT **************************************************************
// *****************************************************************************
const JarvislyBicyclesModal = forwardRef((props, ref) => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const { document, isWorking, refreshDocument } = props;

  // local variables I ---------------------------------------------------------

  // component states ----------------------------------------------------------

  // for 'bicycles' tab
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // local variables II --------------------------------------------------------
  const bicyclesTabProps = {
    ...props,
    showModal,
    setShowModal,
    selectedRecord,
    setSelectedRecord,
  };

  // hooks ---------------------------------------------------------------------
  useImperativeHandle(ref, () => ({
    showModal,
    setShowModal: value => setShowModal(value),
    selectedRecord: selectedRecord,
    setSelectedRecord: value => setSelectedRecord(value),
  }));

  // methods -------------------------------------------------------------------

  const onConfirm = async ({ body }) => {
    try {
      const isEditing = selectedRecord?._id; // Verifica se há um dependente selecionado para edição
      const axiosBicyclesOptions = {
        url: '/v1/bicycles',
        _id: isEditing ? selectedRecord._id : undefined, // Usa o ID se estiver editando
        data: body,
      };

      // Realiza a operação de upsert para dependentes
      const [response] = await axiosService.upsert(axiosBicyclesOptions);
      if (response) {
        // Se estamos adicionando um novo dependente, atualiza os IDs dos dependentes na unidade
        if (!isEditing) {
          const existingBicyclesIds = document?.bicyclesIds || [];
          const newBicycleId = response._id;
          const updatedBicyclesIds = [...existingBicyclesIds, newBicycleId];

          const axiosUnitsOptions = {
            url: '/v1/units',
            _id: document?._id,
            data: { bicyclesIds: updatedBicyclesIds },
          };

          await axiosService.upsert(axiosUnitsOptions);
        }
      }

      refreshDocument();
      setShowModal(false);

      // setShowbicyclesModal(false);
      setSelectedRecord(null); // Limpa a seleção após a operação

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  // UI COMPONENT --------------------------------------------------------------
  return (
    <JarvislyFormProvider
      name="form-bicycle"
      document={selectedRecord}
      requiredFields={['brand', 'model', 'rim', 'color', 'value']}
      showSubmitButton={false}
      onConfirm={onConfirm}
      autoFocus="nameResponsible"
    >
      <ModalComponent
        {...bicyclesTabProps}
        showBirthdate
        updating={isWorking}
        record={selectedRecord}
        afterClose={() => {
          setSelectedRecord(null);
        }}
      />
    </JarvislyFormProvider>
  );
});

export default JarvislyBicyclesModal;
