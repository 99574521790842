import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { translateX } from 'utils/helpers';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { useModuleContext } from 'components/jarvisly-module/ModuleContext';
import appService from 'services/appService';
import { buildUrlQueryString } from 'components/jarvisly-module/module-methods';
import { useSelector } from 'react-redux';
import app from 'configs/ConfigIndex';
import TileItem from 'components/jarvisly-components/others/TileItem';
import apiConfig from 'auth/FetchInterceptor';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************
let tabName = 'organizations';

// COMPONENT *******************************************************************
// *****************************************************************************
const AccountOrganizationsList = props => {
  // props deconstruction ------------------------------------------------------
  const { activeTab } = props;

  // local variables -----------------------------------------------------------
  const { apiRequest, selectedModule } = useSelector(s => s.moduleSlice);
  const { selectedUser } = useSelector(s => s.authSlice);
  const navigate = useNavigate();
  const location = useLocation();

  const { refreshList, dataList } = useModuleContext();

  // component states ----------------------------------------------------------
  const [list, setList] = useState(dataList);

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setList(dataList);
  }, [dataList]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    appService.initRoute({
      moduleType: 'list',
      showPageHeader: false,
      showBreadcrumb: false,
      disableSubscriptionCombo: false,
    });

    if (selectedUser?._id) {
      apiConfig.defaults.headers['x-accountid'] = selectedUser._id;
      apiConfig.defaults.headers['x-username'] = selectedUser?.name || null;
      apiConfig.defaults.headers['x-useremail'] = selectedUser?.email || null;
    }

    if (selectedModule?.url === location?.pathname && activeTab === tabName) {
      const qs = location.state?.qsObj || '';

      if (qs === 'noRefresh' && dataList?.length > 0) {
        const qsStr = buildUrlQueryString(apiRequest.currentQs, tabName);
        navigate(`?${qsStr}`);
        return;
      }

      (async () => {
        await refreshList(qs || 'url', tabName);
      })();

      if (location?.state?.qsObj) delete location.state.qsObj;
    }
  }, [selectedModule?.url, activeTab, selectedUser]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------
  const onClickNewOrganization = () =>
    navigate(`/app/account/settings/organization/add`);

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Row justify="end">
        <Button
          type="primary"
          className="ml-2 mt-2"
          onClick={onClickNewOrganization}
        >
          <PlusOutlined />
          <span>{translateX(app.PROFILE.NEW_MESSAGE)}</span>
        </Button>
      </Row>

      <div className={`my-4 container-fluid`}>
        {
          <Row gutter={ROW_GUTTER}>
            {list?.map(record => (
              <Col xs={24} md={12} xl={8} key={record._id}>
                <TileItem record={record} hoverable strongBorder />
              </Col>
            ))}
          </Row>
        }
      </div>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
};

// EXPORT **********************************************************************
// *****************************************************************************

export default AccountOrganizationsList;
