import {
  AppstoreOutlined,
  EditOutlined,
  LineChartOutlined,
  ShoppingOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { LiaBoxesSolid } from 'react-icons/lia';
import { GoTools } from 'react-icons/go';
import { RiContrastDrop2Line } from 'react-icons/ri';
import { LuThermometerSnowflake } from 'react-icons/lu';
import { BiWater } from 'react-icons/bi';
import { PiToolboxDuotone } from 'react-icons/pi';

const APP_VEERTICAL = {
  ID: 'appveertical',
  NAME: 'Veertical IoT',
  THEME_COLOR: 'CUSTOM_VEERTICAL_APP',
  THEME: 'light',
  FINTECH_SERVICES: false,
  DOMAINS: ['sistema.veertical.com', 'sistema.veertical.com.br'],
  '//PROFILE': [
    'singleCompany',
    'multiCompanies',
    'singleClinic',
    'multiClinics',
    'condominium',
  ],
  PROFILE: 'singleCompany',
  DEV_MODE: false,
  COPY_RIGHT: 'Veertical Technologies',
  '//API_DEV_HOST': ['localhost', 'vps', 'heroku'],
  API_DEV_HOST: 'localhost',
  API_DEV_HTTPS: false,
  API_PORT_DEV: 8005,
  API_PORT_PRODUCTION: 8005,
  WS_PORT: 9001,
  API_HOST_DEV_LOCALHOST: 'localhost',
  API_HOST_DEV_VPS: 'dev.jarvisly.com',
  API_HOST_PRODUCTION: 'vps.jarvisly.com',
  API_HOST_DEV_HEROKU: 'veertical.herokuapp.com',
  WHATSAPP_INTEGRATED: true,
  TOKEN: process.env.REACT_APP_APPVEERTICAL_TOKEN,
  LOCALE: 'pt',

  LOGO_APP: '/apps/appveertical/logo-app.png',
  LOGO_APP_WHITE: '/apps/appveertical/logo-app-white.png',

  LOGO_APP_ICON: '/apps/appveertical/logo-app-icon.png',
  LOGO_APP_ICON_WHITE: '/apps/appveertical/logo-app-icon-white.png',

  LOGO: '/apps/appveertical/logo.png',
  LOGO_WHITE: '/apps/appveertical/logo-white.png',
  LOGO_LOGIN_SIDE: '/apps/appveertical/logo-app-side.png',

  TITLE: 'Veertical IoT',
  '//SLOGAN': 'Condomínios Inteligentes',
  SLOGAN: '',
  SLOGAN2: '',
  SLOGAN_REGISTRATION: 'registration_slogan',
  SLOGAN_REGISTRATION2: '',
  APPLICATION_LAYER: 'adm',
  LOGIN_IDENTIFICATION: 'email',
  SELF_REGISTRATION: false,
  GOOGLE_LOGIN: false,
  FACEBOOK_LOGIN: false,
  RESET_PASSWORD: true,
  NAVIGATION: {
    TYPE: 'MENU',
    CONFIGURATIONS: false,
    LANGUAGES: true,
  },
  ENABLE_MARKETPLACE: false,
  MODULES: ['accounts', 'quotations', 'iotDevices', 'customers', 'crm'],
  MENU_NAVIGATION: [
    {
      key: 'monitoring',
      path: '/app/monitoring',
      title: 'monitoring',
      icon: AppstoreOutlined,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        {
          key: 'monitoring_water_level',
          path: '/app/monitoring/water_level',
          title: 'water_level',
          icon: BiWater,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'monitoring_temperature',
          path: '/app/monitoring/temperature',
          title: 'temperature',
          icon: LuThermometerSnowflake,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'monitoring_humidity',
          path: '/app/monitoring/humidity',
          title: 'humidity',
          icon: RiContrastDrop2Line,
          subscriptionLevel: [0, 1],
        },
      ],
    },

    {
      key: 'records',
      path: '/app/records',
      title: 'records',
      icon: EditOutlined,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        {
          key: 'records_products',
          path: '/app/records/products',
          title: 'products',
          icon: LiaBoxesSolid,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'records_services',
          path: '/app/records/services',
          title: 'services',
          icon: PiToolboxDuotone,
          subscriptionLevel: [0, 1],
        },

        {
          key: 'records_entities',
          path: '/app/records/entities',
          title: 'entities',
          icon: TeamOutlined,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'records_entities_providers',
              path: '/app/records/entities/providers',
              title: 'providers',
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_entities_installers',
              path: '/app/records/entities/installers',
              title: 'installers',
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_entities_consultants',
              path: '/app/records/entities/consultants',
              title: 'consultants',
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_entities_customers',
              // path: '/app/records/entities/customers',
              title: 'customers',
              subscriptionLevel: [0, 1],
            },
            {
              key: 'records_entities_contacts',
              path: '/app/records/entities/contacts',
              title: 'contacts_list',
              subscriptionLevel: [0, 1],
            },
          ],
        },
      ],
    },

    {
      key: 'administrative',
      path: '/app/administrative',
      title: 'administrative',
      icon: EditOutlined,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        {
          key: 'administrative_commercial',
          path: '/app/administrative/commercial',
          title: 'commercial',
          icon: ShoppingOutlined,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'administrative_commercial_opportunities',
              path: '/app/administrative/commercial/opportunities',
              title: 'opportunities',
              subscriptionLevel: [0, 1],
            },
            {
              key: 'administrative_commercial_quotations',
              path: '/app/administrative/commercial/quotations',
              title: 'budgets',
              subscriptionLevel: [0, 1],
            },
            {
              key: 'administrative_commercial_orders',
              path: '/app/administrative/commercial/orders',
              title: 'orders',
              subscriptionLevel: [0, 1],
            },
            {
              key: 'administrative_commercial_contracts',
              path: '/app/administrative/commercial/contracts',
              title: 'contracts',
              subscriptionLevel: [0, 1],
            },
          ],
        },

        {
          key: 'administrative_operational',
          path: '/app/administrative/operational',
          title: 'operational',
          icon: GoTools,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'administrative_operational_services',
              path: '/app/administrative/operational/services',
              title: 'services',
              subscriptionLevel: [0, 1],
            },
            {
              key: 'administrative_operational_activations',
              path: '/app/administrative/operational/activations',
              title: 'activations',
              subscriptionLevel: [0, 1],
            },
          ],
        },

        {
          key: 'administrative_financial',
          path: '/app/administrative/financial',
          title: 'financial',
          icon: LineChartOutlined,
          subscriptionLevel: [0, 1],
          submenu: [
            {
              key: 'administrative_financial_invoices',
              path: '/app/administrative/financial/invoices',
              title: 'invoices',
              subscriptionLevel: [0, 1],
            },
            {
              key: 'administrative_financial_receivable',
              path: '/app/administrative/financial/receivables',
              title: 'receivables',
              subscriptionLevel: [0, 1],
            },
            {
              key: 'administrative_financial_commissions',
              path: '/app/administrative/financial/commissions',
              title: 'commissions',
              subscriptionLevel: [0, 1],
            },
            {
              key: 'administrative_financial_payable',
              path: '/app/administrative/financial/payable',
              title: 'payable',
              subscriptionLevel: [0, 1],
            },
          ],
        },
      ],
    },
  ],
  FIREBASE_CONFIG: JSON.parse(
    process.env.REACT_APP_APPVEERTICAL_FIREBASE_CONFIG,
  ),
};

export default APP_VEERTICAL;
