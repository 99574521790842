import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Button, Card, Col, Form, Row, Select } from 'antd';
import {
  defaultFilterOption,
  FormNumber,
  FormSimpleSwitch,
  JarvislyFormContext,
  JarvislyInput,
  JarvislyTextArea,
} from 'components/jarvisly-components/others/FormComponents';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { translateX } from 'utils/helpers';
import appmultiService from 'services/appmultiService';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { ModuleContext } from 'components/jarvisly-module/ModuleContext';

// COMPONENT *******************************************************************
// *****************************************************************************
const MultiPlansCoveragesIndex = forwardRef((props, ref) => {
  const { setModuleForm } = useContext(ModuleContext);
  const { form } = useContext(JarvislyFormContext);

  // props deconstruction ------------------------------------------------------
  const { isWorking, document, mode } = props;

  // local variables I ---------------------------------------------------------
  const isDisabled = isWorking || mode === 'view';
  const currencyOptions = appmultiService.getCurrencies();
  const initialCoverageOptions = appmultiService.getCoverages();

  // component states ----------------------------------------------------------
  const [coverageOptions, setCoverageOptions] = useState(
    initialCoverageOptions,
  );
  const [selectedCurrency, setSelectedCurrency] = useState({});

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (document) {
      form.setFieldsValue(document);

      const coverageCurrencies = {};

      if (document?.coverages?.length > 0) {
        document.coverages.map(
          (c, idx) => (coverageCurrencies[idx] = c.currency),
        );
      }
      setSelectedCurrency({ ...coverageCurrencies });
    } else {
      form?.resetFields();
    }
    onChangeCoverages(null, true);
  }, [form, document]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setModuleForm(form);
  }, [form]); // eslint-disable-line react-hooks/exhaustive-deps



  useImperativeHandle(ref, () => ({ selectedCurrency }));

  // methods -------------------------------------------------------------------

  const onChangeCoverages = (value, firstValidation) => {
    const formCoverages = form?.getFieldValue(['coverages']) || [];
    const docCoverages = document?.coverages || [];
    const coverageSettings = appmultiService.getCoverages();

    if (!coverageSettings) return;

    // const newCoveragesOptions = configurationTab?.coverages?.map(c => {
    const newCoveragesOptions = coverageSettings?.map(c => {
      c.disabled = !!(
        formCoverages.find(f => f?.coverageKey === c.value) ||
        (typeof firstValidation === 'boolean' &&
          firstValidation &&
          docCoverages.find(f => f?.coverageKey === c.value)) ||
        c.value === value
      );
      return c;
    });

    setCoverageOptions([...newCoveragesOptions]);
  };

  const getType = coverageKey => {
    return initialCoverageOptions.find(c => c.value === coverageKey)?.type;
  };

  const onExchangeCurrencyChange = (value, field) => {
    setSelectedCurrency({ ...selectedCurrency, [field.name]: value });
  };

  // UI COMPONENTS -------------------------------------------------------------
  const DynamicField = props => {
    const { field, index } = props;

    if (!field) return null;

    const type = getType(
      form.getFieldValue(['coverages'])?.[index]?.coverageKey,
    );

    if (!type) return null;

    switch (type) {
      case 'money':
        return (
          // <JarvislyNumber
          //   name={[field.name, 'amount']}
          //   x-no-label
          //   decimalSeparator=","
          //   suffixValue={selectedCurrency[index]}
          //   x-suffix-style={{ width: 60 }}
          //   addonBefore={null}
          //   addonAfter={currencyOptions}
          //   // onSuffixChange={v => onExchangeCurrencyChange(v, field)}
          //   required
          // />

          <FormNumber
            name={[field.name, 'amount']}
            noLabel
            disabled={isDisabled}
            decimalSeparator=","
            suffixValue={selectedCurrency[index]}
            suffixStyle={{ width: 60 }}
            addonAfter={currencyOptions}
            onSuffixChange={v => onExchangeCurrencyChange(v, field)}
            required
          />
        );

      case 'switch':
        return (
          /*
          <JarvislySwitch
            name={[field.name, 'included']}
            className="form-item-no-margin"
            x-no-label
            showYesNoLabel
            // value={form.getFieldValue(['coverages'])?.[index]?.included}
          />
*/

          <FormSimpleSwitch
            name={[field.name, 'included']}
            className="form-item-no-margin"
            noLabel
            disabled={isDisabled}
            showYesNoLabel
            value={form.getFieldValue(['coverages'])?.[index]?.included}
          />
        );

      case 'text':
        return <JarvislyInput name={[field.name, 'text']} x-no-label />;

      default:
        return null;
    }
  };

  return (
    <>
      <Card title={translateX('additional_info_singular')}>
        <Row gutter={ROW_GUTTER} className="w-100">
          <Col span={24}>
            <JarvislyTextArea
              name="coverageAdditionalInfo"
              rows={2}
              x-no-label
            />
          </Col>
        </Row>
      </Card>

      <Card>
        {/* FORM-LIST */}
        <Form.List name="coverages">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Row gutter={ROW_GUTTER} key={field.key}>
                  <Col xs={24} sm={16}>
                    {/*<JarvislySelect*/}
                    {/*  {...field}*/}
                    {/*  name={[field.name, 'coverageKey']}*/}
                    {/*  x-no-label*/}
                    {/*  required*/}
                    {/*  options={coverageOptions}*/}
                    {/*  showSearch*/}
                    {/*  allowClear*/}
                    {/*  placeholder={translateX('coverage')}*/}
                    {/*  onChange={onChangeCoverages}*/}
                    {/*/>*/}

                    <Form.Item
                      {...field}
                      key={field.key}
                      name={[field.name, 'coverageKey']}
                      rules={[
                        {
                          required: true,
                          message: translateX('required_field'),
                        },
                      ]}
                    >
                      <Select
                        options={coverageOptions}
                        showSearch
                        allowClear
                        placeholder={translateX('coverage')}
                        filterOption={defaultFilterOption}
                        onChange={onChangeCoverages}
                        disabled={isDisabled}
                      />
                    </Form.Item>
                  </Col>

                  <Col xs={20} sm={6} className="text-center">
                    <DynamicField {...props} field={field} index={index} />
                  </Col>

                  <Col xs={4} sm={2} className="text-right">
                    <Button
                      icon={<MinusCircleOutlined />}
                      disabled={isDisabled}
                      onClick={() => {
                        remove(field.name);
                        onChangeCoverages(field.name);
                      }}
                    />
                  </Col>
                </Row>
              ))}

              <Form.Item>
                <Button
                  type="dashed"
                  disabled={isDisabled}
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  &nbsp;{translateX('add_coverage')}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        {/* FORM-LIST */}

        {/* SAVE BUTTON */}
        {/*<Row*/}
        {/*  justify={{ xs: 'center', sm: 'center', md: 'end' }}*/}
        {/*  align="bottom"*/}
        {/*  className="mt-3"*/}
        {/*  style={{*/}
        {/*    justifyContent: appService.isMobile() ? 'center' : 'end',*/}
        {/*    width: '100%',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <FormButton*/}
        {/*    title="save"*/}
        {/*    disabled={isDisabled}*/}
        {/*    onClick={form.submit}*/}
        {/*    icon={<SaveOutlined />}*/}
        {/*  />*/}
        {/*</Row>*/}
        {/* SAVE BUTTON */}
      </Card>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
});

export default MultiPlansCoveragesIndex;
