import {
  DeploymentUnitOutlined,
  EditOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { FaSearchengin } from 'react-icons/fa';
import { GiMining } from 'react-icons/gi';

const APP_VALIANT = {
  ID: 'appvaliant',
  NAME: 'Valiant Seguros',
  THEME_COLOR: 'CUSTOM_VALIANT_APP',
  THEME: 'light',
  FINTECH_SERVICES: true,
  DOMAINS: ['sistema.valiantseguros.com', 'valiant.jarvisly.com'],
  '//PROFILE': [
    'singleCompany',
    'multiCompanies',
    'singleClinic',
    'multiClinics',
    'condominium',
  ],
  PROFILE: 'singleCompany',
  DEV_MODE: true,
  COPY_RIGHT: 'Valiant Seguros',
  '//API_DEV_HOST': ['localhost', 'vps', 'heroku'],
  API_DEV_HOST: 'localhost',
  API_DEV_HTTPS: false,
  API_PORT_DEV: 8007,
  API_PORT_PRODUCTION: 8007,
  WS_PORT: 9001,
  API_HOST_DEV_LOCALHOST: 'localhost',
  API_HOST_DEV_VPS: 'vps.jarvisly.com',
  API_HOST_PRODUCTION: 'vps.jarvisly.com',
  API_HOST_DEV_HEROKU: 'valiant.herokuapp.com',
  WHATSAPP_INTEGRATED: false,
  API_INTEGRATED: false,
  TOKEN: process.env.REACT_APP_APPVALIANT_TOKEN,
  LOCALE: 'pt',

  LOGO_APP: '/apps/appvaliant/logo-app.png',
  LOGO_APP_WHITE: '/apps/appvaliant/logo-app-white.png',

  LOGO_APP_ICON: '/apps/appvaliant/logo-app-icon.png',
  LOGO_APP_ICON_WHITE: '/apps/appvaliant/logo-app-icon-white.png',

  LOGO: '/apps/appvaliant/logo.png',
  LOGO_WHITE: '/apps/appvaliant/logo-white.png',
  LOGO_LOGIN_SIDE: '/apps/appvaliant/logo-app-side.png',

  TITLE: 'Valiant Seguros',
  '//SLOGAN': 'Condomínios Inteligentes',
  SLOGAN: '',
  SLOGAN2: '',
  SLOGAN_REGISTRATION: 'registration_slogan',
  SLOGAN_REGISTRATION2: '',
  APPLICATION_LAYER: 'adm',
  LOGIN_IDENTIFICATION: 'email',
  SELF_REGISTRATION: true,
  GOOGLE_LOGIN: false,
  FACEBOOK_LOGIN: false,
  RESET_PASSWORD: true,
  NAVIGATION: {
    TYPE: 'MENU',
    CONFIGURATIONS: false,
    LANGUAGES: true,
  },
  ENABLE_MARKETPLACE: false,
  MODULES: ['accounts', 'leads', 'dataSearch', 'customers'],
  SHOW_ALL_MENUS: process.env.REACT_APP_APPVALIANT_SHOW_ALL_MENUS,
  MENU_NAVIGATION: [
    // ------------------
    // DATA MINING
    // ------------------
    {
      key: 'data_mining',
      path: '/app/data_mining',
      title: 'data_mining',
      className: '',
      icon: GiMining,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        {
          key: 'data_mining_data_search',
          path: '/app/data_mining/data_search',
          title: 'data_search',
          icon: FaSearchengin,
          subscriptionLevel: [0, 1],
        },
        {
          key: 'data_mining_leads',
          path: '/app/commercial/leads',
          title: 'leads',
          icon: DeploymentUnitOutlined,
          subscriptionLevel: [0, 1],
        },
      ],
    },

    // ------------------
    // RECORDS
    // ------------------
    {
      key: 'records',
      path: '/app/records',
      title: 'records',
      className: '',
      icon: EditOutlined,
      isGroupTitle: true,
      subscriptionLevel: [0, 1],
      submenu: [
        {
          key: 'records_customers',
          path: '/app/records/customers',
          title: 'customers',
          icon: TeamOutlined,
          subscriptionLevel: [0, 1],
        },
      ],
    },
  ],
  FIREBASE_CONFIG: JSON.parse(process.env.REACT_APP_APPVALIANT_FIREBASE_CONFIG),
};

export default APP_VALIANT;
