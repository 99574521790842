import { PlusOutlined } from '@ant-design/icons';
import { Card, Flex, Table } from 'antd';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import {
  FormButton,
  JarvislyEditViewRemoveItem,
} from 'components/jarvisly-components/others/FormComponents';
import { FiSmartphone } from 'react-icons/fi';
import { MdOutlineMail } from 'react-icons/md';
import { VscNote } from 'react-icons/vsc';
import {
  parseCnpjToDisplay,
  parsePhoneToDisplay,
  translateX,
} from 'utils/helpers';
import { useEffect } from 'react';
import { HiQrCode } from 'react-icons/hi2';
import axiosService from 'services/axiosService';
import appService from 'services/appService';

const PixEditIndex = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    isWorking,

    setShowPixEditModal,
    selectedRecord,
    isDisabled,

    setSelectedPix,

    pixArr,
    setPixArr,
    refreshDocument
  } = props;

  // local variables I ---------------------------------------------------------
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    setPixArr(selectedRecord?.pixKeys || []);
  }, [selectedRecord?.pixKeys, setPixArr]);

  // methods -------------------------------------------------------------------
  const onAdd = () => {
    setSelectedPix(null);
    setShowPixEditModal(true);
  };

  const onEdit = record => {
    setSelectedPix(record);
    setShowPixEditModal(true);
  };

  const onDelete = async record => {
    const axiosOptions = {
      url: `/v1/banks/add-to-set/${selectedRecord?._id}/$pull/pixKeys._id`,
      data: {
        _id: record._id,
      },
    };

    try {
      await axiosService.put(axiosOptions);
      appService.message('s', 'deleted_pix_key', 'onDelete');

      setPixArr(p => p.filter(item => item._id !== record._id));
    } catch (error) {
      console.error(error);
    }

    refreshDocument(null, true, false, false).then()
  };

  const getIcon = type => {
    switch (type) {
      case 'email':
        return <MdOutlineMail />;
      case 'mobile':
        return <FiSmartphone />;
      case 'cnpj':
        return <VscNote />;
      default:
        return <HiQrCode />;
    }
  };

  // variables III -------------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <Card className="cad-with-body-no-padding">
      <Table
        rowKey="_id"
        columns={tableColumns}
        dataSource={pixArr}
        pagination={false}
        loading={isWorking}
        size="small"
      />

      <Flex justify="center">
        <FormButton
          title="add_pix_key"
          className="m-3"
          style={{ width: 'auto' }}
          icon={<PlusOutlined />}
          onClick={onAdd}
          disabled={isDisabled}
        />
      </Flex>
    </Card>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTableColumns() {
    return [
      {
        title: translateX('pix_key'),
        dataIndex: 'name',
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                imageUrl={getIcon(record?.type)}
                title={translateX(record?.type)}
                subtitle={
                  record?.type === 'cnpj'
                    ? parseCnpjToDisplay(record.value)
                    : record?.type === 'mobile'
                      ? parsePhoneToDisplay(record.value)
                      : record.value
                }
                shape="square"
                style={{ padding: 10 }}
                backColor="#ecf2fe"
              />
            </div>
          );
        },
      },
      {
        title: translateX('actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (
            <JarvislyEditViewRemoveItem
              onEdit={onEdit}
              onDelete={onDelete}
              record={record}
              recordId={record?._id}
              isDisabled={isDisabled}
              deleteTitle={translateX(
                'remove_this_item',
                ['%ITEM%'],
                ['contact'],
              )}
            />
          );
        },
      },
    ];
  }
};

export default PixEditIndex;
