import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import appService from '../../services/appService';
import store from '../index';
import app from '../../configs/ConfigIndex';

export const initialState = {
  ...app, // <- here there is the locale property from ConfigIndex Application
  quote: null,
  working: 0,
  firstLoad: false,
  wsMessage: null,
  navCollapsed: localStorage.getItem('navCollapsed') === 'true',
};

let tmrWorking;

// Define uma ação assíncrona para buscar os dados
export const fetchQuote = createAsyncThunk('theme/fetchQuote', async () => {
  return await appService.getQuote();
});

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    onWsReceiveMessage: (state, action) => {
      state.wsMessage = action.payload;
    },
    toggleCollapsedNav: (state, action) => {
      state.navCollapsed = action.payload;
      localStorage.setItem('navCollapsed', action.payload);
    },
    onNavStyleChange: (state, action) => {
      state.sideNavTheme = action.payload;
    },
    onLocaleChange: (state, action) => {
      state.locale = action.payload;
      localStorage.setItem('locale', action.payload);
    },
    onNavTypeChange: (state, action) => {
      state.navType = action.payload;
    },
    onTopNavColorChange: (state, action) => {
      state.topNavColor = action.payload;
    },
    onHeaderNavColorChange: (state, action) => {
      state.headerNavColor = action.payload;
    },
    onMobileNavToggle: (state, action) => {
      state.mobileNav = action.payload;
    },
    onSwitchTheme: (state, action) => {
      state.currentTheme = action.payload;
    },
    onDirectionChange: (state, action) => {
      state.direction = action.payload;
    },
    onBlankLayout: (state, action) => {
      state.blankLayout = action.payload;
    },
    onRequestsWorking: (state, action) => {
      state.working = action.payload;

      if (state.working === 0) {
        tryHideFullPageLoading();
      }
    },
    setFirstLoad: (state, action) => {
      state.firstLoad = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchQuote.fulfilled, (state, action) => {
      state.quote = action.payload; // Define os dados no estado
    });
  },
});

export const {
  onWsReceiveMessage,
  toggleCollapsedNav,
  onNavStyleChange,
  onLocaleChange,
  onNavTypeChange,
  onTopNavColorChange,
  onHeaderNavColorChange,
  onMobileNavToggle,
  onSwitchTheme,
  onDirectionChange,
  onBlankLayout,
  onRequestsWorking,
  setQuote,
  setFirstLoad,
} = themeSlice.actions;

export default themeSlice.reducer;

function tryHideFullPageLoading() {
  if (tmrWorking) clearTimeout(tmrWorking);
  tmrWorking = setTimeout(() => $waitingGo(), 300);

  function $waitingGo() {
    const w = store.getState().themeSlice.working;
    if (w === 0) store.dispatch(setFirstLoad(true));
  }
}
