import { Badge, Card, Col, ColorPicker, Form, Row } from 'antd';
import {
  JarvislyFormContext,
  JarvislyInput,
  JarvislyNumber,
  JarvislySelect,
} from 'components/jarvisly-components/others/FormComponents';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import appveerticalService from 'services/appveerticalService';
import { translateX } from 'utils/helpers';
import { useContext, useEffect, useState } from 'react';
import useTabChild from 'components/jarvisly-components/hooks/useTabChild';
import { useSelector } from 'react-redux';

// GLOBAL VARIABLES ************************************************************
// *****************************************************************************

// COMPONENT *******************************************************************
// *****************************************************************************
const IotDevicesDeviceTab = props => {
  // providers context ---------------------------------------------------------
  const { form } = useContext(JarvislyFormContext);
  const { parameters } = useSelector(s => s.moduleSlice);

  // props deconstruction ------------------------------------------------------
  const { isDisabled, document, activeTab } = props;

  // local variables I ---------------------------------------------------------
  useTabChild('device', 'channel', props);

  // const { parameters } = useSelector(s => s.moduleSlice);
  const devicesOptions = appveerticalService.buildDevicesOptions();

  // component states ----------------------------------------------------------
  const [selectedDeviceOptions, setSelectedDeviceOptions] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(document?.type);

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------
  useEffect(() => {
    if (document?.type && !selectedDevice) {
      setSelectedDevice(document.type);
    }
  }, [document, activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const currentDeviceModel = form?.getFieldValue('model');
    const selectedDeviceModelOpt = appveerticalService.buildDevicesModelOptions(
      selectedDevice || currentDeviceModel,
    );

    if (selectedDeviceModelOpt.length > 0 && currentDeviceModel) {
      const modelMatched = selectedDeviceModelOpt?.find(
        m => m.value === currentDeviceModel,
      );

      if (!modelMatched) form?.setFieldValue('model', '');
    } else {
      form?.setFieldValue('model', '');
    }

    setSelectedDeviceOptions(
      appveerticalService.buildDevicesModelOptions(selectedDevice),
    );
  }, [selectedDevice]); // eslint-disable-line react-hooks/exhaustive-deps

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------
  const stockOptions = $buildStocksOptions();
  const conditionOptions = $buildConditionOptions();

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <>
      {/* DEVICE CARD */}
      <Card title={translateX('device')}>
        <Row gutter={ROW_GUTTER}>
          {/* CHANNEL */}
          <Col xs={24} sm={6}>
            <JarvislyInput name="channel" />
          </Col>
          {/* CHANNEL */}

          {/* DEVICE */}
          <Col xs={24} sm={8}>
            <JarvislySelect
              label="device"
              name="type"
              options={devicesOptions}
              onChange={d => setSelectedDevice(d)}
              showSearch
            />
          </Col>
          {/* DEVICE */}

          {/* MODEL */}
          <Col xs={24} sm={8}>
            <JarvislySelect
              label="model"
              name="model"
              options={selectedDeviceOptions}
              showSearch
            />
          </Col>
          {/* MODEL */}

          {/*          <Col xs={24} sm={22} lg={6}>
            <JarvislySelect
              label="location"
              name="locationId"
              options={devicesLocationsOptions}
              required
              showSearch
            />
          </Col>*/}

          {/* <Col style={{display: 'none'}}>
            <JarvislySelect name="service" options={devicesTypesOptions} />
          </Col> */}

          {/* COLOR */}
          <Col xs={24} sm={2}>
            <Form.Item
              name="color"
              // style={{ width: '0%', marginTop: '25px' }}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '25px',
              }}
              required={true}
            >
              <ColorPicker
                disabled={isDisabled}
                value={form?.getFieldValue('color')}
                onChange={color => form.setFieldsValue({ color })}
                defaultFormat="hex"
                size="small"
              />
            </Form.Item>
          </Col>
          {/* COLOR */}
        </Row>

        <Row gutter={ROW_GUTTER}>
          {/* TITLE */}
          <Col xs={24} sm={12} lg={12}>
            <JarvislyInput name="title" />
          </Col>
          {/* TITLE */}

          {/* DESCRIPTION */}
          <Col xs={24} sm={12} lg={12}>
            <JarvislyInput name="description" />
          </Col>
          {/* DESCRIPTION */}
        </Row>
      </Card>
      {/* DEVICE CARD */}

      {/* STOCK CARD */}
      <Card title={translateX('stock')}>
        <Row gutter={ROW_GUTTER}>
          <Col xs={24} sm={12}>
            <JarvislySelect
              label="location"
              name="stockId"
              options={stockOptions}
              required
              showSearch
            />
          </Col>

          <Col xs={24} sm={12}>
            <JarvislySelect
              label="condition"
              name="condition"
              options={conditionOptions}
              required
              showSearch
            />
          </Col>
        </Row>
      </Card>
      {/* STOCK CARD */}
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function $buildStocksOptions() {
    if (!parameters?.categoriesParameters?.stocks?.length) {
      return [];
    }

    return parameters.categoriesParameters.stocks.map(stock => {
      return {
        value: stock._id,
        text: stock.label, // O texto a ser mostrado no Select
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span key={stock._id}>
              <Badge
                dot
                style={{
                  marginRight: 8,
                  color: stock?.foreColor,
                  backgroundColor: stock?.backColor,
                  marginTop: 4,
                }}
              />
              {stock.label}
            </span>
          </div>
        ),
        disabled: false,
      };
    });
  }

  function $buildConditionOptions() {
    const options = ['new', 'assembled', 'installed', 'removed', 'discarded'];

    return options.map(i => {
      let color;

      switch (i) {
        case 'new':
          color = 'default';
          break;

        case 'assembled':
          color = 'success';
          break;

        case 'installed':
          color = 'processing';
          break;

        case 'removed':
          color = 'warning';
          break;

        case 'discarded':
          color = 'error';
          break;

        default:
          color = 'default';
      }

      return {
        value: i,
        text: i,
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <span key={i}>
              <Badge
                dot
                style={{
                  marginRight: 8,
                  // color: '#ffffff',
                  // backgroundColor: getDocumentConditionColor(i),
                  marginTop: 4,
                }}
                status={color}
              />
              {translateX(i)}
            </span>
          </div>
        ),
        disabled: false,
      };
    });
  }
};

export default IotDevicesDeviceTab;
