import { Tabs } from 'antd';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { translateX } from 'utils/helpers';
import ProductsRecordIndex from './Record/RecordIndex';
import {
  JarvislyFormProvider,
  JarvislyTabProvider,
} from '../../../../../components/jarvisly-components/others/FormComponents';
import ParametersProvider from 'components/jarvisly-module/ParametersProvider';

const ProductsForm = props => {
  // props deconstruction ------------------------------------------------------
  const { selectedModule, document } = props;

  // local variables I ---------------------------------------------------------
  const [searchParams] = useSearchParams();
  const initTab = selectedModule.documentState.initTab;

  // component states ----------------------------------------------------------
  const [tab, setTab] = useState(searchParams.get('tab') || initTab);

  // local variables II --------------------------------------------------------
  const tabItems = buildTabItens();

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------
  const onTabClick = value => {
    if (!tabItems.find(t => t.key === value)) value = initTab;
    setTab(value);
  };

  const onSave = async ({ body }) => {
    if (!body) return;
    console.log('ESSE', body);
    return body;
  };

  // local variables III -------------------------------------------------------

  // UI COMPONENTS -------------------------------------------------------------
  return (
    <ParametersProvider>
      {/* WRAPPER MODULE PARAMETERS MODAL */}
      {selectedModule?.components?.parameters
        ? selectedModule.components.parameters(props)
        : null}
      {/* WRAPPER MODULE PARAMETERS MODAL */}

      <JarvislyFormProvider
        name="form-products"
        document={document}
        autoFocus="name"
        requiredFields={['name']}
        showSubmitButton={tab !== 'contacts'}
        setTab={setTab}
        onSave={onSave}
      >
        <Tabs
          type="line"
          tabPosition="top"
          activeKey={tab}
          rootClassName="tab-in-form"
          items={tabItems.filter(x => !x.hide)}
          onTabClick={onTabClick}
        />
      </JarvislyFormProvider>
    </ParametersProvider>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================

  function buildTabItens() {
    return [
      {
        label: translateX('identification'),
        key: initTab,
        hide: false,
        children: (
          <JarvislyTabProvider tab={initTab}>
            <ProductsRecordIndex {...props} activeTab={tab} />
          </JarvislyTabProvider>
        ),
      },

      // {
      //   label: translateX('contacts'),
      //   key: 'contacts',
      //   hide: false,
      //   disabled: mode === 'add',
      //   children: <h1>TAB2</h1>,
      // },
    ];
  }
};

export default ProductsForm;
