// COMPONENT *******************************************************************
// *****************************************************************************
import { Flex, Table, Tag } from 'antd';
import {
  FormButton,
  JarvislyEditViewRemoveItem,
} from 'components/jarvisly-components/others/FormComponents';
import { PlusOutlined } from '@ant-design/icons';
import { translateX } from 'utils/helpers';
import appService from 'services/appService';
import axiosService from 'services/axiosService';

const FamilysTab = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    parameters,
    setShowFamilyModal,
    setSelectedRecord,
    refreshParameters,
  } = props;

  // local variables I ----------------------------------------------------------
  const tableColumns = buildTableColumns();

  // component states -----------------------------------------------------------

  // local variables II ---------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // METHODS -------------------------------------------------------------------
  const onAdd = () => {
    setShowFamilyModal(true);
    setSelectedRecord(null)
  };

  const onEdit = record => {
    setSelectedRecord(record);
    setShowFamilyModal(true);
  };

  const onDelete = async record => {
    const axiosOptions = {
      url: `/v1/products-parameters/add-to-set/${parameters?.categoriesParameters?._id}/$pull/familys._id`,
      data: {
        _id: record?._id,
      },
    };

    appService.message('s', 'saved_parameters', 'onSave');
    await axiosService.put(axiosOptions);

    refreshParameters(true);
  };

  // local variables III --------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Table
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={parameters?.categoriesParameters?.familys || []}
        pagination={false}
        disabled={false}
        size="small"
      />

      <Flex justify="center">
        <FormButton
          title="add_family"
          className="m-3"
          style={{ width: 'fit-content' }}
          icon={<PlusOutlined />}
          disabled={false}
          onClick={onAdd}
        />
      </Flex>
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildTableColumns() {
    return [
      {
        title: translateX('family'),
        dataIndex: 'family',
        className: 'text-truncate',
        render: (_, record) => (
          <Tag key={record._id} color={record?.backColor}>
            <span
              className="text-uppercase"
              style={{ color: record?.foreColor }}
            >
              {record?.label}
            </span>
          </Tag>
        ),
      },

      {
        title: translateX('actions'),
        dataIndex: 'actions',
        width: 120,
        className: 'text-center',
        render: (_, record) => {
          return (
            <JarvislyEditViewRemoveItem
              onEdit={onEdit}
              onDelete={onDelete}
              record={record}
              disabled={false}
              deleteTitle={translateX('remove_this_item', ['%ITEM%'], ['item'])}
            />
          );
        },
      },
    ].filter(x => !x.hidden);
  }
};

export default FamilysTab;
