import { AiOutlineThunderbolt } from 'react-icons/ai';
import { BiWater } from 'react-icons/bi';
import { FaFaucet } from 'react-icons/fa6';
import { LuThermometerSnowflake } from 'react-icons/lu';
import { RiContrastDrop2Line, RiDashboard3Line } from 'react-icons/ri';
import { TfiRulerAlt } from 'react-icons/tfi';
import { translateX } from 'utils/helpers';
import { ImSwitch } from 'react-icons/im';

import imgWaterLevelSensor from 'assets/images/water_level_sensor.png';
import imgTemperatureSensor from 'assets/images/temperature_sensor.png';
import imgHumiditySensor from 'assets/images/humidity_sensor.png';
import imgDistanceSensor from 'assets/images/distance_sensor.png';
import imgSwitchDevice from 'assets/images/switch_device.png';
import imgPowerSensor from 'assets/images/power_sensor.png';
import imgWaterFlowSensor from 'assets/images/water_flow_sensor.png';

const appveerticalService = {};
// appveerticalService.buildDevicesFilter = () => {
//   return [
//     {
//       value: 'not_identified',
//       text: setLocale(true, 'not_identified'),
//       color: '#c0c0c0',
//       textColor: 'white',
//     },
//     {
//       value: 'water_level_sensor',
//       text: setLocale(true, 'water_level_sensor'),
//       // color: '#2db7f5',
//       color: '#ecf2fe',
//       textColor: 'black',
//     },
//     {
//       value: 'temperature_sensor',
//       text: setLocale(true, 'temperature_sensor'),
//       // color: '#2db7f5',
//       // color: '#ff7a7a',
//       color: '#ecf2fe',
//       textColor: 'black',
//     },
//   ];
// };

// appveerticalService.buildOnOffStatusFilter = onOff => {
//   return [
//     {
//       value: onOff ? 'on' : 'online',
//       text: setLocale(true, onOff ? 'on' : 'online'),
//       color: 'green',
//     },
//     {
//       value: onOff ? 'off' : 'offline',
//       text: setLocale(true, onOff ? 'off' : 'offline'),
//       color: 'gray',
//     },
//   ];
// };

appveerticalService.buildDevicesOptions = hideTitle => {
  const t = {
    all_sensors: translateX('all_sensors'),
    water_level_sensor: translateX('water_level_sensor'),
    water_level: translateX('water_level'),
    temperature_sensor: translateX('temperature_sensor'),
    temperature: translateX('temperature'),
    humidity_sensor: translateX('humidity_sensor'),
    humidity: translateX('humidity'),
    distance_sensor: translateX('distance_sensor'),
    distance: translateX('distance'),
    switch_device: translateX('switch_device'),
    switch: translateX('switch'),
    power_sensor: translateX('power_sensor'),
    power_consumption: translateX('power_sensor'),
    water_flow_sensor: translateX('water_flow_sensor'),
    water_flow: translateX('water_flow_sensor'),
  };

  const options = [
    {
      value: 'all_sensors',
      text: t.all_sensors,
      label: t.all_sensors,
      title: t.all_sensors,
      // img: imgWaterLevelSensor,
      icon: <RiDashboard3Line />,
      // disabled: false,
      // color: '#ecf2fe',
      // textColor: 'black',
      // symbol: '%',
      // metric: 'cm',
      // hide: true
    },
    {
      value: 'water_level_sensor',
      text: t.water_level_sensor,
      label: t.water_level_sensor,
      title: t.water_level,
      img: imgWaterLevelSensor,
      icon: <BiWater />,
      disabled: false,
      color: '#ecf2fe',
      textColor: 'black',
      symbol: '%',
      metric: 'cm',
    },
    {
      value: 'temperature_sensor',
      text: t.temperature_sensor,
      label: t.temperature_sensor,
      title: t.temperature,
      img: imgTemperatureSensor,
      icon: <LuThermometerSnowflake />,
      disabled: false,
      color: '#ecf2fe',
      textColor: 'black',
      symbol: '°',
      metric: '°',
    },
    {
      value: 'humidity_sensor',
      text: t.humidity_sensor,
      label: t.humidity_sensor,
      title: t.humidity,
      img: imgHumiditySensor,
      icon: <RiContrastDrop2Line />,
      disabled: false,
      color: '#ecf2fe',
      textColor: 'black',
      symbol: '%',
      metric: '%',
    },
    {
      value: 'distance_sensor',
      text: t.distance_sensor,
      label: t.distance_sensor,
      title: t.distance,
      img: imgDistanceSensor,
      icon: <TfiRulerAlt />,
      disabled: false,
      color: '#ecf2fe',
      textColor: 'black',
      symbol: 'cm',
      metric: 'cm',
    },

    {
      value: 'power_sensor',
      text: t.power_sensor,
      label: t.power_sensor,
      title: t.power_consumption,
      img: imgPowerSensor,
      icon: <AiOutlineThunderbolt />,
      disabled: false,
      color: '#ecf2fe',
      textColor: 'black',
      symbol: 'cm',
      metric: 'cm',
    },

    {
      value: 'water_flow_sensor',
      text: t.water_flow_sensor,
      label: t.water_flow_sensor,
      title: t.water_flow,
      img: imgWaterFlowSensor,
      icon: <FaFaucet />,
      disabled: false,
      color: '#ecf2fe',
      textColor: 'black',
      symbol: 'cm',
      metric: 'cm',
    },

    {
      value: 'switch_device',
      text: t.switch_device,
      label: t.switch_device,
      title: t.switch,
      img: imgSwitchDevice,
      icon: <ImSwitch />,
      disabled: false,
      color: '#ecf2fe',
      textColor: 'black',
      symbol: 'on/off',
      metric: '1/0',
    },
  ];

  return hideTitle
    ? options.map(x => {
      return {
        value: x.value,
        icon: x.icon,
      };
    })
    : options.filter(x => x.metric);
};

appveerticalService.buildDevicesModelOptions = selectedDevice => {

  switch (selectedDevice) {
    case 'water_level_sensor':
      return [
        {
          value: 'ultrasonic',
          label: translateX('ultrasonic_sensor_model'),
        },
        {
          value: 'infra_red',
          label: translateX('infrared_sensor_model'),
        },
        {
          value: 'probe06',
          label: translateX('probe_sensor_model_06'),
        },
        {
          value: 'probe20',
          label: translateX('probe_sensor_model_20'),
        },
      ];

    default:
      return [
        {
          value: 'default',
          label: translateX('default'),
        },
      ];
  }
};

appveerticalService.getDevicePicture = device => {
  if (!device) return;

  const d = appveerticalService.buildDevicesOptions();
  const selectedImg = d.find(x => x.value === device);
  return selectedImg?.img;
};

appveerticalService.buildDevicesLocationsOptions = parameters => {
  return (
    parameters?.locations?.map(c => {
      return {
        value: c._id,
        label: c.label,
        color: c.color,
      };
    }) || []
  );
};

appveerticalService.buildDevicesTypesOptions = parameters => {
  return [
    {
      value: 'veertical',
      label: 'veertical',
    },
    {
      value: 'thingSpeak',
      label: 'thingSpeak',
    },
  ];
};

appveerticalService.buildDevicesFormatOptions = () => {
  return [
    {
      value: 'square',
      label: translateX('square'),
    },
    {
      value: 'cylindrical',
      label: translateX('cylindrical'),
    },
  ];
};

appveerticalService.buildDevicesMaterialOptions = () => {
  return [
    {
      value: 'metal',
      label: translateX('metal'),
    },
    {
      value: 'concrete',
      label: translateX('concrete'),
    },
    {
      value: 'fiber',
      label: translateX('fiber'),
    },
  ];
};

appveerticalService.buildDevicesInstallationLocationOptions = () => {
  return [
    {
      value: 'underground',
      label: translateX('underground'),
    },
    {
      value: 'on_ground',
      label: translateX('on_ground'),
    },
    {
      value: 'roof_top_inner',
      label: translateX('roof_top_inner'),
    },
    {
      value: 'roof_top_out',
      label: translateX('roof_top_out'),
    },
    {
      value: 'tower',
      label: translateX('tower'),
    },
  ];
};

// appveerticalService.refresh24hGraphicsByChannel = async (_dataLock, body) => {
//   if (_dataLock?.channel === body.channel) return;

//   const iot = await getIotCollectorDeviceByChannel(body.service, body.channel);

//   body.feeds = iot?.feeds || [];
//   body.status = iot?.status || 'offline';
//   body.lastReadingAt = iot?.lastReadingAt;
//   body.lastEntryKey = iot?.lastEntryKey;
//   body.lastField1 = iot?.lastField1;
//   body.lastField2 = iot?.lastField2;
//   body.lastField3 = iot?.lastField3;
//   body.lastField4 = iot?.lastField4;
// };

export default appveerticalService;
