import { EditOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Button, Flex, Table, Tooltip } from 'antd';
import {
  parseCnpjToDisplay,
  parseDateToDisplay,
  setLocale,
  translateWord,
  translateX,
} from 'utils/helpers';
import dayjs from 'dayjs';
import DocumentStatusTag from 'components/jarvisly-components/others-old/DocumentStatusTag';
import accounting from 'accounting';
import ImageDescription from 'components/jarvisly-components/others-old/ImageDescription';
import Icon from 'components/util-components/Icon';
import MULTI_OPERATORS_MODULE from '../operators';
import { DefaultApiIcon } from 'components/jarvisly-module/Form/FormHeader';

const MultiOperatorsList = props => {
  // providers context ---------------------------------------------------------

  // props deconstruction ------------------------------------------------------
  const {
    isWorking,
    onTableChanges,
    columnSorter,
    columnSearch,
    columnFilters,
    tableKey,
    resetTableSearch,
    pagination,
    dataList,
    buildRowSelection,
    showNumberOfLine,
    onClickOpen,
  } = props;

  // local variables I ---------------------------------------------------------
  const statusFilter = buildDocumentStatusFilter();
  // const apiIntegrationFilter = buildApiIntegrationFilter();
  const tableColumns = buildTableColumns();

  // component states ----------------------------------------------------------

  // local variables II --------------------------------------------------------

  // hooks ---------------------------------------------------------------------

  // methods -------------------------------------------------------------------

  // local variables III -------------------------------------------------------

  // UI COMPONENT --------------------------------------------------------------
  return (
    <>
      <Table
        key={tableKey}
        rowKey="_id"
        columns={tableColumns.filter(x => !x.hidden)}
        dataSource={dataList || []}
        pagination={pagination}
        onChange={onTableChanges}
        rowSelection={buildRowSelection}
        className="with-checkbox"
        resetTableSearch={resetTableSearch}
        loading={isWorking}
      />
    </>
  );

  // INTERNAL FUNCTIONS ========================================================
  // ===========================================================================
  function buildDocumentStatusFilter() {
    const options =
      MULTI_OPERATORS_MODULE?.initialRequest?.filterOptions?.status || [];

    return options.map(o => {
      return {
        text: translateX(o),
        value: o,
      };
    });
  }

  function buildTableColumns() {
    return [
      {
        hidden: !showNumberOfLine,
        title: translateWord('line'),
        width: 80,
        render: (item, record, index) => {
          if (!pagination) return;

          const { current, pageSize } = pagination;

          return (
            <div className="text-left">
              {current * pageSize - pageSize + index + 1 || index}
            </div>
          );
        },
      },

      {
        title: translateX('document'),
        dataIndex: '_metadata.counter',
        ...columnSorter('_metadata.counter'),
        ...columnSearch('_metadata.counter'),
        width: 140,
        render: (_, record) => (
          <span className="text-uppercase text-muted">
            {record?._metadata?.__documentCode}
          </span>
        ),
      },

      {
        title: translateWord('name'),
        dataIndex: 'name',
        ...columnSorter('name'),
        ...columnSearch('name'),
        className: 'text-truncate',
        render: (_, record) => {
          return (
            <div className="d-flex" style={{ width: 200 }}>
              <ImageDescription
                imageUrl={record?.pictureUrl}
                title={record?.name}
                subtitle={parseCnpjToDisplay(record?.cnpj)}
                foreColor={record?.foreColor}
                backColor={record?.backColor}
              />
            </div>
          );
        },
      },

      {
        title: translateWord('quotation'),
        dataIndex: 'parameters.__exchangeRate',
        ...columnSorter('parameters.__exchangeRate'),
        width: 120,
        render: (_, record) => (
          <div className="text-center">
            {record?.parameters?.__exchangeRate &&
            !record?.parameters?.__hideExchangeOnList ? (
              <Flex justify="flex-end" align="start" style={{ width: 100 }}>
                <Tooltip title={translateWord(record?.parameters.__tooltip)}>
                  {accounting.formatMoney(
                    record.parameters.__exchangeRate,
                    `${record?.parameters?.__symbol} `,
                    2,
                    '.',
                    ',',
                  )}
                </Tooltip>

                {record.parameters.__changedByApi ? (
                  <Tooltip
                    title={translateX(
                      'data_changed_by_api',
                      ['%DATE%'],
                      [
                        parseDateToDisplay(
                          dayjs(record?.parameters?.__changedAt),
                          true,
                          true,
                        ),
                      ],
                    )}
                  >
                    <div className="mt-1" style={{ width: 21 }}>
                      <Icon
                        type={ThunderboltOutlined}
                        className="text-primary font-size-md ml-1"
                        style={{ flexShrink: 0 }}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  <div className="mt-1" style={{ width: 21 }} />
                )}
              </Flex>
            ) : (
              ''
            )}
          </div>
        ),
      },

      {
        title: translateWord('api'),
        dataIndex: '_integrations.operatorCode',
        // ...columnFilters('parameters.integrated', apiIntegrationFilter),
        ...columnSorter('_integrations.operatorCode'),
        width: 70,
        render: (_, record) => (
          <div className="text-center">
            <DefaultApiIcon
              document={record}
              showApiIcon
              showApiIconOnlyConnected
            />
          </div>
        ),
      },

      {
        title: translateWord('status'),
        dataIndex: 'status',
        ...columnFilters('status', statusFilter),
        ...columnSorter('status'),
        render: (_, record) => <DocumentStatusTag record={record} />,
      },

      {
        title: translateWord('last_update'),
        dataIndex: '_metadata.audit.updatedAt',
        ...columnSorter('_metadata.audit.updatedAt'),
        hidden: false,
        render: (_, record) => (
          <>
            {record?._metadata?.audit?.updatedAt &&
              dayjs(record._metadata.audit.updatedAt).fromNow()}
          </>
        ),
      },

      {
        title: setLocale('actions'),
        dataIndex: 'actions',
        align: 'center',
        width: 50,
        render: (_, record) => (
          <div className="text-right d-flex justify-content-end">
            <Tooltip title={setLocale('edit')}>
              <Button
                className="mr-2"
                icon={<EditOutlined />}
                onClick={() => onClickOpen(record)}
                size="small"
              />
            </Tooltip>

            {/* <Tooltip title={setLocale('view')}>
              <Button
                className="mr-2"
                icon={<EyeOutlined />}
                onClick={() => onClickPreview(record)}
                size="small"
              />
            </Tooltip>*/}
          </div>
        ),
      },
    ].filter(x => !x.hidden);
  }

  // function buildApiIntegrationFilter() {
  //   return [
  //     {
  //       text: translateWord('api_integrated'),
  //       value: 'yes',
  //     },
  //     {
  //       text: translateWord('no_api_integrated'),
  //       value: 'no',
  //     },
  //   ];
  // }
};

export default MultiOperatorsList;
